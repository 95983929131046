import React, { useContext, useEffect } from "react";

import { PageTemplate } from "templates";
import { CustomerContext, ReportContext } from "context";
import { TableCardComponent } from "components";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";

import { CustomerTableColumns } from "../components";

function CustomerPage() {
  const {
    allData,
    isLoading,
    getAllDataHandlers,
    totalDocs,
    totalArchiveDocs,
  } = useContext(CustomerContext);
  const { generateSubscriberReportHandler } = useContext(ReportContext);
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const { search } = usePageQueryHooks();
  const pageQuery = {
    emailSearch: search,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, currentPage, search]);

  const tableCardProps = {
    column: CustomerTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
  };
  return (
    <PageTemplate
      scrollAll
      title={"Customer"}
      breadCrumb={[
        {
          name: "Subscribers",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        exportXlsx={{
          generateHandler() {
            generateSubscriberReportHandler();
          },
        }}
        searchInput={{
          placeholder: "Find User by Email ID",
        }}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
        {...tableCardProps}
      />
    </PageTemplate>
  );
}

export default CustomerPage;
