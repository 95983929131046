import React from "react";

import { Route, Routes, useLocation } from "react-router-dom";

import { PrivateRouteComponent, PublicRouteComponent } from "./components";
import {
  AuthRoutes,
  PackageRoutes,
  RolesPermissionsRoutes,
  UserRoutes,
  CustomerRoutes,
  SettingRoutes,
  DashboardRoutes,
  ProfileRoutes,
  PurchaseHistoryRoutes,
} from "modules";
import { Page404 } from "pages";

export default function AuthRoute() {
  const location = useLocation();

  return (
    <>
      <Routes location={location}>
        <Route path="/" element={<PublicRouteComponent />}>
          {AuthRoutes()}
        </Route>

        <Route path="/" element={<PrivateRouteComponent />}>
          {DashboardRoutes()}
          {SettingRoutes()}
          {PackageRoutes()}
          {RolesPermissionsRoutes()}
          {UserRoutes()}
          {CustomerRoutes()}
          {ProfileRoutes()}
          {PurchaseHistoryRoutes()}
        </Route>

        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  );
}
