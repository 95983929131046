import React, { useContext } from "react";
import { AppContext } from "../context";
import moment from "moment";

function UseDateHooks() {
  const getDateHandler = ({
    date,
    haveTime,
    defaultFormat,
  }: {
    date: string | Date;
    haveTime?: boolean;
    defaultFormat?: string;
  }) => {
    if (!date) {
      return "";
    }
    // Desired time zone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    date = new Date(date);
    date = new Date(
      date.toLocaleString("en-US", {
        timeZone: timeZone,
      })
    );

    if (defaultFormat) {
      return moment(date)?.format(defaultFormat);
    }
    return moment(date)?.format(`YYYY-MM-DD ${haveTime ? "hh:mm A" : ""} `);
  };
  return {
    getDateHandler,
  };
}

export default UseDateHooks;
