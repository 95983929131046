import actionTypes from "./config";
import { AppReducerInterface } from "./interfaces";

export const appState: AppReducerInterface = {
  isLoading: false,
  isError: false,
  isActionSuccess: false,
  isActionLoading: false,
  isNotification: false,
  isAuthenticated: false,
  havePageError: false,
  pageErrorMessage: "",
  hasTenantSpaceSelected: false,
  isAuthLoading: true,
  profileDetails: null,
  systemSettings: null,
  message: "",
};
const appReducer = (state: any, action: any) => {
  switch (action.type) {
    case actionTypes.APP_SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isActionSuccess: false,
        isActionLoading: false,
        isAuthenticated: false,
        profileDetails: null,
        hasTenantSpaceSelected: false,
      };
    case actionTypes.APP_SET_PAGE_ERROR:
      return {
        ...state,
        havePageError: action?.payload?.status,
        pageErrorMessage: action?.payload?.message,
      };
    case actionTypes.APP_SET_PROFILE:
      return {
        ...state,
        profileDetails: action.payload,
        isAuthenticated: true,
        hasTenantSpaceSelected: true,
      };
    case actionTypes.APP_SET_SETTING:
      return {
        ...state,
        settings: action.payload,
      };
    case actionTypes.APP_SET_SYSTEM_SETTINGS:
      return {
        ...state,
        systemSettings: action.payload,
      };

    case actionTypes.APP_SET_SUCCESS_ACTION:
      return {
        ...state,
        isActionSuccess: action.payload,
      };
    case actionTypes.APP_SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionTypes.APP_SET_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: action.payload,
      };
    case actionTypes.APP_SET_NOTIFICATION:
      return {
        ...state,
        isNotification: action.payload,
      };
    case actionTypes.APP_SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case actionTypes.APP_SET_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case actionTypes.APP_SET_AUTH_LOADING:
      return {
        ...state,
        isAuthLoading: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
