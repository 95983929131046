import React from "react";
import { useSearchParams } from "react-router-dom";

import { MySearchInput, MySearchSelect } from "components";
import { DateRangeOptionEnum, QueryStringEnum } from "interfaces";
import {
  lastMonthEnd,
  lastMonthStart,
  lastYearEnd,
  lastYearStart,
  thisMonthEnd,
  thisMonthStart,
  thisYearEnd,
  thisYearStart,
} from "helpers";
import { usePaginationQueryHooks } from "../../useHooks";
export interface TableFilterCardInterface {
  haveDateRange?: boolean;
}

function TableFilterCard({ haveDateRange }: TableFilterCardInterface) {
  const [query, setQuery] = useSearchParams();
  const rangeType: DateRangeOptionEnum | any = query?.get(
    QueryStringEnum.RANGE_TYPE
  );
  const { setCurrentPage } = usePaginationQueryHooks();
  return (
    <div className={"flex items-center gap-5"}>
      {haveDateRange && (
        <div className={"flex items-center gap-5"}>
          <MySearchSelect
            isClearable
            // selectedValue={DateRangeOptionEnum.THIS_MONTH}
            option={{
              selectOptions: DateRangeOptions,
              defaultLabel: "Select Date Range Type",
            }}
            styles={{
              width: "250px",
            }}
            onSelectHandler={(value) => {
              query.set(QueryStringEnum.RANGE_TYPE, value);
              if (value === DateRangeOptionEnum.THIS_MONTH) {
                query.set(QueryStringEnum.START_DATE, thisMonthStart);
                query.set(QueryStringEnum.END_DATE, thisMonthEnd);
              } else if (value === DateRangeOptionEnum.THIS_YEAR) {
                query.set(QueryStringEnum.START_DATE, thisYearStart);
                query.set(QueryStringEnum.END_DATE, thisYearEnd);
              } else if (value === DateRangeOptionEnum.LAST_MONTH) {
                query.set(QueryStringEnum.START_DATE, lastMonthStart);
                query.set(QueryStringEnum.END_DATE, lastMonthEnd);
              } else if (value === DateRangeOptionEnum.LAST_YEAR) {
                query.set(QueryStringEnum.START_DATE, lastYearStart);
                query.set(QueryStringEnum.END_DATE, lastYearEnd);
              } else {
                query.set(QueryStringEnum.START_DATE, "");
                query.set(QueryStringEnum.END_DATE, "");
              }
              setCurrentPage("1");
              setQuery(query);
            }}
            paramsName={QueryStringEnum.RANGE_TYPE}
          />

          {rangeType === DateRangeOptionEnum.CUSTOM && (
            <>
              <MySearchInput
                type={"date"}
                paramsName={QueryStringEnum.START_DATE}
              />
              <MySearchInput
                type={"date"}
                paramsName={QueryStringEnum.END_DATE}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
const DateRangeOptions = [
  {
    label: "This Year",
    value: DateRangeOptionEnum.THIS_YEAR,
  },
  {
    label: "This Month",
    value: DateRangeOptionEnum.THIS_MONTH,
  },
  {
    label: "Last Year",
    value: DateRangeOptionEnum.LAST_YEAR,
  },
  {
    label: "Last Month",
    value: DateRangeOptionEnum.LAST_MONTH,
  },
  {
    label: "All Time",
    value: DateRangeOptionEnum.ALL_TIME,
  },
  {
    label: "Custom",
    value: DateRangeOptionEnum.CUSTOM,
  },
];

export default TableFilterCard;
