import React, { useContext } from "react";
import {
  Api,
  downloadBlobFileHandler,
  getIconHelper,
  getImageFileDetails,
} from "helpers";
import { IconTypeEnum } from "interfaces";
import { ApiUrl } from "services";
import { AppContext } from "context";
import { ImageViewCard } from "./index";

interface PropsInterface {
  filePath: string;
  canDownload?: boolean;
  deleteHandler?();
}
function DocumentViewCard({
  filePath,
  canDownload,
  deleteHandler,
}: PropsInterface) {
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const { getBlobResApi } = Api();
  const { isImage, extension } = getImageFileDetails(filePath);
  const FileIcon = getIconHelper(IconTypeEnum.FILE);
  const DownloadIcon = getIconHelper(IconTypeEnum.DOWNLOAD);
  const DeleteIcon = getIconHelper(IconTypeEnum.DELETE);
  const downloadDocumentHandler = async () => {
    try {
      setLoading(true);
      const res = await getBlobResApi(`${ApiUrl.file.get_file}${filePath}`);
      downloadBlobFileHandler(res, filePath);
    } catch (err) {
      setError(true, err?.message || "No file exist");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={"flex flex-col items-center relative"}>
      <div
        className={
          "flex items-center justify-center rounded-lg bg-gray-100 overflow-hidden p-1 min-w-[130px] w-full h-[150px]"
        }
      >
        {isImage ? (
          <ImageViewCard
            fileName={filePath}
            containerStyle={"h-full w-full object-fill rounded-lg"}
          />
        ) : (
          <FileIcon
            data-testid={"document_view_file_icon"}
            className={"text-[44px] text-tBlue"}
          />
        )}
      </div>
      <div className={"flex gap-2 h-full absolute top-2 right-2"}>
        {canDownload && filePath && (
          <div
            data-testid={"document_view_download_icon"}
            onClick={downloadDocumentHandler}
            className={
              "flex items-center justify-center shadow-md h-[40px] w-[40px] bg-white cursor-pointer rounded-full text-primary text-[18px]"
            }
          >
            <DownloadIcon className={"text-[22px]"} />
          </div>
        )}
        {typeof deleteHandler === "function" && filePath && (
          <div
            onClick={deleteHandler}
            className={
              "flex items-center justify-center shadow-md h-[40px] w-[40px] bg-white cursor-pointer rounded-full text-tRed text-[18px]"
            }
          >
            <DeleteIcon />
          </div>
        )}
      </div>
      {!isImage && (
        <div className={"absolute bottom-0 left-0 bg-white p-2 rounded-tr-2xl"}>
          <span className={"text-black font-semibold uppercase text-[14px]"}>
            {extension}
          </span>
        </div>
      )}
    </div>
  );
}

export default DocumentViewCard;
