import React, { useState } from "react";

function UseDataContextHooks() {
  const [isLoading, setLoading] = useState(false);
  const [isDetailsLoading, setDetailsLoading] = useState(false);
  const [isArchiveLoaded, setArchiveLoaded] = useState(false);

  const [totalDocs, setTotalDocs] = useState(0);
  const [totalArchiveDocs, setTotalArchiveDoc] = useState(0);

  const [allData, setAllData] = useState([]);
  const [details, setDetails] = useState(null);
  const setTotalArchiveDocs = (count: number) => {
    setTotalArchiveDoc(count);
    setArchiveLoaded(true);
  };
  return {
    isArchiveLoaded,
    isLoading,
    setLoading,
    isDetailsLoading,
    setDetailsLoading,
    totalDocs,
    setTotalDocs,
    totalArchiveDocs,
    setTotalArchiveDocs,
    allData,
    setAllData,
    details,
    setDetails,
  };
}

export default UseDataContextHooks;
