import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import React from "react";
import { SystemTenantUserInterface } from "interfaces";

interface PropsInterface {
  data: SystemTenantUserInterface[];
  dateFormatter({ date }: { date: Date });
}
async function generateSubscriberReport({
  data,
  dateFormatter,
}: PropsInterface) {
  try {
    //    create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Subscriber Report", {});
    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 20;
    let tableHeaders = [
      {
        name: "SN.",
      },
      {
        name: "Email",
      },
      {
        name: "Package",
      },
      {
        name: "Purchased Property ",
      },
      {
        name: "last Login",
      },
      {
        name: "Number of login",
      },
      {
        name: "Start Date",
      },
      {
        name: "Expire Date",
      },
      {
        name: "Payment Mode",
      },
    ];
    let tableRows = data?.map((e, index) => {
      return [
        index + 1,
        e?.userID?.email,
        e?.activeSubscription?.subscribedPackage?.name,
        e?.activeSubscription?.purchasedProperty,
        dateFormatter({ date: e?.userID?.loginAt }),
        e?.userID?.numberOfLogins,
        dateFormatter({ date: e?.activeSubscription?.packageStartsAt }),
        dateFormatter({ date: e?.activeSubscription?.packageExpiredAt }),
        e?.activeSubscription?.paymentMode,
      ];
    });
    // add a table to a sheet
    worksheet.addTable({
      name: "Subscriber Report",
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        showRowStripes: false,
      },
      columns: tableHeaders,
      rows: tableRows,
    });
    //    save the workbook to a file
    const bufferFile = await workbook.xlsx.writeBuffer();
    await FileSaver.saveAs(
      new Blob([bufferFile], { type: "application/octet-stream" }),
      `${Date.now()}-subscriber.xlsx`
    );
  } catch (err) {
    throw err;
  }
}

export default generateSubscriberReport;
