import React from "react";
import { PageTemplate } from "templates";
import { SubscriberStatsContent, RevenueStatsContent } from "../content";

function DashboardPage() {
  return (
    <PageTemplate
      scrollAll
      isBgTransparent
      title={"Dashboard"}
      breadCrumb={[{ name: "Dashboard" }]}
    >
      <div className={"flex flex-col gap-10"}>
        <RevenueStatsContent />
        <div className={"col-span-1"}>
          <SubscriberStatsContent />
        </div>
      </div>
    </PageTemplate>
  );
}

export default DashboardPage;
