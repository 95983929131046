import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageTemplate } from "templates";

import { PackageContext } from "context";
import { MyButton, TableCardComponent } from "components";
import { PageLinks } from "routes";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";

import { PackageTableColumns } from "../components";
import { AdminAccessListEnum, IconTypeEnum } from "interfaces";

function PackagePage() {
  const {
    allData,
    isLoading,
    getAllDataHandlers,
    totalDocs,
    totalArchiveDocs,
  } = useContext(PackageContext);

  const { isArchive, currentPage } = usePaginationQueryHooks();
  const navigate = useNavigate();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAdd = checkCanAccessHandler([
    AdminAccessListEnum.SUBSCRIPTION_ADD_SUBSCRIPTION,
  ]);
  const pageQuery = {};
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [currentPage, isArchive]);
  const tableCardProps = {
    column: PackageTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      rightChildren: canAdd && (
        <div className={"flex items-center gap-2"}>
          <MyButton
            onClick={() => {
              navigate(PageLinks.package.create);
            }}
            iconType={IconTypeEnum.ADD}
            name={`Add a New Package`}
          />{" "}
        </div>
      ),
    },
  };
  return (
    <PageTemplate
      scrollAll
      title={"Package"}
      breadCrumb={[
        {
          name: "Packages",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default PackagePage;
