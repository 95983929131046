import { AiOutlineMore } from "react-icons/ai";
import Popup from "reactjs-popup";

import { MyMoreOptionsButtonInterface } from "./interfaces";
import { IoCaretDown } from "react-icons/io5";
import React from "react";
import { getIconHelper } from "helpers";

export default function MoreOptionsButton({
  options,
  type,
  colorType,
  isOutline,
  name,
  iconType,
  width,
}: MyMoreOptionsButtonInterface) {
  const getButtonStyle = () => {
    let styles, iconStyle;
    switch (colorType) {
      case "primary":
        if (isOutline) {
          styles = "border-primary border-2 text-primary";
        } else {
          styles = "bg-primary  text-white ";
        }
        iconStyle = "text-[22px] text-primary";
        break;
      case "danger":
        if (isOutline) {
          styles = "border-red-500 border-2 text-red-500";
        } else {
          styles = "bg-red-500  text-white ";
        }
        break;
      default:
        if (isOutline) {
          styles = "border-primary border-2 text-primary";
        } else {
          styles = "bg-primary  text-white ";
        }
        iconStyle = "text-[22px]";
    }
    return { styles, iconStyle };
  };
  const { styles, iconStyle } = getButtonStyle();
  const Icon = getIconHelper(iconType);
  return (
    <Popup
      position="bottom right"
      closeOnDocumentClick
      contentStyle={{ width: width || "200px" }}
      disabled={options?.length < 1}
      trigger={
        type === "button" ? (
          <button
            type={"button"}
            className={`btn flex-1 flex rounded-full gap-5 items-center min-h-[40px] cursor-pointer     px-5
      text-[16px]  ${styles} `}
          >
            <div className={"flex  items-center justify-center  gap-1"}>
              {iconType && (
                <Icon
                  height={iconStyle?.height || "20px"}
                  width={iconStyle?.width || "20px"}
                  className={` text-[16px] ${iconStyle} `}
                />
              )}
              <span className={"whitespace-nowrap "}>{name}</span>
            </div>
            <IoCaretDown />
          </button>
        ) : (
          <button className="button">
            <div className={"rounded-full p-1 border-2 text-gray-500 bg-white"}>
              <AiOutlineMore fontSize={18} />
            </div>
          </button>
        )
      }
    >
      <div className={"flex flex-col "}>
        {options?.map((e, key) => {
          if (!e?.name) return;
          return (
            <div
              className={
                "flex items-center gap-2 border-b-2 px-2 py-3 text-primary text-[14px] cursor-pointer hover:bg-gray-100"
              }
              key={key}
              onClick={() => e?.handler()}
            >
              {iconType && (
                <Icon
                  height={iconStyle?.height || "20px"}
                  width={iconStyle?.width || "20px"}
                  className={` text-[16px] ${iconStyle} `}
                />
              )}
              <span>{e?.name}</span>
            </div>
          );
        })}
      </div>
    </Popup>
  );
}
