import React, { createContext, useContext } from "react";

import { AppContext } from "context";
import { Api, downloadBlobFileHandler } from "helpers";
import { ApiUrl } from "services";

interface PDFContextPropsInterface {
  downloadSubscriptionBillPDF(itemID: string, orderID: string);
}
export const PDFContext = createContext<PDFContextPropsInterface>({
  downloadSubscriptionBillPDF(itemID, orderID: string) {},
});

function DataContextProvider({ children }) {
  const { handlers } = useContext(AppContext);
  const { getBlobResApi } = Api();

  //  handler
  const contextHandlers = {
    downloadSubscriptionBillPDF: async (itemID, orderID) => {
      try {
        handlers?.setLoading(true);
        const res = await getBlobResApi(
          ApiUrl.pdf.get_subscriptionPDF(itemID, orderID)
        );
        downloadBlobFileHandler(res, `${itemID}-subscription`, "pdf");
        handlers?.setSuccess(true, "Downloaded Successfully");
      } catch (err) {
        handlers?.setError(true, err?.message || "File not found");
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const contextValue = {
    ...contextHandlers,
  };
  return (
    <PDFContext.Provider value={contextValue}>{children}</PDFContext.Provider>
  );
}

export default DataContextProvider;
