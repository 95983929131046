import React, { createContext, useContext } from "react";

import { Api } from "helpers";
import { ApiUrl } from "services";
import { TIME_BASIS_ENUM } from "interfaces";
import { AppContext } from "../appContext";

export const DashboardStatsContext = createContext({
  getRevenueStatsHandler(type: TIME_BASIS_ENUM) {},
  getUserStatsHandler(query?: any) {},
  getSubscriptionStatsHandler(query?: any) {},
});

function DataContextProvider({ children }) {
  const { getApi } = Api();
  const { handlers } = useContext(AppContext);
  //  handler
  const contextHandlers = {
    getRevenueStatsHandler: async (type: TIME_BASIS_ENUM) => {
      try {
        let res = await getApi(ApiUrl.dashboard.get_revenueStats(type));
        return res?.data;
      } catch (err) {
        handlers?.setError(true, err?.message);
      }
      return {};
    },
    getUserStatsHandler: async (query: any) => {
      try {
        let res = await getApi(ApiUrl.dashboard.get_userStats, {
          ...query,
        });
        return res?.data;
      } catch (err) {
        handlers?.setError(true, err?.message);
      }
      return {};
    },
    getSubscriptionStatsHandler: async (query: any) => {
      try {
        let res = await getApi(ApiUrl.dashboard.get_subscription, {
          ...query,
        });
        return res?.data;
      } catch (err) {
        handlers?.setError(true, err?.message);
      }
      return {};
    },
  };

  const contextValue = {
    ...contextHandlers,
  };
  return (
    <DashboardStatsContext.Provider value={contextValue}>
      {children}
    </DashboardStatsContext.Provider>
  );
}

export default DataContextProvider;
