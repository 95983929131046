import React, { createContext, useContext, useState } from "react";

import { AppContext } from "context/index";
import { Api } from "helpers";
import { ApiUrl } from "services";
import {
  HandlerCallbackInterface,
  DataContextInterface,
  PurchasePackageInterface,
  SystemTenantUserInterface,
} from "interfaces";
import {
  useConfirmHook,
  useDataContextHooks,
  usePaginationQueryHooks,
} from "useHooks";

interface CustomerContextInterface
  extends DataContextInterface<SystemTenantUserInterface> {
  purchaseHistory: PurchasePackageInterface[];
  getPurchaseHistoryHandler(itemID?: string);
  extendPackageHandler(payload: any, callback?: HandlerCallbackInterface);
  assignPackageHandler(
    payload: any,
    actions: any,
    callback?: HandlerCallbackInterface
  );
  terminatePackageHandler(itemsID: string, callback?: HandlerCallbackInterface);
}
export const CustomerContext = createContext<CustomerContextInterface>({
  isDetailsLoading: false,
  isLoading: false,
  totalDocs: 0,
  totalArchiveDocs: 0,
  allData: [],
  details: undefined,
  purchaseHistory: [],
  getAllDataHandlers(query?: any) {},
  getPurchaseHistoryHandler(itemID?: string) {},
  getDetailsHandler(itemId: string, isFromEdit?: boolean) {},
  archiveHandler(
    itemId: string,
    status: boolean,
    callback?: HandlerCallbackInterface
  ) {},
  extendPackageHandler(payload: any, callback?: HandlerCallbackInterface) {},
  assignPackageHandler(
    payload: any,
    actions: any,
    callback?: HandlerCallbackInterface
  ) {},
  terminatePackageHandler(
    itemsID: string,
    callback?: HandlerCallbackInterface
  ) {},
});

function DataContextProvider({ children }) {
  const { handlers } = useContext(AppContext);
  const { confirm } = useConfirmHook();
  const { isArchive, paginationQuery } = usePaginationQueryHooks();
  const {
    isArchiveLoaded,
    totalArchiveDocs,
    setTotalArchiveDocs,
    isDetailsLoading,
    setDetailsLoading,
    details,
    setLoading,
    isLoading,
    setTotalDocs,
    totalDocs,
    setDetails,
    allData,
    setAllData,
  } = useDataContextHooks();
  const [purchaseHistory, setPurchaseHistory] = useState([]);

  const { getApi, putApi, deleteApi } = Api();
  //  handler
  const contextHandlers = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);

        let res: any;
        res = await getApi(ApiUrl.customers.get_customers, {
          ...query,
          ...paginationQuery,
        });
        if (isArchive) {
          setTotalArchiveDocs(res?.data?.totalDocs);
        } else {
          setTotalDocs(res?.data?.totalDocs);
        }
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getDetailsHandler: async (itemID: string, isFromEdit) => {
      try {
        if (!isFromEdit) {
          setDetailsLoading(true);
          setDetails(null);
        }
        const res: any = await getApi(
          ApiUrl.customers.get_customerDetails(itemID)
        );
        setDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
        handlers.setPageErrorHandler(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    archiveHandler: async (
      itemsId,
      status: boolean,
      callback: HandlerCallbackInterface
    ) => {
      try {
        const isConfirm = await confirm(
          `Are you sure you want to ${
            !status ? "archive" : "unarchive"
          } the selected items?`,
          !status ? "Archive" : "Unarchive",
          "Cancel"
        );
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await putApi(
          ApiUrl.customers.put_archiveCustomer +
            `${itemsId}/${status ? "off" : "on"}`,
          {}
        );
        if (isArchiveLoaded) {
          // isAlready archived
          if (status) {
            setTotalArchiveDocs(totalArchiveDocs - 1);
            setTotalDocs(totalDocs + 1);
          } else {
            setTotalArchiveDocs(totalArchiveDocs + 1);
          }
        }
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    getPurchaseHistoryHandler: async (itemsId?: string) => {
      try {
        setLoading(true);
        const res: any = await getApi(
          ApiUrl.customers.get_purchaseHistoryLandlord(itemsId)
        );
        setPurchaseHistory(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    assignPackageHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) return;
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(true)
          : handlers?.setLoading(true);

        const res: any = await putApi(
          ApiUrl.customers.put_assignPackageToCustomer,
          values
        );

        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback?.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(false)
          : handlers?.setLoading(false);
      }
    },
    extendPackageHandler: async (
      values,
      callback: HandlerCallbackInterface
    ) => {
      try {
        handlers?.setLoading(true);
        const res: any = await putApi(
          ApiUrl.customers.put_extendSubscriptionDuration,
          values
        );

        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    terminatePackageHandler: async (
      itemsId,
      callback: HandlerCallbackInterface
    ) => {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res = await deleteApi(
          ApiUrl.customers.delete_terminateActivePackage + itemsId,
          {}
        );

        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const contextValue = {
    totalDocs,
    totalArchiveDocs,
    allData,
    details,
    isDetailsLoading,
    isLoading,
    purchaseHistory,
    ...contextHandlers,
  };
  return (
    <CustomerContext.Provider value={contextValue}>
      {children}
    </CustomerContext.Provider>
  );
}

export default DataContextProvider;
