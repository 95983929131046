import React from "react";
import { Route } from "react-router-dom";
import { AccessVerifier } from "templates";
import { AdminAccessListEnum } from "interfaces";
import { PurchaseHistoryPage } from "./pages";

function Index() {
  return (
    <Route path="purchase-history/">
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.CUSTOMER_READ_PURCHASE_HISTORY]}
            isForPage={true}
          >
            <PurchaseHistoryPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
