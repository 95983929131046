import React from "react";
import { FieldArray, useFormikContext } from "formik";
import {
  MyButton,
  MyRadioInput,
  MyTextAreaInput,
  MyTextField,
} from "components";
import { FormWrapper } from "assets";
import { getCurrency } from "helpers";
import {
  IconTypeEnum,
  SubscriptionPackageInterface,
  TaxTypeEnum,
} from "interfaces";

import { PackageFormik } from "../helpers";

function PackageForm() {
  const { values } = useFormikContext<SubscriptionPackageInterface>();
  return (
    <div className={"flex flex-col gap-10"}>
      <div
        className={
          "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
        }
      >
        <MyTextField
          isRequired
          name={"name"}
          label={"Name"}
          placeholder={"eg: Premium Package"}
        />
        <MyTextField
          name={"subtitle"}
          label={"Sub-title"}
          placeholder={"eg: Premium Package"}
        />
      </div>
      <div
        className={
          "grid grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-5 items-start"
        }
      >
        {/*<MyRadioInput name={"allowBooking"} label={"Allow Booking"} />*/}
        <MyRadioInput name={"purchaseDisable"} label={"Purchase Disable"} />
        <MyRadioInput name={"isFreeForever"} label={"Free Forever"} />
        {!values?.isFreeForever && (
          <MyRadioInput name={"trial.enable"} label={"Trial"} />
        )}
      </div>

      {values?.trial?.enable && !values?.isFreeForever && (
        <FormWrapper
          header={{
            title: { name: "Trial" },
          }}
        >
          <div
            className={
              "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
            }
          >
            <MyTextField
              type={"number"}
              name={"trial.days"}
              label={"Trial (days)"}
              placeholder={"eg: 5"}
            />
            <MyTextField
              name={"trial.text"}
              label={"Text"}
              placeholder={"eg: Get Free for 5 days"}
            />
          </div>
        </FormWrapper>
      )}

      <FormWrapper
        header={{
          title: { name: "Features" },
        }}
      >
        <div
          className={
            "grid grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-5 items-start"
          }
        >
          <MyRadioInput
            name={"allowBankIntegration"}
            label={"Bank Integration"}
          />
          <MyRadioInput name={"allowSMS"} label={"SMS"} />
          <MyRadioInput name={"enableMail"} label={"Mail"} />
          <MyRadioInput name={"enableMobileLogin"} label={"Mobile Login"} />
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: { name: "Module" },
        }}
      >
        <div
          className={
            "grid grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-5 items-start"
          }
        >
          <MyRadioInput name={"enableTask"} label={"Task"} />
          <MyRadioInput name={"allowBooking"} label={"Booking"} />
          <MyRadioInput name={"enableMaintenance"} label={"Maintenance"} />
          <MyRadioInput name={"enableInventory"} label={"Inventory"} />
          <MyRadioInput name={"allowMessageModule"} label={"Message Module"} />
          <MyRadioInput name={"allowSupplierUser"} label={"Supplier User"} />
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: { name: "Storage" },
        }}
      >
        <div className={"flex flex-col gap-2"}>
          <span className={"text-gray-500"}>Note: 1GB = 1024MB</span>
          <div
            className={
              "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
            }
          >
            <MyTextField
              name={"storage.base"}
              type={"number"}
              label={"Base Storage (MB)"}
              placeholder={"eg: 0.5GB"}
            />{" "}
            <MyTextField
              name={"storage.perProperty"}
              type={"number"}
              label={"Per Property (MB)"}
              placeholder={"eg: 0.1GB"}
            />
          </div>
        </div>
      </FormWrapper>
      {!values?.isFreeForever && (
        <>
          <FormWrapper
            header={{
              title: { name: "Annual Subscription Charge" },
              leftChildren: (
                <MyRadioInput
                  name={"annualCost.enable"}
                  radioButtonType={"switch"}
                />
              ),
            }}
          >
            {values?.annualCost?.enable && (
              <div className={"flex flex-col gap-5"}>
                <div className={"flex items-center gap-10"}>
                  <MyRadioInput
                    name={"annualCost.taxType"}
                    label={"Tax Exclusive"}
                    defaultValue={TaxTypeEnum.EXCLUSIVE}
                  />
                  <MyRadioInput
                    name={"annualCost.taxType"}
                    label={"Tax Inclusive"}
                    defaultValue={TaxTypeEnum.INCLUSIVE}
                  />
                  <MyRadioInput
                    name={"annualCost.taxType"}
                    label={"Tax Exempted"}
                    defaultValue={TaxTypeEnum.EXEMPTED}
                  />
                </div>
                <div
                  className={
                    "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
                  }
                >
                  <MyTextField
                    type={"number"}
                    name={"annualCost.cost"}
                    label={`Package Charge (${getCurrency()})`}
                    placeholder={`eg:${getCurrency()}500`}
                  />

                  <MyTextField
                    type={"number"}
                    name={"annualCost.discountPercent"}
                    label={"Discount Percent (%)"}
                    placeholder={`eg: 10%`}
                  />
                  {values?.annualCost?.taxType !== TaxTypeEnum.EXEMPTED && (
                    <MyTextField
                      type={"number"}
                      name={"annualCost.taxRate"}
                      label={"Tax Rate (%)"}
                      placeholder={`eg: 10%`}
                    />
                  )}
                </div>
                <div
                  className={
                    "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
                  }
                >
                  <MyTextField
                    type={"number"}
                    isRequired
                    name={"annualCost.flatPropertyCost"}
                    label={`Flat Property Charge (${getCurrency()}) (Per Property)`}
                    placeholder={`eg: ${getCurrency()}500`}
                  />
                </div>
              </div>
            )}
          </FormWrapper>
          <FormWrapper
            header={{
              title: { name: "Monthly Subscription Charge" },
              leftChildren: (
                <MyRadioInput
                  name={"monthlyCost.enable"}
                  radioButtonType={"switch"}
                />
              ),
            }}
          >
            {values?.monthlyCost?.enable && (
              <div className={"flex flex-col gap-5"}>
                <div className={"flex items-center gap-10"}>
                  <MyRadioInput
                    name={"monthlyCost.taxType"}
                    label={"Tax Exclusive"}
                    defaultValue={TaxTypeEnum.EXCLUSIVE}
                  />
                  <MyRadioInput
                    name={"monthlyCost.taxType"}
                    label={"Tax Inclusive"}
                    defaultValue={TaxTypeEnum.INCLUSIVE}
                  />
                  <MyRadioInput
                    name={"monthlyCost.taxType"}
                    label={"Tax Exempted"}
                    defaultValue={TaxTypeEnum.EXEMPTED}
                  />
                </div>
                <div
                  className={
                    "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
                  }
                >
                  <MyTextField
                    type={"number"}
                    name={"monthlyCost.cost"}
                    label={`Package Charge (${getCurrency()})`}
                    placeholder={`eg:${getCurrency()}500`}
                  />

                  <MyTextField
                    type={"number"}
                    name={"monthlyCost.discountPercent"}
                    label={"Discount Percent (%)"}
                    placeholder={`eg: 10%`}
                  />
                  {values?.monthlyCost?.taxType !== TaxTypeEnum.EXEMPTED && (
                    <MyTextField
                      type={"number"}
                      name={"monthlyCost.taxRate"}
                      label={"Tax Rate (%)"}
                      placeholder={`eg: 10%`}
                    />
                  )}
                </div>
                <div
                  className={
                    "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
                  }
                >
                  <MyTextField
                    isRequired
                    type={"number"}
                    name={"monthlyCost.flatPropertyCost"}
                    label={`Flat Property Charge (${getCurrency()}) (Per Property)`}
                    placeholder={`eg: ${getCurrency()}500`}
                  />
                </div>
              </div>
            )}
          </FormWrapper>
        </>
      )}

      <FormWrapper
        header={{
          title: { name: "Property" },
        }}
      >
        <div className={"flex flex-col gap-5"}>
          {!values?.isFreeForever && (
            <div
              className={
                "grid grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
              }
            >
              <MyRadioInput
                name={"isMaxPropertyUnlimited"}
                label={"Unlimited Property"}
              />
            </div>
          )}

          <div
            className={
              "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
            }
          >
            {!values?.isMaxPropertyUnlimited && !values?.isFreeForever && (
              <MyTextField
                type={"number"}
                name={"maxProperty"}
                label={"Maximum Number of Properties"}
                placeholder={`eg: 10`}
              />
            )}

            <MyTextField
              type={"number"}
              name={"propertyIncludedInBasePrice"}
              label={"Additional Property through Base Price"}
              placeholder={`eg: 9`}
            />
          </div>
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: { name: "Specifications" },
        }}
      >
        <FieldArray
          name={"specifications"}
          render={({ push, remove }) => {
            return (
              <div className={"flex flex-col gap-5"}>
                {values?.specifications?.map((e, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
                      }
                    >
                      <MyTextField
                        label={"Feature"}
                        placeholder={"eg: Allow SMS"}
                        name={`specifications.${index}.feature`}
                      />
                      <div className={"flex items-center gap-10 mt-8"}>
                        <MyRadioInput
                          label={"Include"}
                          name={`specifications.${index}.isIncluded`}
                        />
                        <div>
                          <MyButton
                            colorType={"danger"}
                            isOutline
                            name={"Remove"}
                            onClick={() => remove(index)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  <MyButton
                    isOutline
                    name={"Add More"}
                    onClick={() => {
                      push(PackageFormik.specificationValues());
                    }}
                    iconType={IconTypeEnum.ADD}
                  />
                </div>
              </div>
            );
          }}
        />
      </FormWrapper>
      <div
        className={
          "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
        }
      >
        <div className={"col-span-2"}>
          <MyTextAreaInput name={"description"} label={"Description"} />
        </div>
      </div>
    </div>
  );
}

export default PackageForm;
