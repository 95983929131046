import React, { createContext, useContext } from "react";

import { ApiUrl } from "services";
import { AppContext } from "context";
import { useDateHooks } from "useHooks";
import {
  Api,
  generatePurchaseHistoryReport,
  generateSubscriberReport,
} from "helpers";

interface ReportContextInterface {
  // report generate
  generateSubscriberReportHandler(query?: any);
  generatePurchaseHistoryReportHandler(query?: any);
}
export const ReportContext = createContext<ReportContextInterface>({
  generateSubscriberReportHandler(query?: any) {},
  generatePurchaseHistoryReportHandler(query?: any) {},
});

function DataContextProvider({ children }) {
  const { handlers } = useContext(AppContext);
  const { getApi } = Api();
  const { getDateHandler } = useDateHooks();

  //  handler
  const reportHandler = {
    async generateSubscriberReportHandler(query?: any) {
      try {
        handlers?.setLoading(true);
        let res = await getApi(ApiUrl.customers.get_customers, {
          ...query,
        });
        await generateSubscriberReport({
          data: res?.data?.docs || [],
          dateFormatter: getDateHandler,
        });
        handlers?.setSuccess(true, "Subscriber CSV exported successfully");
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    async generatePurchaseHistoryReportHandler(query?: any) {
      try {
        handlers?.setLoading(true);
        let res = await getApi(ApiUrl.purchaseHistory.get_purchaseHistory, {
          ...query,
        });
        await generatePurchaseHistoryReport({
          data: res?.data?.docs || [],
          dateFormatter: getDateHandler,
        });
        handlers?.setSuccess(true, "Purchase History CSV exported successfully");
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const contextValue = {
    ...reportHandler,
  };
  return (
    <ReportContext.Provider value={contextValue}>
      {children}
    </ReportContext.Provider>
  );
}

export default DataContextProvider;
