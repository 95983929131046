import { createContext, useContext, useState } from "react";

import { Api, getModuleNameIconHandler } from "helpers";
import { FieldTypeEnum, ModuleListEnum } from "interfaces";
import {
  OptionsContextInterface,
  OptionsDataEnum,
  PackageModuleOptionInterface,
} from "./interfaces";
import { ApiUrl } from "services";
import { AppContext } from "context";

export const OptionsContext = createContext<OptionsContextInterface>({
  fieldTypeOptions: {
    [FieldTypeEnum.OWNER_TYPE]: [],
    [FieldTypeEnum.DOCUMENT_TYPE]: [],
    [FieldTypeEnum.PROPERTY_TYPE]: [],
    [FieldTypeEnum.UNIT_SIZE]: [],
  },
  propertyOptions: [],
  unitOptions: [],
  ownerOptions: [],
  roleOptions: [],
  getRoleOptionsHandler() {},
  getOptionsHandler: (optionType: FieldTypeEnum) => {},
  getUnitOptionsHandler() {},
  getPropertyOptionsHandler() {},
  getOwnerOptionsHandler() {},
  getPackageModules() {
    return null;
  },
});

const AppProvider = ({ children }: any) => {
  const { handlers } = useContext(AppContext);
  const [fieldTypeOptions, setDynamicFieldOptions] = useState({});
  const [options, setOptions] = useState({
    propertyOptions: [],
    unitOptions: [],
    ownerOptions: [],
    roleOptions: [],
  });
  const setOptionsDataHandler = (type: OptionsDataEnum, value) => {
    setOptions((e) => {
      return {
        ...e,
        [type]: value,
      };
    });
  };

  const { getApi } = Api();

  const getOptionsHandler = async (optionType: FieldTypeEnum) => {
    try {
      if (fieldTypeOptions[optionType]) {
        return;
      }

      const res = await getApi(
        ApiUrl.configuration.dynamicList.get_listFromFieldType + optionType
      );
      //  getting form api
      setDynamicFieldOptions((e) => {
        return {
          ...e,
          [optionType]: res?.data,
        };
      });
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };

  const getPropertyOptionsHandler = async () => {
    try {
      const res = await getApi(ApiUrl.property.get_property);
      setOptionsDataHandler(OptionsDataEnum.propertyOptions, res?.data);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getRoleOptionsHandler = async () => {
    try {
      const res = await getApi(ApiUrl.privilege.get_adminPrivilege);
      setOptionsDataHandler(OptionsDataEnum.roleOptions, res?.data?.docs);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getUnitOptionsHandler = async () => {
    try {
      const res = await getApi(ApiUrl.unit.get_unit);
      setOptionsDataHandler(OptionsDataEnum.unitOptions, res?.data);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getOwnerOptionsHandler = async () => {
    try {
      const res = await getApi(ApiUrl.owner?.get_ownersInSpace);
      setOptionsDataHandler(OptionsDataEnum.ownerOptions, res?.data);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getPackageModules = (): PackageModuleOptionInterface[] => {
    return [
      {
        value: ModuleListEnum.RENTAL,
        name: getModuleNameIconHandler(ModuleListEnum.RENTAL).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.RENTAL).Icon,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.TENANCY).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.TENANCY).Icon,
        value: ModuleListEnum.TENANCY,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.INVOICE).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.INVOICE).Icon,
        value: ModuleListEnum.INVOICE,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.INVENTORY).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.INVENTORY).Icon,
        value: ModuleListEnum.INVENTORY,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.FINANCE).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.FINANCE).Icon,
        value: ModuleListEnum.FINANCE,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.BOOKING).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.BOOKING).Icon,
        value: ModuleListEnum.BOOKING,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.REPAIR_AND_MAINTENANCE)
          .name,
        Icon: getModuleNameIconHandler(ModuleListEnum.REPAIR_AND_MAINTENANCE)
          .Icon,
        value: ModuleListEnum.REPAIR_AND_MAINTENANCE,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.CANDIDATE).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.CANDIDATE).Icon,
        value: ModuleListEnum.CANDIDATE,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.TOOLS).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.TOOLS).Icon,
        value: ModuleListEnum.TOOLS,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.TASK).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.TASK).Icon,
        value: ModuleListEnum.TASK,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.NOTES).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.NOTES).Icon,
        value: ModuleListEnum.NOTES,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.COMMUNICATION).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.COMMUNICATION).Icon,
        value: ModuleListEnum.COMMUNICATION,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.AUTH).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.AUTH).Icon,
        value: ModuleListEnum.AUTH,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.CONFIGURATION).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.CONFIGURATION).Icon,
        value: ModuleListEnum.CONFIGURATION,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.DOCUMENT).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.DOCUMENT).Icon,
        value: ModuleListEnum.DOCUMENT,
      },
      {
        name: getModuleNameIconHandler(ModuleListEnum.BANK).name,
        Icon: getModuleNameIconHandler(ModuleListEnum.BANK).Icon,
        value: ModuleListEnum.BANK,
      },
    ];
  };
  let contextValue = {
    fieldTypeOptions,
    ...options,
    getRoleOptionsHandler,
    getOptionsHandler,
    getPropertyOptionsHandler,
    getUnitOptionsHandler,
    getOwnerOptionsHandler,
    getPackageModules,
  };

  return (
    <OptionsContext.Provider value={contextValue}>
      {children}
    </OptionsContext.Provider>
  );
};

export default AppProvider;
