import {
  AdminPrivilegeInterface,
  DynamicListInterface,
  FieldTypeEnum,
  OwnerInterface,
  PropertyInterface,
  PropertyOwnerInterface,
  UnitInterface,
} from "interfaces";

export interface OptionsContextInterface {
  fieldTypeOptions: {
    [FieldTypeEnum.DOCUMENT_TYPE]?: DynamicListInterface[];
    [FieldTypeEnum.OWNER_TYPE]?: DynamicListInterface[];
    [FieldTypeEnum.PROPERTY_TYPE]?: DynamicListInterface[];
    [FieldTypeEnum.UNIT_SIZE]?: DynamicListInterface[];
    [FieldTypeEnum.OWNERSHIP_TYPE]?: DynamicListInterface[];
    [FieldTypeEnum.PROPERTY_AMENITIES]?: DynamicListInterface[];
  };
  propertyOptions: PropertyInterface[];
  unitOptions: UnitInterface[];
  ownerOptions: PropertyOwnerInterface[];
  roleOptions: AdminPrivilegeInterface[];
  getRoleOptionsHandler();
  getOptionsHandler(optionType: FieldTypeEnum);
  getPropertyOptionsHandler();
  getUnitOptionsHandler();
  getOwnerOptionsHandler();
  getPackageModules(): PackageModuleOptionInterface[];
}

export interface PackageModuleOptionInterface {
  name: string;
  value: string;
  Icon: any;
}

export enum OptionsDataEnum {
  propertyOptions = "propertyOptions",
  unitOptions = "unitOptions",
  ownerOptions = "ownerOptions",
  roleOptions = "roleOptions",
}
