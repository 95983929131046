import { useContext } from "react";
import { useField } from "formik";

import { AppContext } from "context";
import { Api } from "helpers";
import { ApiUrl } from "services";
import { ImageViewCard } from "components";

import { MyUploadDocumentInterface } from "./interfaces";

export default function MyUploadPhoto({
  onChangeCallBack,
  ...props
}: MyUploadDocumentInterface) {
  const [field, meta, helpers] = useField(props);
  const { postFormApi } = Api();

  const { handlers } = useContext(AppContext);

  const { setValue } = helpers;
  let isError = meta.touched && meta.error;
  const isMultiple = Array.isArray(field.value);
  const changeHandler = async (e) => {
    try {
      handlers?.setLoading(true);
      let file = e.target.files[0];
      const bodyFormData = new FormData();
      bodyFormData.append("file", file);
      bodyFormData.append("imageTitle", field?.name);

      let res: any = await postFormApi(
        ApiUrl.file.post_uploadFile,
        bodyFormData
      );
      if (!res.success) {
        throw res;
      }
      if (isMultiple) {
        let value = [...field.value, res.name];
        helpers?.setValue(value);
      } else {
        helpers?.setValue(res.name);
      }
      if (typeof onChangeCallBack === "function") {
        onChangeCallBack(res.name);
      }
    } catch (error) {
      handlers.setError(error.message);
    } finally {
      handlers?.setLoading(false);
    }
  };

  const removeImageHandler = (index) => {
    if (isMultiple) {
      const value = [...field.value];
      value.splice(index, 1);
      setValue(value);
    } else {
      setValue("");
    }
  };

  return (
    <div className="flex flex-col items-start  gap-2">
      <span className={"input_label"}>{props?.label}</span>
      {/*selected images*/}
      <div className="flex items-start gap-2">
        {isMultiple
          ? field?.value?.length > 0 && (
              <>
                {field?.value?.map((e, key) => {
                  return (
                    <ImageViewCard
                      removeHandler={() => removeImageHandler(key)}
                      key={key}
                      fileName={e}
                    />
                  );
                })}
              </>
            )
          : field.value && (
              <ImageViewCard
                removeHandler={() => removeImageHandler(0)}
                fileName={meta.value}
              />
            )}
      </div>

      {/* select file*/}
      <div className="text-center flex flex-col items-center justify-center cursor-pointer">
        {(isMultiple || !field.value) && (
          <label>
            <input
              type="file"
              className={"rounded-full text-blue-500 bg-gray-100 text-[14px]"}
              accept="application/jpg application/png"
              name={field.name}
              onChange={changeHandler}
            />
          </label>
        )}
        {isError && <span className="input_textError">* {meta.error}</span>}
      </div>
    </div>
  );
}
