import React, { useContext, useEffect } from "react";

import { Form, Formik } from "formik";

import { FormConcludeButtons } from "assets";

import { useNavigate, useParams } from "react-router-dom";

import { PageLinks } from "routes";
import { AppContext, UserContext } from "context";
import { PageTemplate } from "templates";
import { ParamsStringEnum } from "interfaces";

import { UserFormik } from "../helpers";
import { UserForm } from "../components";

function EditUserPage() {
  const params = useParams<ParamsStringEnum>();
  const {
    handlers: { setLoading },
  } = useContext(AppContext);
  const { details, getDetailsHandler, editDetailsHandler } =
    useContext(UserContext);
  const navigate = useNavigate();
  const isEdit = params?.id;
  useEffect(() => {
    if (isEdit) {
      (async () => {
        setLoading(true);
        await getDetailsHandler(isEdit);
        setLoading(false);
      })();
    }
  }, [isEdit]);
  const submitHandler = async (values, helpers) => {
    await editDetailsHandler(values, helpers, {
      onSuccess: async (payload) => {
        navigate(PageLinks.user.list);
      },
    });
  };
  return (
    <PageTemplate
      title={"Users"}
      breadCrumb={[
        {
          name: "Users",
        },
      ]}
      backNavigation={{
        title: `${isEdit ? "Edit" : "Add"} User`,
        path: PageLinks.user.list,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={UserFormik?.initialValues(isEdit && details)}
        validationSchema={UserFormik?.validationSchema(isEdit ? true : false)}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <UserForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                  handler: () => {
                    navigate(PageLinks.user.list);
                  },
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default EditUserPage;
