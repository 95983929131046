const actionTypes = {
  APP_SET_AUTHENTICATED: "LOGIN_USER",
  LOGOUT: "LOGOUT_USER",
  APP_SET_PROFILE: "APP_SET_PROFILE",
  APP_SET_SETTING: "APP_SET_SETTING",
  APP_SET_LOADING: "APP_SET_LOADING",
  APP_SET_ERROR: "APP_SET_ERROR",
  APP_SET_SUCCESS_ACTION: "APP_SET_SUCCESS_ACTION",
  APP_SET_ACTION_LOADING: "APP_SET_ACTION_LOADING",
  APP_SET_NOTIFICATION: "APP_SET_NOTIFICATION",
  APP_SET_MESSAGE: "APP_SET_MESSAGE",
  APP_SET_AUTH_LOADING: "APP_SET_AUTH_LOADING",
  APP_SET_SYSTEM_SETTINGS: "APP_SET_SYSTEM_SETTINGS",
  APP_SET_PAGE_ERROR: "APP_SET_PAGE_ERROR",
};

export default actionTypes;
