import React from "react";
import { FormWrapperInterface } from "./interfaces";

function FormWrapper({ header, children }: FormWrapperInterface) {
  let { title, className, rightChildren, leftChildren } = header;
  return (
    <div className={"flex flex-col gap-7"}>
      {/*  header*/}
      <div className={`flex items-center justify-between ${className}`}>
        <div className={"flex items-center gap-5"}>
          <span className={`font-bold text-[20px] ${title?.className}`}>
            {title?.name}
            {title?.isRequired && <span className={"text-red-500"}>*</span>}
          </span>
          {leftChildren}
        </div>
        {rightChildren}
      </div>
      {/* children*/}
      <div className={"flex flex-col gap-5"}>{children}</div>
    </div>
  );
}

export default FormWrapper;
