import React, { useContext } from "react";

import Popup from "reactjs-popup";
import { NavLink } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";

import { AppContext } from "context";
import { MyIconButton } from "components";
import { AccessibleSettingLinks } from "routes";
import { IconTypeEnum } from "interfaces";

import "./style.css";

function SettingDetail() {
  const { handlers } = useContext(AppContext);
  const settingLinks = AccessibleSettingLinks();

  return (
    <Popup
      key={`tp-sd`}
      trigger={
        <button type="button" className="button">
          <MyIconButton title={"Settings"} IconType={IconTypeEnum.SETTING} />
        </button>
      }
      position={"bottom right"}
      on={["click"]}
      contentStyle={{ backgroundColor: "white", width: 250 }}
      arrow
      arrowStyle={{ color: "white" }}
    >
      <div>
        <div className="topNav_setting_modal">
          <div className={"flex flex-col "}>
            {settingLinks[0].map((e, key) => {
              let Icon = e?.Icon;
              let isLastItem = settingLinks[0]?.length - 1 == key;
              return (
                <NavLink
                  key={key}
                  to={e?.path}
                  className={`setting_navLink ${isLastItem && "border-none"}`}
                >
                  <Icon className={"text-[18px]"} /> <span>{e?.name}</span>
                </NavLink>
              );
            })}
          </div>
          <span className={"text-gray-500 text-[14px]"}>My Account</span>
          <div className={"flex flex-col "}>
            {settingLinks[1].map((e, key) => {
              let Icon = e?.Icon;
              return (
                <NavLink to={e?.path} key={key} className="setting_navLink">
                  <Icon className={"text-[18px]"} /> <span>{e?.name}</span>
                </NavLink>
              );
            })}
            <div
              onClick={handlers.logout}
              className="setting_navLink border-none"
            >
              <IoLogOutOutline className={"text-[18px]"} /> Log Out
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default SettingDetail;
