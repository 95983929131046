import { AdminAccessListEnum, NavLinkInterface } from "interfaces";
import { MdAlternateEmail } from "react-icons/md";
import { BsPersonCheck } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { IoCopyOutline, IoGridOutline } from "react-icons/io5";
import { AiOutlineLock, AiOutlineSetting, AiOutlineStar } from "react-icons/ai";
import { PageLinks } from "./index";
import { useAuthorizationHook } from "../useHooks";
import { TfiTimer } from "react-icons/tfi";

export const AccessibleNavLinks = (): NavLinkInterface[][] => {
  let links = navLinks;
  let accessibleNavLinks: NavLinkInterface[] = [].concat(...links);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const accLink = accessibleNavLinks?.reduce((acc, currentValue) => {
    if (checkCanAccessHandler(currentValue?.access)) {
      if (!currentValue?.isGroup) {
        return [...acc, currentValue];
      } else {
        const childFiltered = currentValue?.groups?.reduce(
          (childAcc, childCurrent) => {
            if (checkCanAccessHandler(childCurrent?.access)) {
              return [...childAcc, childCurrent];
            }
            return childAcc;
          },
          []
        );
        return [
          ...acc,
          {
            ...currentValue,
            groups: childFiltered,
          },
        ];
      }
    }
    return acc;
  }, []);
  // group into 2
  const result = accLink.reduce((acc, current, index) => {
    if (index % 2 === 0) {
      acc.push([current]);
    } else {
      acc[acc.length - 1].push(current);
    }
    return acc;
  }, []);
  return result;
};
export const AccessibleSettingLinks = (): NavLinkInterface[][] => {
  let links = settingLinks;
  const { checkCanAccessHandler } = useAuthorizationHook();
  let results = [];
  links.forEach((e) => {
    const accLink = e?.reduce((acc, currentValue) => {
      if (checkCanAccessHandler(currentValue?.access)) {
        if (!currentValue?.isGroup) {
          return [...acc, currentValue];
        } else {
          const childFiltered = currentValue?.groups?.reduce(
            (childAcc, childCurrent) => {
              if (checkCanAccessHandler(childCurrent?.access)) {
                return [...childAcc, childCurrent];
              }
              return childAcc;
            },
            []
          );
          return [
            ...acc,
            {
              ...currentValue,
              groups: childFiltered,
            },
          ];
        }
      }
      return acc;
    }, []);

    results.push(accLink);
  });

  return results;
};

const navLinks: NavLinkInterface[][] = [
  [
    {
      name: "Dashboard",
      Icon: IoGridOutline,
      path: "/",
      isGroup: false,
      access: [AdminAccessListEnum.MISC_ACCESS_DASHBOARD],
    },
    {
      name: "Customers",
      Icon: AiOutlineStar,
      path: "/customers",
      isGroup: false,
      access: [AdminAccessListEnum.CUSTOMER_READ_CUSTOMER],
    },
  ],
  [
    {
      name: "Users",
      Icon: FiUsers,
      path: "/users",
      isGroup: false,
      access: [AdminAccessListEnum.AUTH_READ_USER],
    },
    {
      name: "Roles & Permissions",
      Icon: AiOutlineLock,
      path: "/roles",
      isGroup: false,
      access: [AdminAccessListEnum.AUTH_READ_ROLE],
    },
  ],
  [
    {
      name: "Packages",
      Icon: IoCopyOutline,
      path: PageLinks.package.list,
      isGroup: false,
      access: [AdminAccessListEnum.SUBSCRIPTION_READ_SUBSCRIPTION],
    },
    {
      name: "Purchase History",
      Icon: TfiTimer,
      path: PageLinks.purchaseHistory.list,
      isGroup: false,
      access: [AdminAccessListEnum.CUSTOMER_READ_PURCHASE_HISTORY],
    },
  ],
];
const settingLinks: NavLinkInterface[][] = [
  [
    {
      name: "Settings",
      Icon: AiOutlineSetting,
      path: PageLinks.settings.spaceSettings,
      isGroup: false,
      access: [AdminAccessListEnum.CONFIGURATION_MANAGE_SETTINGS],
    },
  ],
  [
    {
      name: "My Account",
      Icon: BsPersonCheck,
      path: PageLinks.profile.details,
      isGroup: false,
      access: [],
    },
    {
      name: "Change Password",
      Icon: MdAlternateEmail,
      path: PageLinks.profile.changePassword,
      isGroup: false,
      access: [],
    },
  ],
];
export { navLinks, settingLinks };
