import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { CustomerContext, ReportContext } from "context";
import { TableCardComponent } from "components";

import { ParamsStringEnum } from "interfaces";
import { PurchaseHistoryTableColumns } from "content";

function PurchaseHistoryTab() {
  const params = useParams<ParamsStringEnum>();
  const { purchaseHistory, isLoading, totalDocs, getPurchaseHistoryHandler } =
    useContext(CustomerContext);
  const { generatePurchaseHistoryReportHandler } = useContext(ReportContext);
  const tenantID = params?.tenantID;
  useEffect(() => {
    getPurchaseHistoryHandler(tenantID);
  }, [tenantID]);
  const tableCardProps = {
    column: PurchaseHistoryTableColumns(),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: purchaseHistory,
  };

  return (
    <TableCardComponent
      exportXlsx={{
        generateHandler() {
          generatePurchaseHistoryReportHandler({ landlord: tenantID });
        },
      }}
      havePagination={{
        totalDocs: totalDocs,
      }}
      {...tableCardProps}
    />
  );
}

export default PurchaseHistoryTab;
