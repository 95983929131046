import React from "react";
import { PageTemplateComponentInterface } from "./interfaces";

function PageTemplateComponent({
  data,
  children,
}: PageTemplateComponentInterface) {
  return (
    <div
      style={{
        backgroundImage: "url('/img/app/signUpBg.png')",
      }}
      className="flex items-center justify-center bg-no-repeat bg-cover hideScroll  w-full h-[100vh]"
    >
      <div
        className={
          "bg-white p-5 rounded-md overflow-y-scroll hideScroll flex flex-col  gap-2  min-w-[500px] max-w-[500px]   "
        }
      >
        <div>
          <h1 className="font-bold text-[25px]">{data?.title}</h1>
          <span className={"text-gray-400 text-[12px]"}>{data?.subTitle}</span>
        </div>
        {children}
      </div>
    </div>
  );
}

export default PageTemplateComponent;
