import { createContext, useContext } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { ApiUrl } from "services";
import { AppContext } from "context";
import { Api, setAuthToken } from "helpers";
import { SearchQueryEnum } from "../interfaces";
import { AuthTokenENUM, baseUrlType } from "interfaces";

export const DataContext = createContext({
  loginHandler: (values, actions) => {},
  signUpAsLandlordHandler: (values, actions) => {},
  requestForgetPasswordHandler: (values, actions) => {},
  validateForgetPasswordOTPHandler: (values, actions) => {},
  resetPasswordHandler: (values, actions) => {},
});

export default function DataContextProvider() {
  const { postApi, putApi } = Api();
  const { handlers } = useContext(AppContext);
  const navigate = useNavigate();
  const { login } = handlers;
  const [query] = useSearchParams();
  const paramHash = decodeURIComponent(query.get(SearchQueryEnum.HASH) || "");
  const paramEmail = decodeURIComponent(query.get(SearchQueryEnum.EMAIL) || "");
  const paramToken = decodeURIComponent(query.get(SearchQueryEnum.TOKEN || ""));

  const loginHandler = async (values, { setFieldError, setSubmitting }) => {
    try {
      setSubmitting(true);
      let res: any = await postApi(ApiUrl.auth.post_login, values);
      setAuthToken(res?.data?.accessToken, AuthTokenENUM.accessToken);
      setAuthToken(res?.data?.refreshToken, AuthTokenENUM.refreshToken);
      await handlers.getCurrentProfile();
      navigate("/");
    } catch (error) {
      setFieldError("error", error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const signUpAsLandlordHandler = async (
    values,
    { setSubmitting, setFieldError }
  ) => {
    try {
      setSubmitting(true);
      let res: any = await postApi(ApiUrl.auth.post_registerAsLandLord, values);

      let token = res.data.refreshToken;
      login(token);
      // await getCurrentProfile();
    } catch (error) {
      setFieldError("error", error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const requestForgetPasswordHandler = async (values, action) => {
    try {
      action?.setSubmitting && action?.setSubmitting(true);
      let res: any = await putApi(
        ApiUrl.auth.post_requestForgetPassword,
        values
      );
      const email = encodeURIComponent(res?.data?.email);
      const hash = encodeURIComponent(res?.data?.hash);

      navigate({
        pathname: "/auth/verifyOTP",
        search: `${SearchQueryEnum?.HASH}=${hash}&${SearchQueryEnum.OTP_LENGTH}=${res?.data?.otpLength}&${SearchQueryEnum?.EMAIL}=${email}`,
      });
      handlers?.setSuccess(true, res?.message);
    } catch (error) {
      action?.setFieldError && action?.setFieldError("error", error?.message);
    } finally {
      action?.setSubmitting && action?.setSubmitting(false);
    }
  };
  const validateForgetPasswordOTPHandler = async (
    values,
    { setSubmitting, setFieldError }
  ) => {
    try {
      setSubmitting(true);
      let res: any = await putApi(ApiUrl.auth.post_validateForgetPasswordOtp, {
        ...values,
        hash: paramHash,
        emailOrPhone: paramEmail,
      });
      const token = encodeURIComponent(res?.data?.token);

      navigate({
        pathname: "/auth/changePassword",
        search: `${SearchQueryEnum?.TOKEN}=${token}&${SearchQueryEnum?.EMAIL}=${paramEmail}`,
      });
    } catch (error) {
      setFieldError("error", error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const resetPasswordHandler = async (
    values,
    { setSubmitting, setFieldError }
  ) => {
    try {
      setSubmitting(true);
      let token = `${paramToken}`;
      await putApi(
        ApiUrl.auth.put_resetPassword,
        values,
        AuthTokenENUM.refreshToken,
        baseUrlType.DEFAULT,
        token
      );
      navigate("/auth/login");
      handlers?.setSuccess(true, "Password saved");
    } catch (error) {
      setFieldError("error", error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  let contextValue = {
    loginHandler,
    signUpAsLandlordHandler,
    requestForgetPasswordHandler,
    validateForgetPasswordOTPHandler,
    resetPasswordHandler,
  };
  return (
    <DataContext.Provider value={contextValue}>
      <Outlet />
    </DataContext.Provider>
  );
}
