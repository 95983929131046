import React, { useContext } from "react";
import {
  ColorTypeOptionEnum,
  IconTypeEnum,
  PurchasePackageInterface,
  TableColumnInterface,
} from "interfaces";

import { useDateHooks, usePaginationQueryHooks } from "useHooks";
import { getCurrency, separateWithComma } from "helpers";
import { MyIconButton } from "components";
import { PDFContext } from "context";
import { StatusText } from "assets";

function PurchaseHistoryTableColumns(): TableColumnInterface<PurchasePackageInterface>[] {
  const { calcSNHandler } = usePaginationQueryHooks();
  const { getDateHandler } = useDateHooks();
  const { downloadSubscriptionBillPDF } = useContext(PDFContext);
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Order ID",
      render(renderData) {
        return (
          <div className={"text-[12px] text-red-500 bg-gray-100 text-center"}>
            {renderData?.invoiceID}
          </div>
        );
      },
    },
    {
      title: "Refernce Number",
      render(renderData) {
        return (
          <div className={"text-[12px] text-red-500 bg-gray-100 text-center"}>
            {renderData?.stripe?.intentID}
          </div>
        );
      },
    },
    {
      title: "Package",
      render(renderData) {
        return <div>{renderData?.subscribedPackage?.name}</div>;
      },
    },
    {
      title: "Properties",
      render(renderData) {
        return (
          <div className={"flex items-start "}>
            {renderData?.propertyQuantity}
          </div>
        );
      },
    },
    {
      title: "Paid With",
      render(renderData) {
        return <div>{renderData?.paymentMode}</div>;
      },
    },
    {
      title: "Price",
      render(renderData) {
        return (
          <div className={"whitespace-nowrap"}>
            {getCurrency()}{""}
            {separateWithComma(renderData?.orderCostDetail?.payableAmount)}
          </div>
        );
      },
    },

    {
      title: "Purchased Date",
      render(renderData) {
        return (
          <span>{getDateHandler({ date: renderData?.packageStartsAt })}</span>
        );
      },
    },
    {
      title: "Expiry Date",
      render(renderData) {
        return (
          <div className={"flex flex-col gap-1"}>
            {renderData?.numberOfDaysRemains > 0 && (
              <span className={"text-[14px] text-gray-500"}>
                {renderData?.numberOfDaysRemainsText}
              </span>
            )}

            {getDateHandler({
              date:
                renderData?.packageExpiredAt || renderData?.packageExpiryDate,
              haveTime: true,
            })}
          </div>
        );
      },
    },
    {
      title: "Status",
      render(renderData) {
        return (
          <StatusText
            status={
              renderData?.isExpired
                ? ColorTypeOptionEnum.GRAY
                : ColorTypeOptionEnum.GREEN
            }
            label={renderData?.isExpired ? "Expired" : "Active"}
          />
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <MyIconButton
            onClick={() => {
              downloadSubscriptionBillPDF(renderData?._id, renderData?.orderID);
            }}
            iconSize={16}
            IconType={IconTypeEnum.DOWNLOAD}
          />
        );
      },
    },
  ];
}

export default PurchaseHistoryTableColumns;
