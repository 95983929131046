const PageLinks = {
  package: {
    list: "/packages",
    details: (itemID: string) => `/packages/details/${itemID}`,
    edit: (itemID: string) => `/packages/edit/${itemID}`,
    create: `/packages/create`,
  },
  user: {
    list: "/users",
    details: (itemID: string) => `/users/details/${itemID}`,
    edit: (itemID: string) => `/users/edit/${itemID}`,
    create: `/users/create`,
  },
  role: {
    list: "/roles",
    details: (itemID: string) => `/roles/details/${itemID}`,
    edit: (itemID: string) => `/roles/edit/${itemID}`,
    create: `/roles/create`,
  },
  customers: {
    list: "/customers",
    details: (itemID: string) => `/customers/details/${itemID}`,
  },
  settings: {
    spaceSettings: "/settings/space-settings",
  },
  purchaseHistory: {
    list: "/purchase-history",
  },
  profile: {
    details: "/profile",
    edit: "/profile/edit",
    changePassword: "/profile/changePassword",
  },
};
export default PageLinks;
