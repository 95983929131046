import { SystemSettingsInterface } from "interfaces";
import * as yup from "yup";
import { addressInitialValues } from "content";

export const SystemSettingFormikValues = {
  initialValues: (values?: SystemSettingsInterface) => {
    return {
      companyName: values?.companyName,
      companyLogo: values?.companyLogo,
      companyPan: values?.companyPan,
      companyRegistration: values?.companyRegistration,
      contactDetail: values?.contactDetail,
      website: values?.website,
      companyEmail: values?.companyEmail,
      brevoKey: values?.brevoKey,
      sender: {
        email: values?.sender?.email,
        name: values?.sender?.name,
      },
      shootSubscriptionReminder: values?.shootSubscriptionReminder || [],

      invoicePrefix: {
        separator: values?.invoicePrefix?.separator,
        SUBSCRIPTION: values?.invoicePrefix?.SUBSCRIPTION,
      },

      aboutUsPageURL: values?.aboutUsPageURL,
      privacyPolicyPageURL: values?.privacyPolicyPageURL,
      appStoreLink: values?.appStoreLink,
      playStoreLink: values?.playStoreLink,
      termsAndConditionURL: values?.termsAndConditionURL,

      ...addressInitialValues(values?.companyAddress),
    };
  },
  validationSchema: yup.object().shape({}),
};
