import * as yup from "yup";
import { AdminInterface } from "interfaces";

export const editProfileFormik = {
  initialValues: (values?: AdminInterface) => {
    return {
      name: values?.name,
      phone: values?.phone || "",
      photo: values?.photo || "",
    };
  },
  validationSchema: yup.object().shape({}),
};

export const editChangePasswordFormik = {
  initialValues: () => {
    return {
      oldPassword: "",
      password: "",
    };
  },
  validationSchema: yup.object().shape({
    oldPassword: yup.string().required("required"),
    password: yup
      .string()
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      )
      .required("required"),
  }),
};
