import React, { useContext, useEffect, useState } from "react";
import { AppContext, DashboardStatsContext } from "context";
import { getCurrency, getIconHelper, separateWithComma } from "helpers";
import { IconTypeEnum } from "interfaces";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Doughnut } from "react-chartjs-2";
import { SubscriptionInterface, UserStatsInterface } from "../interfaces";
import { DateSelectModal } from "../components";

ChartJS.register(ArcElement, Tooltip, Legend);

function RevenueStatsContent() {
  const { getSubscriptionStatsHandler, getUserStatsHandler } = useContext(
    DashboardStatsContext
  );
  const {
    handlers: { setLoading },
  } = useContext(AppContext);
  const UserIcon = getIconHelper(IconTypeEnum.USER);
  const UsersIcon = getIconHelper(IconTypeEnum.USERS);
  const HomeHandIcon = getIconHelper(IconTypeEnum.HOME_HAND);
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
    type: null,
  });
  const [userStats, setUserStats] = useState<UserStatsInterface>(null);
  const [subscriptionStats, setSubscriptionStats] =
    useState<SubscriptionInterface>(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const subscriptionData: any = await getSubscriptionStatsHandler(
          dateFilter
        );
        const userData: any = await getUserStatsHandler(dateFilter);
        setSubscriptionStats(subscriptionData);
        setUserStats(userData);
      } catch (er) {
      } finally {
        setLoading(false);
      }
    })();
  }, [dateFilter?.endDate, dateFilter?.startDate, dateFilter?.type]);
  const pieData = {
    labels: ["Monthly Subscriber", "Yearly Subscriber", "Free Subscriber"],
    datasets: [
      {
        label: "Tenancies",
        data: [
          subscriptionStats?.monthlySubscription?.count,
          subscriptionStats?.yearlySubscription?.count,
          subscriptionStats?.freeSubscription?.count,
        ],
        backgroundColor: ["#6B9DFE", "#FFBC6E", "#B494FF"],
        borderColor: ["white", "white", "white"],
      },
    ],
  };

  return (
    <div className={"flex flex-col gap-5"}>
      <div className={"flex justify-between items-center  pb-2"}>
        <b className={"text-[24px]"}>Customers</b>
        <DateSelectModal
          dateFilter={dateFilter}
          submitHandler={setDateFilter}
        />
      </div>

      <>
        <div className={"grid grid-cols-4  gap-5"}>
          <div
            className={
              "flex items-center justify-start gap-5 bg-white rounded-md pl-5 text-black min-h-[140px]"
            }
          >
            <UserIcon
              className={
                "w-[35px] h-[35px] fill-gray-200 text-gray-200 text-gray-200"
              }
            />
            <div className={"flex flex-col gap-2 text-[16px] "}>
              <span>Free Customers</span>
              <span className={"text-[22px] font-semibold"}>
                {separateWithComma(userStats?.freeUser) || "-"}
              </span>
            </div>
          </div>
          <div
            className={
              "flex items-center justify-start gap-5 bg-white rounded-md pl-5 text-black min-h-[140px]"
            }
          >
            <div className={"text-[32px] text-[#FE8D6B] "}>{getCurrency()}</div>
            <div className={"flex flex-col gap-2 text-[16px] "}>
              <span>Paid Customers</span>
              <span className={"text-[22px] font-semibold"}>
                {separateWithComma(userStats?.paidUser) || "-"}
              </span>
            </div>
          </div>{" "}
          <div
            className={
              "flex items-center justify-start gap-5 bg-white rounded-md pl-5 text-black min-h-[140px]"
            }
          >
            <UsersIcon
              className={
                "w-[35px] h-[35px] fill-gray-200 text-gray-200 text-gray-200"
              }
            />
            <div className={"flex flex-col gap-2 text-[16px] "}>
              <span>Total Customers</span>
              <span className={"text-[22px] font-semibold"}>
                {separateWithComma(userStats?.totalUser) || "-"}
              </span>
            </div>
          </div>{" "}
          <div
            className={
              "flex items-center justify-start gap-5 bg-white rounded-md pl-5 text-black min-h-[140px]"
            }
          >
            <HomeHandIcon className={"w-[35px] h-[35px] fill-gray-200200"} />
            <div className={"flex flex-col gap-2 text-[16px] "}>
              <span>Total Properties Bought</span>
              <span className={"text-[22px] font-semibold"}>
                {separateWithComma(userStats?.propertiesBought) || "-"}
              </span>
            </div>
          </div>
        </div>
        <div className={"bg-white rounded-md p-5"}>
          <div className={"border-b-2 pb-5"}>
            <b className={"text-[24px]"}>Overall</b>
          </div>
          <div className={"grid grid-cols-5 gap-2 pt-5 mt-2"}>
            <div className={"col-span-2 flex items-center justify-center"}>
              <div className={"h-[250px] w-[250px]"}>
                <Doughnut
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    cutout: 60,
                  }}
                  data={pieData}
                />
              </div>
            </div>
            <div className={"col-span-3 flex flex-col gap-5"}>
              <div className={"flex flex-col gap-1"}>
                <span className={"text-gray-500 text-[14px]"}>
                  Monthly Subscribers (
                  {subscriptionStats?.monthlySubscription?.count || 0})
                </span>
                <span className={"font-semibold text-[18px]"}>
                  {Math.abs(subscriptionStats?.monthlySubscription?.ratio) || 0}
                  %
                </span>
                <div
                  className={"relative w-full h-[8px] rounded-full bg-gray-200"}
                >
                  <div
                    style={{
                      width: `${
                        (subscriptionStats?.monthlySubscription?.ratio > 100
                          ? 100
                          : subscriptionStats?.monthlySubscription?.ratio) || 0
                      }%`,
                    }}
                    className={`absolute h-full w-[${
                      subscriptionStats?.monthlySubscription?.ratio || 0
                    }%] bg-[#3944F7] rounded-full`}
                  ></div>
                </div>
              </div>
              <div className={"flex flex-col gap-1"}>
                <span className={"text-gray-500 text-[14px]"}>
                  Yearly Subscribers (
                  {subscriptionStats?.yearlySubscription?.count || 0})
                </span>
                <span className={"font-semibold text-[18px]"}>
                  {Math.abs(subscriptionStats?.yearlySubscription?.ratio) || 0}%
                </span>
                <div
                  className={"relative w-full h-[8px] rounded-full bg-gray-200"}
                >
                  <div
                    style={{
                      width: `${
                        (subscriptionStats?.yearlySubscription?.ratio > 100
                          ? 100
                          : subscriptionStats?.yearlySubscription?.ratio) || 0
                      }%`,
                    }}
                    className={`absolute h-full bg-[#EDC126] rounded-full`}
                  ></div>
                </div>
              </div>
              <div className={"flex flex-col gap-1"}>
                <span className={"text-gray-500 text-[14px]"}>
                  Free Customers (
                  {subscriptionStats?.freeSubscription?.count || 0})
                </span>
                <span className={"font-semibold text-[18px]"}>
                  {Math.abs(subscriptionStats?.freeSubscription?.ratio) || 0}%
                </span>
                <div
                  className={"relative w-full h-[8px] rounded-full bg-gray-200"}
                >
                  <div
                    style={{
                      width: `${
                        (subscriptionStats?.freeSubscription?.ratio > 100
                          ? 100
                          : subscriptionStats?.freeSubscription?.ratio) || 0
                      }%`,
                    }}
                    className={`absolute h-full z-10  bg-[#8D3DAF] rounded-full`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default RevenueStatsContent;
