export enum baseUrlType {
  DEFAULT = "default",
}

export enum AuthTokenENUM {
  accessToken = "accessToken",
  refreshToken = "refreshToken",
}
export enum DateRangeOptionEnum {
  FISCAL_YEAR = "FISCAL_YEAR",
  THIS_MONTH = "THIS_MONTH",
  THIS_YEAR = "THIS_YEAR",
  LAST_MONTH = "LAST_MONTH",
  LAST_YEAR = "LAST_YEAR",
  ALL_TIME = "ALL_TIME",
  CUSTOM = "CUSTOM",
}
export enum QueryStringEnum {
  Archive = "archive", // for normal and archive
  Search = "q", // for searching
  PROPERTY_ID = "propertyID",
  BANK = "BANK",
  TRANSACTION_TYPE = "TRANSACTION_TYPE",
  CODE = "code",
  INVOICE_ID = "invoiceID",
  DATE = "d",
  ACCOUNT_TYPE = "ACCOUNT_TYPE",
  USER_ID = "userID",
  UNIT_ID = "unitID",
  ITEM_ID = "itemID",
  ID = "id",
  ACTIVE_TAB = "activeTab",
  EDIT = "edit",
  STATUS = "status",
  TYPE = "type",
  RANGE_TYPE = "rangeType",
  FISCAL_YEAR = "fiscalYear",
  RETURN_URL = "returnURL",
  CURRENT_PAGE = "currentPage",
  MESSAGE_STATUS = "messageStatus",
  START_DATE = "startDate",
  END_DATE = "endDate",
  COMPARE_TYPE = "compareType",
  FIRST_COMPARE_DATE = "firstCompareDate",
  SECOND_COMPARE_DATE = "secondCompareDate",
}
export enum CompareTypeEnum {
  YEAR = "year",
  MONTH = "month",
}

export enum ParamsStringEnum {
  PROPERTY_ID = "propertyId",
  UNIT_ID = "unitId",
  USER_ID = "userId",
  TENANCY_ID = "tenancyId",
  ID = "id",
  TYPE = "type",
  USER_TYPE = "userType",
  TENANT_ID = "tenantID",
  Archive = "Archive",
}

export enum IconTypeEnum {
  CALENDAR = "CALENDAR",
  FILE = "FILE",
  GROWTH = "GROWTH",
  HOME_HAND = "HOME_HAND",
  USER = "USER",
  USERS = "USERS",
  ADD = "ADD",
  DOLLAR = "DOLLAR",
  SELECTED_RADIO = "SELECTED_RADIO",
  UN_SELECTED_RADIO = "UN_SELECTED_RADIO",
  UP = "UP",
  MINUS = "MINUS",
  DOT = "DOT",
  MAIL = "MAIL",
  CHECK_ALL = "CHECK_ALL",
  DOWN_ARROW = "DOWN_ARROW",
  DOWNLOAD = "DOWNLOAD",
  DASHBOARD_SETTING = "DASHBOARD_SETTING",
  DASHBOARD_HAND_HOME = "DASHBOARD_HAND_HOME",
  UP_ARROW = "UP_ARROW",
  BOOKING_CANCELLED = "  BOOKING_CANCELLED",
  LAYER = "  LAYER",
  BOOKING_CONFIRM = "  BOOKING_CONFIRM",
  BOOKING_PENDING = "  BOOKING_PENDING",
  SALES_SETTLED = "  SALES_SETTLED",
  SALES_STATUS_DRAFT = "  SALES_STATUS_DRAFT",
  UNIT_OCCUPIED = "  UNIT_OCCUPIED",
  UNIT_VACANT = "  UNIT_VACANT",
  HOME = "  HOME",
  PROPERTY_VALUE = "  PROPERTY_VALUE",
  RENTAL_INCOME_TOTAL = "  RENTAL_INCOME_TOTAL",
  RENTAL_PROFILE = "  RENTAL_PROFILE",
  RELOAD = "  RELOAD",
  KEYS = "KEYS",
  GRAPH = "GRAPH",
  STAT_TAGS = "STAT_TAGS",
  SETTLED = "SETTLED",
  CANCEL = "CANCEL",
  UNIT = "UNIT",
  PROPERTY = "PROPERTY",
  DOWN = "DOWN",
  JOURNAL = "JOURNAL",
  Images = "Images",
  SETTING = "SETTING",
  EDIT = "EDIT",
  CLOSE = "CLOSE",
  BUILDING = "BUILDING",
  CALENDER = "CALENDER",
  NOTIFICATION = "NOTIFICATION",
  CLOCK = "CLOCK",
  ARCHIVE = "ARCHIVE",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  ADDRESS = "ADDRESS",
  DELETE = "DELETE",
  SEARCH = "SEARCH",
  QUESTION = "QUESTION",
  SQUARE_FILL = "SQUARE_FILL",
  SQUARE_CHECK = "SQUARE_CHECK",
  TOGGLE_ON = "TOGGLE_ON",
  TOGGLE_OFF = "TOGGLE_OFF",
  CHECK = "CHECK",
  CHECK_FILL = "CHECK_FILL",
  UN_CHECK = "UN_CHECK",
  DISCOUNT = "DISCOUNT",
}

export enum InputSizeEnum {
  SM = "sm",
  MD = "md",
  LG = "lg",
}

export enum TIME_BASIS_ENUM {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}
