import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import "./styles.css";
import { SearchInterface } from "./interfaces";
import { usePageQueryHooks, usePaginationQueryHooks } from "../../useHooks";

export default function MySearchInput({
  placeholder,
  paramsName,
  type,
  label,
}: SearchInterface) {
  const [query, setQuery] = useSearchParams();
  const { search } = usePageQueryHooks();
  const [value, setValue] = useState(search || "");
  const { setCurrentPage } = usePaginationQueryHooks();

  const onChangeText = (e) => {
    const value = e?.target?.value;
    setValue(value);
    query.set(paramsName, value);
    setQuery(query);
    setCurrentPage("1");
  };

  const submitHandler = () => {
    if (!value) {
      query.delete(paramsName);
    } else if (query.get(paramsName)) {
      query.set(paramsName, encodeURIComponent(value));
    } else {
      query.append(paramsName, encodeURIComponent(value));
    }
    setCurrentPage("1");
    setQuery(query);
  };
  return (
    <div className={"input_container"}>
      {label && <label className={"input_label"}>{label}</label>}
      <input
        type={type || "text"}
        onChange={onChangeText}
        placeholder={placeholder || "search..."}
        value={value}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            submitHandler();
          }
        }}
        className={` rounded-full outline-none  focus:border-[#2680EB] border-[1px] border-[#C0C0C0] text-[16px] pl-6 h-[48px]`}
      />
    </div>
  );
}
