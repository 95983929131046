import React from "react";
import { FormikHelpers } from "formik/dist/types";
import { AdminAccessListEnum } from "../modules";

export interface BreadCrumbInterface {
  name: string;
  path?: string;
}

export enum ColorTypeOptionEnum {
  GREEN = "GREEN",
  RED = "RED",
  YELLOW = "YELLOW",
  GRAY = "GRAY",
  BLUE = "BLUE",
}
export interface PageTemplateInterface {
  title?: string;
  children?: any;
  breadCrumb?: BreadCrumbInterface[];
  isBgTransparent?: boolean;
  backNavigation?: {
    title: string;
    path?: string;
    overRideReturnURL?: boolean;
  };
  dataTypeToggle?: {
    isDataType: boolean;
    badgeValue: [number, number];
  };
  scrollAll?: boolean;
  titleRightChildren?: JSX.Element;
}

export interface TableColumnInterface<dataType> {
  title?: string;
  render(renderData: dataType, key: number): JSX.Element;
}
export interface TableRowSelectionInterface {
  selectRow: boolean;
  key?: string;
  selectedRows: any[];
  onSelectChange(newSelectedRow: any[]);
}

export interface TableInterface {
  column: TableColumnInterface<any>[];
  dataSource: any[];
  loading?: {
    isLoading: boolean;
    loadingRow?: number;
  };
  rowSelections?: TableRowSelectionInterface;
}
export interface NavLinkInterface {
  name: string;
  path?: string;
  Icon: any;
  InActiveIcon?: any;
  isGroup: boolean;
  access: AdminAccessListEnum[];
  groups?: NavLinkInterface[];
}

export interface HandlerCallbackInterface {
  onSuccess(payload?: any): Promise<any> | any;
  onError?(error?: any);
}

export interface DataContextInterface<moduleInterface> {
  isLoading: boolean;
  isDetailsLoading: boolean;
  allData: moduleInterface[];
  details?: moduleInterface;
  totalDocs?: number;
  totalArchiveDocs?: number;
  getAllDataHandlers(query?: any);
  getDetailsHandler?(itemId: string, isFromEdit?: boolean);
  editDetailsHandler?(
    values,
    actions: FormikHelpers<moduleInterface>,
    callback?: HandlerCallbackInterface
  );
  archiveHandler?(
    itemId: string,
    status: boolean,
    callback?: HandlerCallbackInterface
  );
  deleteHandler?(itemId: string, callback?: HandlerCallbackInterface);
}

export interface AdminAccessRoleInterface {
  label: string;
  name: string;
  access?: AdminAccessRoleInterface[];
}
