import React, { useContext, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

import { TabComponent } from "components";
import { PageTemplate } from "templates";
import { CustomerContext } from "context";

import { ParamsEnum } from "../interfaces";
import { PageLinks } from "routes";
import { useAuthorizationHook } from "useHooks";
import { AdminAccessListEnum } from "interfaces";

function CustomerDetailsPage() {
  const query = useParams<ParamsEnum>();
  const tenantID = query?.tenantID;
  const { getDetailsHandler } = useContext(CustomerContext);
  useEffect(() => {
    if (tenantID) {
      getDetailsHandler(tenantID);
    }
  }, [tenantID]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canViewPurchaseHistory = checkCanAccessHandler([
    AdminAccessListEnum.CUSTOMER_READ_PURCHASE_HISTORY,
  ]);

  const tabs = [
    {
      title: "General Information",
      pathName: "",
    },
    {
      title: "Package",
      pathName: "packages",
    },
    canViewPurchaseHistory && {
      title: "Purchase History",
      pathName: "purchaseHistory",
    },
  ];
  return (
    <PageTemplate
      title={"Subscriber"}
      backNavigation={{
        title: "Subscriber's Details",
        path: PageLinks.customers.list,
      }}
    >
      <div className={"flex flex-col gap-10"}>
        <TabComponent tabs={tabs} />
        <Outlet />
      </div>
    </PageTemplate>
  );
}

export default CustomerDetailsPage;
