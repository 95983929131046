import * as yup from "yup";
import { AdminPrivilegeInterface } from "interfaces";

export const RolesFormik = {
  initialValues: (values?: AdminPrivilegeInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      name: values?.name,
      granted: values?.granted,
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().required("required"),
  }),
};
