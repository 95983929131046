import * as yup from "yup";
import { SubscriptionPackageInterface, TaxTypeEnum } from "interfaces";

export const PackageFormik = {
  specificationValues: (values?: any) => {
    return {
      feature: values?.feature,
      isIncluded: values?.isIncluded || false,
    };
  },
  initialValues: (values?: SubscriptionPackageInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      purchaseDisable: values?.purchaseDisable || false,
      name: values?.name,
      subtitle: values?.subtitle,
      description: values?.description,
      isFreeForever: values?.isFreeForever || false,
      trial: {
        enable: values?.trial?.enable,
        days: values?.trial?.days,
        text: values?.trial?.text,
      },
      annualCost: {
        enable: values?.annualCost?.enable || false,
        cost: values?.annualCost?.cost,
        taxRate: values?.annualCost?.taxRate,
        discountPercent: values?.annualCost?.discountPercent,
        taxType: values?.annualCost?.taxType || TaxTypeEnum.INCLUSIVE,
        flatPropertyCost: values?.annualCost?.flatPropertyCost,
      },
      monthlyCost: {
        enable: values?.monthlyCost?.enable || false,
        cost: values?.monthlyCost?.cost || 0,
        taxRate: values?.monthlyCost?.taxRate,
        discountPercent: values?.monthlyCost?.discountPercent,
        taxType: values?.monthlyCost?.taxType || TaxTypeEnum.INCLUSIVE,
        flatPropertyCost: values?.monthlyCost?.flatPropertyCost,
      },
      maxProperty: values?.maxProperty || 0,
      propertyIncludedInBasePrice: values?.propertyIncludedInBasePrice || 0,
      isMaxPropertyUnlimited: values?.isMaxPropertyUnlimited || false,
      storage: {
        base: values?.storage?.base || 0,
        perProperty: values?.storage?.perProperty || 0,
      },
      allowBankIntegration: values?.allowBankIntegration || false,
      allowSMS: values?.allowSMS || false,
      enableMail: values?.enableMail || false,
      enableMobileLogin: values?.enableMobileLogin || false,
      allowMessageModule: values?.allowMessageModule || false,
      enableTask: values?.enableTask,
      allowBooking: values?.allowBooking,
      enableMaintenance: values?.enableMaintenance,
      enableInventory: values?.enableInventory,
      allowSupplierUser: values?.allowSupplierUser,
      specifications: values?.specifications || [
        PackageFormik.specificationValues(),
      ],
    };
  },
  validationSchema: yup.object().shape({
    purchaseDisable: yup.boolean(),
    name: yup.string(),
    subtitle: yup.string(),
    description: yup.string(),
    allowBooking: yup.boolean(),
    trial: yup.object().shape({
      enable: yup?.boolean(),
      days: yup.number().min(0, "invalid"),
      text: yup.string(),
    }),
    annualCost: yup.object().shape({
      enable: yup.boolean(),
      cost: yup.number().min(0, "invalid"),
      taxRate: yup.number().min(0, "invalid").max(100, "invalid"),
      discountPercent: yup.number().min(0, "invalid").max(100, "invalid"),
      taxType: yup.string(),
      flatPropertyCost: yup.number().min(0, "invalid"),
    }),
    monthlyCost: yup.object().shape({
      enable: yup.boolean(),
      cost: yup.number().min(0, "invalid"),
      taxRate: yup.number().min(0, "invalid").max(100, "invalid"),
      discountPercent: yup.number().min(0, "invalid").max(100, "invalid"),
      taxType: yup.string(),
      flatPropertyCost: yup.number().min(0, "invalid"),
    }),
    maxProperty: yup.number().min(0, "invalid"),
    isFreeForever: yup.boolean(),

    propertyIncludedInBasePrice: yup
      .number()
      .min(0, "invalid")
      .test("custom-validation", "Invalid field value", function (value) {
        const isError =
          value > this.parent.maxProperty &&
          !this.parent.isMaxPropertyUnlimited &&
          !this.parent.isFreeForever;
        const shouldBeAtLeast1 = this.parent.isFreeForever && value < 1;
        if (shouldBeAtLeast1) {
          return this.createError({
            path: "propertyIncludedInBasePrice",
            message: "It should be at least 1 property",
          });
        }
        return (
          !isError ||
          this.createError({
            path: "propertyIncludedInBasePrice",
            message: "It should not exceed the maximum number of properties",
          })
        );
      }),
    isMaxPropertyUnlimited: yup.boolean(),
    storage: yup.object().shape({
      base: yup.number().min(0, "invalid"),
      perProperty: yup.number().min(0, "invalid"),
    }),
    allowBankIntegration: yup.boolean(),
    allowSMS: yup.boolean(),
    enableMail: yup.boolean(),
    enableMobileLogin: yup.boolean(),
    allowMessageModule: yup.boolean(),
    specifications: yup.array().of(
      yup.object().shape({
        feature: yup.string(),
        isIncluded: yup.boolean(),
      })
    ),
  }),
};
