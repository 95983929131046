import React from "react";
import { NameValueTextInterface } from "./interfaces";
import Skeleton from "react-loading-skeleton";

function NameValueText({
  name,
  nameStyle,
  valueStyle,
  value,
  containerStyle,
  isLoading,
  alignDirection,
}: NameValueTextInterface) {
  return (
    <div
      className={`flex  gap-4 text-[14px] ${
        alignDirection === "row" ? " items-center" : "flex-col"
      } ${containerStyle} `}
    >
      <span className={`font-bold text-black ${nameStyle}`}>
        {name}
        {alignDirection === "row" && ":"}
      </span>
      {isLoading ? (
        <Skeleton width={100} />
      ) : (
        <div
          className={`flex items-start text-[16px] text-gray-900 cursor-pointer ${valueStyle}`}
        >
          {value}
        </div>
      )}
    </div>
  );
}

export default NameValueText;
