import React, { useContext, useEffect, useMemo } from "react";

import { OptionsContext } from "context";
import {
  MyPasswordInput,
  MySelectField,
  MyTextField,
  MyUploadDocument,
} from "components";
import { decodeRoleOptionsHandler } from "helpers";
import { useParams } from "react-router-dom";
import { AdminInterface, ParamsStringEnum } from "interfaces";
import { useFormikContext } from "formik";

function UserForm() {
  const { getRoleOptionsHandler, roleOptions } = useContext(OptionsContext);
  const params = useParams<ParamsStringEnum>();
  const { values } = useFormikContext<AdminInterface<string>>();
  const editID = params?.id;
  useEffect(() => {
    getRoleOptionsHandler();
  }, []);

  return (
    <div className={"flex flex-col gap-10"}>
      {!values?.isSeed && (
        <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5`}>
          <MySelectField
            name={"privilege"}
            label={"Role"}
            isRequired={true}
            option={{
              defaultLabel: "Select Role",
              selectOptions: decodeRoleOptionsHandler(roleOptions),
            }}
          />
        </div>
      )}

      <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5`}>
        <MyTextField
          isRequired={true}
          label={"Full Name"}
          name="name"
          placeholder={"Eg. James Cordan"}
        />

        <MyTextField
          isRequired
          disabled={editID ? true : false}
          label={"Email ID"}
          name="email"
          placeholder={"Eg. ijames@gmail.com"}
        />

        <MyTextField
          isRequired
          label={"Mobile Number"}
          name="phone"
          placeholder={"Eg. +44 980000000"}
        />
        {!editID && (
          <MyPasswordInput
            isRequired
            label={"Password"}
            name="password"
            placeholder={"Enter Password"}
          />
        )}
      </div>
      <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5`}>
        <MyUploadDocument
          fileContainerStyle={"grid grid-cols-1"}
          name={"photo"}
          fileType={"image"}
          label={"Picture"}
        />
      </div>
    </div>
  );
}

export default UserForm;
