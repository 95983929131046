import { Component } from "react";
import { CrashedPage } from "pages";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  async errorApiHandler(errorMessage) {
    // await Api.post(apiUrl.app.post_clientLogging, { errorMessage });
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message

    let errorMessage = `Error: ${error} \n Error Info: ${new Error(errorInfo)}`;
    this.errorApiHandler(errorMessage);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    // @ts-ignore
    if (this.state.errorInfo) {
      let isDev =
        !process.env.NODE_ENV || process.env.NODE_ENV === "development";
      // Error path
      return (
        <CrashedPage>
          {isDev && (
            <div>
              <details style={{ whiteSpace: "pre-wrap" }}>
                {/* @ts-ignore */}
                {this.state.error && this.state.error.toString()}
                <br />
                {/* @ts-ignore */}
                {this.state.errorInfo.componentStack}
              </details>
            </div>
          )}
        </CrashedPage>
      );
    }
    //@ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
