import { useContext, useState } from "react";

import { AppContext } from "context";
import { SideNavComponent, TopNavComponent } from "./components";
import "./style.css";
import { getIconHelper } from "helpers";
import { IconTypeEnum } from "interfaces";

export default function Index({ children }: any) {
  const [isSideOpen, setOpen] = useState(true);
  const LeftIcon = getIconHelper(IconTypeEnum.LEFT);
  const RightIcon = getIconHelper(IconTypeEnum.RIGHT);
  const { state } = useContext(AppContext);

  const SideNav = <SideNavComponent />;

  return state.isAuthenticated && state?.hasTenantSpaceSelected ? (
    <div className="layoutIndexContainer overflow-y-scroll hideScroll">
      <TopNavComponent />
      <div
        style={{
          gridTemplateColumns: "auto 1fr",
        }}
        className="grid overflow-y-scroll hideScroll"
      >
        <div className={"flex overflow-y-scroll hideScroll relative"}>
          {isSideOpen && SideNav}
          <div
            className={`h-full w-1  ${isSideOpen ? "ml-4" : "ml-2"} pr-3 ${
              !isSideOpen ? "border-l-2 ml-7" : ""
            }`}
          >
            <div
              title={"Collapse Nav Bar"}
              onClick={() => setOpen((e) => !e)}
              className={
                "absolute top-8 shadow-sm border-2 right-[2px] p-1 text-[16px] cursor-pointer hover:bg-primary hover:text-white hover:border-primary bg-gray-100 text-gray-500 border-white rounded-full"
              }
            >
              {isSideOpen ? <LeftIcon /> : <RightIcon />}
            </div>
          </div>
        </div>
        <div className="grid overflow-y-scroll  hideScroll">{children}</div>
      </div>
    </div>
  ) : (
    children
  );
}
