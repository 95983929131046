import { MyIconButtonInterface } from "./interfaces";
import getIconHelpers from "../../helpers/utils/getIconHelpers";

export default function MyIconButton({
  IconType,
  badgeStyle,
  iconColor,
  onClick,
  iconSize,
  badge,
  containerStyle,
  title,
}: MyIconButtonInterface) {
  const CustomIcon = getIconHelpers(IconType);
  return (
    <button
      title={title}
      type={"button"}
      onClick={onClick || undefined}
      className={`rounded-full flex items-center justify-center relative bg-white cursor-pointer hover:bg-gray-200  ${
        containerStyle || "h-[44px] w-[44px]"
      }  `}
    >
      <CustomIcon
        className={`h-[18px] w-[18px]  ${iconColor}`}
        fontSize={iconSize || 24}
        color={iconColor}
      />
      {/*    badge*/}
      {badge && badge > 0 && (
        <div
          className={`absolute flex-center-HV top-[-5px] right-[-5px] rounded-full p-1 bg-tGreen text-white text-[12px] font-bold w-[25px] h-[22px] ${badgeStyle}`}
        >
          {badge > 9 ? "9+" : badge}
        </div>
      )}
    </button>
  );
}
