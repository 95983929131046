import React from "react";
import { Route } from "react-router-dom";
import { EditPackagePage, PackageDetailsPage, PackagePage } from "./pages";
import { AdminAccessListEnum, ParamsStringEnum } from "interfaces";
import { AccessVerifier } from "templates";
function Index() {
  return (
    <Route path="packages/">
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.SUBSCRIPTION_READ_SUBSCRIPTION]}
            isForPage={true}
          >
            <PackagePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`create`}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.SUBSCRIPTION_ADD_SUBSCRIPTION]}
            isForPage={true}
          >
            <EditPackagePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.SUBSCRIPTION_EDIT_SUBSCRIPTION]}
            isForPage={true}
          >
            <EditPackagePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`details/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.SUBSCRIPTION_READ_SUBSCRIPTION]}
            isForPage={true}
          >
            <PackageDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
