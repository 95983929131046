import axios from "axios";
import { apiConfig } from "configs";
import { AuthTokenENUM, baseUrlType } from "interfaces";

import { ApiUrl } from "../services";
import { clearAuthToken, getAuthToken, setAuthToken } from "./authUtils";
import { useNavigate } from "react-router-dom";

let isRefreshing = false;
let refreshQueue = [];

const apiAxiosInstance = (
  baseUrl: baseUrlType = baseUrlType.DEFAULT,
  tokenType: AuthTokenENUM = AuthTokenENUM.accessToken,
  token?: string
) => {
  let axiosInstance = axios.create({
    baseURL: apiConfig[baseUrl],
    headers: {
      authorization: token || getAuthToken(tokenType),
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.response.use(
    function (response) {
      if (response.status === 200) {
        return response.data;
      }
      return response;
    },
    async function errorHandler(error) {
      if (
        error?.response?.status === 401 &&
        getAuthToken(AuthTokenENUM.refreshToken)
      ) {
        throw error;
      } else if (error?.response?.status === 404) {
        throw new Error("Routes not found");
      } else {
        throw error?.response?.data ? error?.response?.data : error;
      }
    }
  );
  return axiosInstance;
};

function Api() {
  const navigate = useNavigate();
  async function handleTokenRefresh() {
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        const res = await apiAxiosInstance(
          baseUrlType.DEFAULT,
          AuthTokenENUM.refreshToken
        ).get(ApiUrl.auth.get_newAccessToken);
        const newAccessToken = res?.data?.accessToken;
        const newRefreshToken = res?.data?.refreshToken;
        setAuthToken(newAccessToken, AuthTokenENUM.accessToken);
        setAuthToken(newRefreshToken, AuthTokenENUM.refreshToken);

        apiAxiosInstance().defaults.headers["Authorization"] = getAuthToken(
          AuthTokenENUM.accessToken
        );
        refreshQueue.forEach((retryPromise) => {
          retryPromise?.originalRequest();
        });
      } catch (refreshError) {
        clearAuthToken(AuthTokenENUM.accessToken);
        clearAuthToken(AuthTokenENUM.refreshToken);
        navigate("/auth/login");
      } finally {
        isRefreshing = false;
        refreshQueue = [];
      }
    }
  }

  async function getApi(
    url: string,
    body?: any,
    tokenType: AuthTokenENUM = AuthTokenENUM.accessToken,
    baseUrl: baseUrlType = baseUrlType.DEFAULT
  ): Promise<any> {
    const originalAxios = () =>
      apiAxiosInstance(baseUrl, tokenType).get(url, {
        params: body,
      });
    return new Promise(async (resolve, reject) => {
      try {
        const res = await originalAxios();
        resolve(res);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          const originalRequest = async () => {
            try {
              const res = await originalAxios();
              resolve(res);
            } catch (retryError) {
              reject(retryError);
            }
          };
          refreshQueue.push({ originalRequest, resolve, reject });
          if (!isRefreshing) {
            await handleTokenRefresh();
          }
        } else {
          reject(err);
        }
      }
    });
  }

  async function getBlobResApi(
    url: string,
    body?: any,
    tokenType: AuthTokenENUM = AuthTokenENUM.accessToken,
    baseUrl: baseUrlType = baseUrlType.DEFAULT
  ): Promise<any> {
    const originalAxios = () =>
      apiAxiosInstance(baseUrl, tokenType).get(url, {
        params: body,
        responseType: "blob",
      });
    return new Promise(async (resolve, reject) => {
      try {
        let res: any = await originalAxios();
        resolve(res);
      } catch (err: any) {
        if (err.response && err.response.status === 401) {
          const originalRequest = async () => {
            try {
              let res: any = await originalAxios();
              resolve(res);
            } catch (retryError) {
              reject(retryError);
            }
          };

          // Add the request to the refresh queue
          refreshQueue.push({ originalRequest, resolve, reject });

          // Trigger token refresh if it's not already in progress
          if (!isRefreshing) {
            await handleTokenRefresh();
          }
        } else {
          reject(err);
        }
      }
    });
  }

  async function postApi(
    url: string,
    body: any,
    tokenType: AuthTokenENUM = AuthTokenENUM.accessToken,
    baseUrl: baseUrlType = baseUrlType.DEFAULT,
    token?: string
  ): Promise<any> {
    const originalAxios = () =>
      apiAxiosInstance(baseUrl, tokenType, token).post(url, body, {
        data: body,
      });
    return new Promise(async (resolve, reject) => {
      try {
        let res = await originalAxios();
        resolve(res);
      } catch (err: any) {
        if (err.response && err.response.status === 401) {
          const originalRequest = async () => {
            try {
              let res = await originalAxios();
              resolve(res);
            } catch (retryError) {
              reject(retryError);
            }
          };
          refreshQueue.push({ originalRequest, resolve, reject });
          if (!isRefreshing) {
            await handleTokenRefresh();
          }
        } else {
          reject(err); // Reject the original request with the error
        }
      }
    });
  }

  async function putApi(
    url: string,
    body?: any,
    tokenType: AuthTokenENUM = AuthTokenENUM.accessToken,
    baseUrl: baseUrlType = baseUrlType.DEFAULT,
    token?: string
  ): Promise<any> {
    const originalAxios = () =>
      apiAxiosInstance(baseUrl, tokenType, token).put(url, body);
    return new Promise(async (resolve, reject) => {
      try {
        let res = await originalAxios();
        resolve(res);
      } catch (err: any) {
        if (err.response && err.response.status === 401) {
          const originalRequest = async () => {
            try {
              let res = await originalAxios();
              resolve(res);
            } catch (retryError) {
              reject(retryError);
            }
          };

          refreshQueue.push({ originalRequest, resolve, reject });

          if (!isRefreshing) {
            await handleTokenRefresh();
          }
        } else {
          reject(err);
        }
      }
    });
  }

  async function deleteApi(
    url: string,
    body?: any,
    tokenType: AuthTokenENUM = AuthTokenENUM.accessToken,
    baseUrl: baseUrlType = baseUrlType.DEFAULT,
    token?: string
  ): Promise<any> {
    const originalAxios = () =>
      apiAxiosInstance(baseUrl, tokenType, token).delete(url, {
        data: body,
      });
    return new Promise(async (resolve, reject) => {
      try {
        let res: any = await originalAxios();
        resolve(res);
      } catch (err: any) {
        if (err.response && err.response.status === 401) {
          const originalRequest = async () => {
            try {
              let res: any = await originalAxios();
              resolve(res);
            } catch (retryError) {
              reject(retryError);
            }
          };
          refreshQueue.push({ originalRequest, resolve, reject });

          if (!isRefreshing) {
            await handleTokenRefresh();
          }
        } else {
          reject(err);
        }
      }
    });
  }

  async function postFormApi(
    url: string,
    body?: any,
    tokenType: AuthTokenENUM = AuthTokenENUM.accessToken,
    baseUrl: baseUrlType = baseUrlType.DEFAULT,
    token?: string
  ): Promise<any> {
    const originalAxios = () =>
      apiAxiosInstance(baseUrl, tokenType, token).post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    return new Promise(async (resolve, reject) => {
      try {
        let res = await originalAxios();
        resolve(res);
      } catch (err: any) {
        if (err.response && err.response.status === 401) {
          const originalRequest = async () => {
            try {
              let res = await originalAxios();
              resolve(res);
            } catch (retryError) {
              reject(retryError);
            }
          };

          // Add the request to the refresh queue
          refreshQueue.push({ originalRequest, resolve, reject });

          // Trigger token refresh if it's not already in progress
          if (!isRefreshing) {
            await handleTokenRefresh();
          }
        } else {
          reject(err); // Reject the original request with the error
        }
      }
    });
  }
  async function getSearchApi(
    url: string,
    config?: {
      params?: any;
      signal?: any;
    },
    tokenType: AuthTokenENUM = AuthTokenENUM.accessToken,
    baseUrl: baseUrlType = baseUrlType.DEFAULT,
    token?: string
  ): Promise<any> {
    const originalAxios = () =>
      apiAxiosInstance(baseUrl, tokenType, token).get(url, {
        params: config?.params,
        signal: config?.signal,
      });
    return new Promise(async (resolve, reject) => {
      try {
        let res: any = await originalAxios();
        resolve(res);
      } catch (err: any) {
        if (err.response && err.response.status === 401) {
          const originalRequest = async () => {
            try {
              let res: any = await originalAxios();
              resolve(res);
            } catch (retryError) {
              reject(retryError);
            }
          };

          // Add the request to the refresh queue
          refreshQueue.push({ originalRequest, resolve, reject });

          // Trigger token refresh if it's not already in progress
          if (!isRefreshing) {
            await handleTokenRefresh();
          }
        } else {
          reject(err); // Reject the original request with the error
        }
      }
    });
  }
  return {
    getApi,
    postApi,
    putApi,
    deleteApi,
    postFormApi,
    getSearchApi,
    getBlobResApi,
  };
}

export default Api;
