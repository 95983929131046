import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { PageTemplate } from "templates";
import { PrivilegeContext } from "context";
import dot from "dot-object";

import { PageLinks } from "routes";
import { ParamsStringEnum } from "interfaces";
import { FormWrapper, NameValueText } from "assets";
import { MyCheckButton } from "components";
import { AdminPermissionsData } from "helpers";

function RoleDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const id = params?.id;
  const { getDetailsHandler, details } = useContext(PrivilegeContext);
  useEffect(() => {
    if (id) {
      getDetailsHandler(id);
    }
  }, [id]);
  return (
    <PageTemplate
      title={"Role"}
      backNavigation={{
        title: "Roles Details",
        path: PageLinks.role.list,
      }}
    >
      <div className={"flex flex-col gap-10"}>
        <FormWrapper header={{ title: { name: "" } }}>
          <div className={`grid grid-cols-3 sm:grid-cols-1 gap-5`}>
            <NameValueText name={"Name"} value={details?.name || "-"} />
          </div>
        </FormWrapper>
        <FormWrapper header={{ title: { name: "Assign Permissions" } }}>
          <table className="table_container ">
            <thead className="table_head whitespace-nowrap ">
              <tr>
                <th scope="col" className="pl-2 py-3">
                  Modules
                </th>
                <th scope="col">Functions</th>
              </tr>
            </thead>
            <tbody className={"text-center "}>
              {AdminPermissionsData?.map((e, key) => {
                let isLast = AdminPermissionsData?.length - 1 === key;
                return (
                  <tr
                    key={key}
                    className={`text-left ${!isLast && "border-b-2"}`}
                  >
                    <td scope="col" className="pl-2 py-3">
                      <div className={"flex items-center gap-2"}>
                        {e?.label}
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          "grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5 py-5 "
                        }
                      >
                        {e?.access?.map((f, key) => {
                          let isChecked = dot.pick(f?.name, details?.granted);
                          return (
                            <MyCheckButton
                              key={key}
                              label={f?.label}
                              isChecked={isChecked}
                            />
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </FormWrapper>
      </div>
    </PageTemplate>
  );
}

export default RoleDetailsPage;
