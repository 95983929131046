import actionTypes from "./config";

export const setLoadingAction = (flag: boolean) => {
  return {
    type: actionTypes.APP_SET_LOADING,
    payload: flag,
  };
};

export const setErrorAction = (flag: boolean) => {
  return {
    type: actionTypes.APP_SET_ERROR,
    payload: flag,
  };
};

export const setPageErrorAction = (status: boolean, message?: string) => {
  return {
    type: actionTypes.APP_SET_PAGE_ERROR,
    payload: { status, message },
  };
};

export const setSuccessAction = (flag: boolean) => {
  return {
    type: actionTypes.APP_SET_SUCCESS_ACTION,
    payload: flag,
  };
};

export const setActionLoadingAction = (flag: boolean) => {
  return {
    type: actionTypes.APP_SET_ACTION_LOADING,
    payload: flag,
  };
};

export const setNotificationAction = (flag: boolean) => {
  return {
    type: actionTypes.APP_SET_NOTIFICATION,
    payload: flag,
  };
};

export const setAuthLoadingAction = (flag: boolean) => {
  return {
    type: actionTypes.APP_SET_AUTH_LOADING,
    payload: flag,
  };
};

export const setMessageAction = (message: string) => {
  return {
    type: actionTypes.APP_SET_MESSAGE,
    payload: message,
  };
};

export const setAuthenticateAction = (payload: any) => {
  return {
    type: actionTypes.APP_SET_AUTHENTICATED,
    payload,
  };
};

export const logoutAction = (payload: any) => {
  return {
    type: actionTypes.LOGOUT,
    payload,
  };
};

export const setProfileAction = (payload: any) => {
  return {
    type: actionTypes.APP_SET_PROFILE,
    payload,
  };
};
export const setSystemSettingsAction = (payload: any) => {
  return {
    type: actionTypes.APP_SET_SYSTEM_SETTINGS,
    payload,
  };
};
