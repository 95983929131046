import { SettingDetail } from "../assets";
import "./style.css";
import { useNavigate } from "react-router-dom";

export default function TopNav() {
  const navigate = useNavigate();
  return (
    <div className="topNav_container ">
      <div className="flex gap-8 items-center">
        <img
          onClick={() => navigate("/")}
          className="h-[65px] w-[130px] cursor-pointer  select-none"
          src="/img/logo/logo.svg"
          alt="logo"
        />
        <div className={"flex items-center gap-3"}></div>
      </div>

      {/*  icons*/}
      <div className={"flex items-center gap-3"}>
        <SettingDetail />
      </div>
    </div>
  );
}
