import { apiConfig } from "configs";
import { ApiUrl } from "services";
import queryString from "query-string";
import moment from "moment";
import {
  AdminAccessRoleInterface,
  AdminAccessListEnum,
  DateRangeOptionEnum,
} from "../interfaces";

export const downloadBlobFileHandler = (
  fileBlob,
  fileName,
  fileFormat?: "pdf"
) => {
  let url = window.URL.createObjectURL(new Blob([fileBlob]));

  if (fileFormat === "pdf") {
    url = window.URL.createObjectURL(
      new Blob([fileBlob], {
        type: "application/pdf",
      })
    );
  }
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();
  URL.revokeObjectURL(url);
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};
export function getImageFileDetails(filename: string) {
  const imageExtensions = [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".bmp",
    ".tiff",
    ".webp",
  ];
  if (!filename) {
    return { isImage: false, extension: "", name: "" };
  }
  const fileExtension = filename.split(".").pop().toLowerCase();
  let decode = filename?.split("/");
  let name = decode[decode?.length - 1];

  return {
    isImage: imageExtensions?.includes("." + fileExtension),
    extension: fileExtension,
    name,
  };
}

export const downloadLink = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.setAttribute("download", fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const getInputDateFormat = (
  date: string | Date,
  type: "date" | "datetime-local" = "date"
) => {
  if (date) {
    if (type == "date") {
      return new Date(date)?.toISOString()?.split("T")[0];
    } else {
      return new Date(date)?.toISOString()?.split("Z")[0];
    }
  }
};

export const getCurrency = () => {
  return process.env.REACT_APP_CURRENCY || "£";
};

export const getImageUrlHandler = (imageURl?: string) => {
  if (!imageURl) return null;
  return apiConfig.default + ApiUrl.utils.get_Image + imageURl;
};

export function separateWithComma(number: number | string) {
  if (!number) return number;
  return number.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
  });
}

export const getStaffFullName = (payload: { first: string; last: string }) => {
  return `${payload?.first || ""} ${payload?.last || ""}`;
};
export function waitForMe(milisec) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("");
    }, milisec);
  });
}

// Last Month
export const lastMonthStart = moment(new Date())
  .subtract(1, "month")
  .startOf("month")
  .format("YYYY-MM-DD");
export const lastMonthEnd = moment(new Date())
  .subtract(1, "month")
  .endOf("month")
  .format("YYYY-MM-DD");

// This Month
export const thisMonthStart = moment(new Date())
  .startOf("month")
  .format("YYYY-MM-DD");
export const thisMonthEnd = moment(new Date())
  .endOf("month")
  .format("YYYY-MM-DD");

// Last Year
export const lastYearStart = moment(new Date())
  .subtract(1, "year")
  .startOf("year")
  .format("YYYY-MM-DD");

export const lastYearEnd = moment(new Date())
  .subtract(1, "year")
  .endOf("year")
  .format("YYYY-MM-DD");

// This Year
export const thisYearStart = moment(new Date())
  .startOf("year")
  .format("YYYY-MM-DD");

export const thisYearEnd = moment(new Date())
  .endOf("year")
  .format("YYYY-MM-DD");

export const DateRangeOptions = [
  {
    label: "This Year",
    value: DateRangeOptionEnum.THIS_YEAR,
  },
  {
    label: "This Month",
    value: DateRangeOptionEnum.THIS_MONTH,
  },
  {
    label: "Last Year",
    value: DateRangeOptionEnum.LAST_YEAR,
  },
  {
    label: "Last Month",
    value: DateRangeOptionEnum.LAST_MONTH,
  },
  {
    label: "All Time",
    value: DateRangeOptionEnum.ALL_TIME,
  },
  {
    label: "Custom",
    value: DateRangeOptionEnum.CUSTOM,
  },
];

export const AdminPermissionsData: AdminAccessRoleInterface[] = [
  {
    label: "Authorization",
    name: "AUTH",
    access: [
      { label: "Add Role", name: AdminAccessListEnum.AUTH_ADD_ROLE },
      { label: "Edit Role", name: AdminAccessListEnum.AUTH_EDIT_ROLE },
      { label: "Delete Role", name: AdminAccessListEnum.AUTH_DEL_ROLE },
      { label: "Read Role", name: AdminAccessListEnum.AUTH_READ_ROLE },
      { label: "Add User", name: AdminAccessListEnum.AUTH_ADD_USER },
      { label: "Edit User", name: AdminAccessListEnum.AUTH_EDIT_USER },
      { label: "Delete User", name: AdminAccessListEnum.AUTH_DEL_USER },
      { label: "Read User", name: AdminAccessListEnum.AUTH_READ_USER },
    ],
  },
  {
    label: "Subscription",
    name: "SUBSCRIPTION",
    access: [
      {
        label: "Add Subscription",
        name: AdminAccessListEnum.SUBSCRIPTION_ADD_SUBSCRIPTION,
      },
      {
        label: "Edit Subscription",
        name: AdminAccessListEnum.SUBSCRIPTION_EDIT_SUBSCRIPTION,
      },
      {
        label: "Delete Subscription",
        name: AdminAccessListEnum.SUBSCRIPTION_DEL_SUBSCRIPTION,
      },
      {
        label: "Read Subscription",
        name: AdminAccessListEnum.SUBSCRIPTION_READ_SUBSCRIPTION,
      },
    ],
  },
  {
    label: "Customer",
    name: "CUSTOMER",
    access: [
      {
        label: "Delete Customer",
        name: AdminAccessListEnum.CUSTOMER_DEL_CUSTOMER,
      },
      {
        label: "Read Customer",
        name: AdminAccessListEnum.CUSTOMER_READ_CUSTOMER,
      },
      {
        label: "Read Purchase History",
        name: AdminAccessListEnum.CUSTOMER_READ_PURCHASE_HISTORY,
      },
    ],
  },
  {
    label: "Other",
    name: "MISC",
    access: [
      {
        label: "Access Dashboard",
        name: AdminAccessListEnum.MISC_ACCESS_DASHBOARD,
      },
    ],
  },
  {
    label: "Configuration",
    name: "CONFIGURATION",
    access: [
      {
        label: "Manage Settings",
        name: AdminAccessListEnum.CONFIGURATION_MANAGE_SETTINGS,
      },
    ],
  },
];

export function capitalizeFirstLetter(name: string) {
  if (!name) {
    return name;
  }
  return name.charAt(0).toUpperCase() + name.slice(1);
}
