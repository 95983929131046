import React from "react";

import { FormWrapper } from "assets";
import { MyTextField, MyRadioInput, MyCheckButton } from "components";
import { AdminPermissionsData } from "helpers";
import { useFormikContext } from "formik";
import { AdminPrivilegeInterface } from "interfaces";
import dot from "dot-object";

function RolesForm() {
  const { values, setFieldValue } = useFormikContext<AdminPrivilegeInterface>();
  const checkAllHandler = (value, isCheckedAll: boolean) => {
    value?.access?.forEach((e) => {
      setFieldValue("granted." + e?.name, isCheckedAll ? false : true);
    });
  };
  return (
    <div className={"flex flex-col gap-10"}>
      <FormWrapper header={{ title: { name: "" } }}>
        <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5`}>
          <MyTextField
            isRequired
            label={"Name"}
            name="name"
            placeholder={"Eg. Admin"}
          />
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Assign Permissions" } }}>
        <table className="table_container ">
          <thead className="table_head whitespace-nowrap ">
            <tr>
              <th scope="col" className="pl-2 py-3">
                Modules
              </th>
              <th scope="col">Functions</th>
            </tr>
          </thead>
          <tbody className={"text-center "}>
            {AdminPermissionsData?.map((e, key) => {
              let isLast = AdminPermissionsData?.length - 1 === key;
              let isAllChecked = true;
              let isAtLeastOneChecked = false;
              e?.access?.forEach((f) => {
                if (dot.pick(f?.name, values?.granted)) {
                  isAtLeastOneChecked = true;
                }
                if (!dot.pick(f?.name, values?.granted)) {
                  isAllChecked = false;
                  return;
                }
              });
              return (
                <tr
                  key={key}
                  className={`text-left ${!isLast && "border-b-2"}`}
                >
                  <td scope="col" className="pl-2 py-3">
                    <MyCheckButton
                      changeHandler={() => {
                        checkAllHandler(e, isAllChecked);
                      }}
                      label={e?.label}
                      isChecked={isAtLeastOneChecked}
                    />
                  </td>
                  <td>
                    <div
                      className={
                        "grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5 py-5 "
                      }
                    >
                      {e?.access?.map((f, key) => {
                        return (
                          <MyRadioInput
                            key={key}
                            name={`granted.${f?.name}`}
                            label={f?.label}
                          />
                        );
                      })}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </FormWrapper>
    </div>
  );
}

export default RolesForm;
