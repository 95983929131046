import React, { useContext, useEffect } from "react";

import { PageTemplate } from "templates";
import { useParams } from "react-router-dom";

import { FormWrapper, NameValueText } from "assets";
import { FileViewCard } from "components";
import { UserContext } from "context";
import { ParamsStringEnum } from "interfaces";
import { capitalizeFirstLetter } from "helpers";

function UserDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const { details, isDetailsLoading, getDetailsHandler } =
    useContext(UserContext);
  const uId = params?.id;
  useEffect(() => {
    if (uId) {
      getDetailsHandler(uId);
    }
  }, [uId]);

  return (
    <PageTemplate
      title={"Users"}
      backNavigation={{
        title: "User Details",
      }}
    >
      <div className={"flex flex-col gap-10"}>
        <FormWrapper header={{ title: { name: "General" } }}>
          <div className={"flex flex-col gap-5"}>
            <div
              className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5`}
            >
              <NameValueText
                isLoading={isDetailsLoading}
                name={"Full Name"}
                value={details?.name}
              />
              <NameValueText
                isLoading={isDetailsLoading}
                name={"Email ID"}
                value={details?.email}
              />
              <NameValueText
                isLoading={isDetailsLoading}
                name={"Mobile Number"}
                value={details?.phone || "-"}
              />
            </div>
            {details?.photo && (
              <div className={"grid grid-cols-3 gap-5"}>
                <FileViewCard canDownload filePath={details?.photo} />
              </div>
            )}
          </div>
        </FormWrapper>
        <FormWrapper header={{ title: { name: "Roles & Permissions" } }}>
          <>
            <div
              className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5`}
            >
              <NameValueText
                isLoading={isDetailsLoading}
                name={"Role"}
                value={capitalizeFirstLetter(
                  details?.role?.replaceAll("_", " ")
                )}
              />
            </div>
          </>
        </FormWrapper>
      </div>
    </PageTemplate>
  );
}

export default UserDetailsPage;
