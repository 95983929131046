import { MyCheckButtonInterface, MyToggleButtonInterface } from "./interfaces";
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";

export default function MyCheckButton({
  isChecked,
  label,
  changeHandler,
  value,
}: MyCheckButtonInterface) {
  const onClickHandler = () => {
    changeHandler(value);
  };

  return (
    <div
      className={"flex items-center gap-2 cursor-pointer select-none"}
      onClick={onClickHandler}
    >
      <div className={""}>
        {isChecked ? (
          <AiFillCheckCircle className={" text-tGreen  text-[20px]"} />
        ) : (
          <AiOutlineCheckCircle className={"text-gray-300  text-[20px]"} />
        )}
      </div>

      <span className={"text-[14px]"}>{label}</span>
    </div>
  );
}
