import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { QueryStringEnum } from "../interfaces";

function UsePageQueryHooks() {
  const [query] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const returnURL = query.get(QueryStringEnum.RETURN_URL);
  const startDate = query.get(QueryStringEnum.START_DATE);
  const endDate = query.get(QueryStringEnum.END_DATE);
  let search = query.get(QueryStringEnum.Search);
  if (search) {
    search = decodeURIComponent(search);
  }
  const navigateWithReturnUrlHandler = (
    pathName: string,
    queryStrings?: string
  ) => {
    //this function will have return url
    navigate({
      pathname: pathName,
      search: `${QueryStringEnum.RETURN_URL}=${location.pathname}${
        queryStrings ? `&${queryStrings}` : ""
      }`,
    });
  };

  return {
    returnURL,
    startDate,
    endDate,
    search,
    navigateWithReturnUrlHandler,
  };
}

export default UsePageQueryHooks;
