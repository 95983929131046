import * as yup from "yup";
export const LoginFormik = {
  initialValues: {
    emailOrPhone: "",
    password: "",
  },
  validationSchema: yup.object().shape({
    emailOrPhone: yup.string().email("invalid email").required("required"),
    password: yup.string().required("required"),
  }),
};
export const RequestForgotPasswordFormik = {
  initialValues: {
    emailOrPhone: "",
  },
  validationSchema: yup.object().shape({
    emailOrPhone: yup.string().email("invalid").required("required"),
  }),
};
export const ValidateOTPPasswordFormik = {
  initialValues: {
    otp: "",
  },
  validationSchema: (length: number) =>
    yup.object().shape({
      otp: yup
        .string()
        .matches(/^\d{6}$/, "invalid")
        .length(length, "invalid")
        .required("required"),
    }),
};
export const ResetPasswordFormik = {
  initialValues: {
    password: "",
  },
  validationSchema: yup.object().shape({
    password: yup
      .string()
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      )
      .required("required"),
  }),
};
