import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { PageLinks } from "routes";
import { PackageContext } from "context";
import { MyMoreOptionButton } from "components";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";
import {
  AdminAccessListEnum,
  ColorTypeOptionEnum,
  SubscriptionPackageInterface,
  TableColumnInterface,
} from "interfaces";
import { StatusText } from "assets";

function PackageTableColumns(
  pageQuery
): TableColumnInterface<SubscriptionPackageInterface>[] {
  const navigate = useNavigate();
  const {
    getAllDataHandlers,
    archiveHandler,
    deleteHandler,
    editDetailsHandler,
  } = useContext(PackageContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canDelete = checkCanAccessHandler([
    AdminAccessListEnum.SUBSCRIPTION_DEL_SUBSCRIPTION,
  ]);
  const canEdit = checkCanAccessHandler([
    AdminAccessListEnum.SUBSCRIPTION_EDIT_SUBSCRIPTION,
  ]);
  return [
    {
      title: "SN",
      render(renderData, key) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Package Name",
      render(renderData) {
        return <span>{renderData?.name}</span>;
      },
    },
    {
      title: "Trial",
      render(renderData) {
        return (
          <div>
            {renderData?.trial?.enable && renderData?.trial?.days > 0 ? (
              <div>
                <span>{renderData?.trial?.days} days</span>
              </div>
            ) : (
              <span>No Trial</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Type",
      render(renderData) {
        return (
          <StatusText
            status={
              !renderData?.isFreeForever
                ? ColorTypeOptionEnum.GREEN
                : ColorTypeOptionEnum.RED
            }
            label={renderData?.isFreeForever ? "Free" : "Paid"}
          />
        );
      },
    },
    {
      title: "Status",
      render(renderData) {
        return (
          <StatusText
            onClick={() => {
              editDetailsHandler(
                {
                  ...renderData,
                  purchaseDisable: !renderData?.purchaseDisable,
                },
                null,
                {
                  onSuccess(payload?: any): any {
                    getAllDataHandlers(pageQuery);
                  },
                }
              );
            }}
            status={
              renderData?.purchaseDisable
                ? ColorTypeOptionEnum.GRAY
                : ColorTypeOptionEnum.GREEN
            }
            label={
              renderData?.purchaseDisable ? "Purchase Disable" : "Purchasable"
            }
          />
        );
      },
    },
    {
      title: "Maximum Properties Count",
      render(renderData) {
        return (
          <div>
            {renderData?.isMaxPropertyUnlimited
              ? "Unlimited"
              : `${
                  renderData?.maxProperty ||
                  renderData?.propertyIncludedInBasePrice
                } ${
                  renderData?.maxProperty > 1 ||
                  renderData?.propertyIncludedInBasePrice > 1
                    ? "properties"
                    : "property"
                } `}
          </div>
        );
      },
    },

    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View ",
                  handler: () => {
                    navigate(PageLinks.package.details(renderData?._id));
                  },
                },
                !renderData?.isArchived &&
                  canEdit && {
                    name: "Edit ",
                    handler: () => {
                      navigate(PageLinks.package.edit(renderData?._id));
                    },
                  },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(pageQuery);
                      },
                    });
                  },
                },

                canDelete && {
                  name: "Delete ",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(pageQuery);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default PackageTableColumns;
