import React, { createContext, useContext, useState } from "react";

import { AppContext } from "context/index";
import { Api } from "helpers";
import { ApiUrl } from "services";
import {
  HandlerCallbackInterface,
  DataContextInterface,
  PurchasePackageInterface,
  SystemTenantUserInterface,
} from "interfaces";
import {
  useConfirmHook,
  useDataContextHooks,
  usePaginationQueryHooks,
} from "useHooks";

export const PurchaseHistoryContext = createContext<
  DataContextInterface<PurchasePackageInterface>
>({
  isDetailsLoading: false,
  isLoading: false,
  totalDocs: 0,
  totalArchiveDocs: 0,
  allData: [],
  details: undefined,

  getAllDataHandlers(query?: any) {},
  getDetailsHandler(itemId: string, isFromEdit?: boolean) {},
  archiveHandler(
    itemId: string,
    status: boolean,
    callback?: HandlerCallbackInterface
  ) {},
});

function DataContextProvider({ children }) {
  const { handlers } = useContext(AppContext);
  const { confirm } = useConfirmHook();
  const { isArchive, paginationQuery } = usePaginationQueryHooks();
  const {
    totalArchiveDocs,
    setTotalArchiveDocs,
    isDetailsLoading,
    setDetailsLoading,
    details,
    setLoading,
    isLoading,
    setTotalDocs,
    totalDocs,
    setDetails,
    allData,
    setAllData,
  } = useDataContextHooks();

  const { getApi, putApi } = Api();
  //  handler
  const contextHandlers = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);

        let res: any;
        res = await getApi(ApiUrl.purchaseHistory.get_purchaseHistory, {
          ...query,
          ...paginationQuery,
        });
        if (isArchive) {
          setTotalArchiveDocs(res?.data?.totalDocs);
        } else {
          setTotalDocs(res?.data?.totalDocs);
        }
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getDetailsHandler: async (itemID: string, isFromEdit) => {
      try {
        if (!isFromEdit) {
          setDetailsLoading(true);
          setDetails(null);
        }
        const res: any = await getApi(
          ApiUrl.customers.get_customerDetails(itemID)
        );
        setDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    archiveHandler: async (
      itemsId,
      status: boolean,
      callback: HandlerCallbackInterface
    ) => {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await putApi(
          ApiUrl.customers.put_archiveCustomer +
            `${itemsId}/${status ? "off" : "on"}`,
          {}
        );
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const contextValue = {
    totalDocs,
    totalArchiveDocs,
    allData,
    details,
    isDetailsLoading,
    isLoading,
    ...contextHandlers,
  };
  return (
    <PurchaseHistoryContext.Provider value={contextValue}>
      {children}
    </PurchaseHistoryContext.Provider>
  );
}

export default DataContextProvider;
