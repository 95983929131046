import React from "react";
import { ColorTypeOptionEnum } from "../../interfaces";

interface PropsInterface {
  status: ColorTypeOptionEnum;
  label: string;
  onClick?();
}
function StatusText({ status, label, onClick }: PropsInterface) {
  let textColor, bgColor;
  switch (status) {
    case ColorTypeOptionEnum.YELLOW:
      textColor = "text-yellow-300";
      bgColor = "bg-yellow-100";
      break;

    case ColorTypeOptionEnum.RED:
      textColor = "text-red-500";
      bgColor = "bg-red-100";
      break;
    case ColorTypeOptionEnum.RED:
      textColor = "text-red-500";
      bgColor = "bg-red-100";
      break;
    case ColorTypeOptionEnum.GREEN:
      textColor = "text-green-500";
      bgColor = "bg-green-100";
      break;
    case ColorTypeOptionEnum.GRAY:
      textColor = "text-gray-500";
      bgColor = "bg-gray-100";
      break;

    default:
      textColor = "text-gray-500";
      bgColor = "bg-gray-100";
  }

  return (
    <div onClick={onClick} className={"flex items-start"}>
      <div
        className={`flex items-center justify-center gap-2 px-3 py-1 rounded-full  ${textColor} ${bgColor}`}
      >
        <span className={"text-[14px] "}>{label}</span>
      </div>
    </div>
  );
}

export default StatusText;
