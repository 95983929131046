import React, { useState } from "react";

import { MySearchInput } from "components";
import { IconTypeEnum, QueryStringEnum } from "interfaces";

import { TableCardInterface } from "./interfaces";
import TableComponent from "./TableComponent";
import PaginationComponent from "../cards/PaginationComponent";
import { MyButton } from "../buttons";

export default function TableCardComponent({
  title,
  loading,
  column,
  dataSource,
  rowSelection,
  searchInput,
  children,
  havePagination,
  exportXlsx,
}: TableCardInterface) {
  let selectRow = rowSelection?.selectRow;
  let key = rowSelection?.key;
  const [selectedRows, setSelectedRows] = useState([]);
  const onSelectChange = (newSelectedRow: any[]) => {
    setSelectedRows(newSelectedRow);
  };
  const haveTopBorder =
    children?.leftChildren || children?.rightChildren || title || searchInput;
  return (
    <div className={"flex flex-col gap-5 overflow-y-scroll hideScroll"}>
      {/*  table header*/}
      <div
        className={`flex items-center justify-between gap-5 ${
          haveTopBorder && "border-b-[1px] mb-5 pb-10"
        } `}
      >
        <div className={"flex items-center gap-5"}>
          {title && (
            <span className={" font-bold text-[22px] whitespace-nowrap"}>
              {title}
            </span>
          )}
          <div className={"flex items-center gap-5 p-1"}>
            {searchInput && (
              <MySearchInput
                paramsName={QueryStringEnum.Search}
                placeholder={searchInput?.placeholder || "Search by keyword"}
              />
            )}
            {children?.leftChildren}
          </div>
        </div>
        {children?.centerChildren}
        <div className={"flex items-center gap-5"}>
          {children?.rightChildren}
          {typeof exportXlsx?.generateHandler === "function" && (
            <div className={`${exportXlsx?.havePaddingTop && "mt-8"}`}>
              <MyButton
                iconType={IconTypeEnum.DOWNLOAD}
                isOutline
                onClick={exportXlsx?.generateHandler}
                name={"Export xlsx"}
              />
            </div>
          )}
        </div>
      </div>
      {children?.aboveTableChildren && (
        <div className={"mt-2 pb-5"}>{children?.aboveTableChildren}</div>
      )}
      {/*  table*/}
      <div className={"overflow-x-scroll"}>
        <TableComponent
          rowSelections={{
            selectRow,
            selectedRows,
            onSelectChange,
            key,
          }}
          column={column}
          dataSource={dataSource}
          loading={loading}
        />
      </div>

      {havePagination && (
        <PaginationComponent totalDocs={havePagination?.totalDocs} />
      )}
    </div>
  );
}
