import React from "react";
import { Route } from "react-router-dom";
import { ProfilePage, EditProfilePage, EditPasswordPage } from "./pages";
import ProfileModuleContextProvider from "./context";
function Index() {
  return (
    <Route path="profile/" element={<ProfileModuleContextProvider />}>
      <Route path={``} element={<ProfilePage />} />
      <Route path={`edit`} element={<EditProfilePage />} />
      <Route path={`changePassword`} element={<EditPasswordPage />} />
    </Route>
  );
}

export default Index;
