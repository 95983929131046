import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AppContext } from "context";

export default function PublicRouteComponent() {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (state?.isAuthenticated) {
      navigate("/");
    }
  }, [state?.isAuthenticated]);

  return <Outlet />;
}
