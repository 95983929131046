import React, { useContext, useEffect } from "react";

import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { PageTemplate } from "templates";
import { FormConcludeButtons } from "assets";
import { ParamsStringEnum, SubscriptionPackageInterface } from "interfaces";
import { AppContext, PackageContext } from "context";
import { PageLinks } from "routes";
import { usePageQueryHooks } from "useHooks";

import { PackageFormik } from "../helpers";
import { PackageForm } from "../components";

function NewPropertyPage() {
  const params = useParams<ParamsStringEnum>();
  const isEdit = params?.id;
  const { returnURL } = usePageQueryHooks();
  const { getDetailsHandler, details, editDetailsHandler } =
    useContext(PackageContext);
  const {
    handlers: { setLoading },
  } = useContext(AppContext);

  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      if (isEdit) {
        setLoading(true);
        await getDetailsHandler(isEdit);
        setLoading(false);
      }
    })();
  }, [isEdit]);
  const onSubmitHandler = async (
    values: SubscriptionPackageInterface,
    formikHelpers: any
  ) => {
    let payload = { ...values };
    if (payload?.isFreeForever) {
      payload.maxProperty = payload.propertyIncludedInBasePrice;
      payload.isMaxPropertyUnlimited = false;
    }
    await editDetailsHandler(payload, formikHelpers, {
      onSuccess: async (payload) => {
        if (returnURL) {
          navigate(returnURL);
        } else {
          navigate(PageLinks.package.list);
        }
      },
    });
  };
  return (
    <PageTemplate
      scrollAll
      title={"Package"}
      breadCrumb={[
        {
          name: "Package",
        },
        {
          name: isEdit ? "Edit" : "Create",
        },
      ]}
      backNavigation={{
        title: isEdit ? "Edit Package" : "New Package",
        path: PageLinks?.package?.list,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={PackageFormik?.initialValues(isEdit && details)}
        validationSchema={PackageFormik?.validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ isSubmitting}) => {
          return (
            <Form className={"flex flex-col gap-10"}>
              <PackageForm />
              <FormConcludeButtons
                cancelButton={{
                  title: "Cancel",
                }}
                submitButton={{
                  isLoading: isSubmitting,
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewPropertyPage;
