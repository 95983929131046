import React from "react";
import { MyTextField } from "components";
import { FormWrapper } from "assets";

import { AddressFormInterface } from "./interfaces";

function AddressForm({ isInFormModal }: AddressFormInterface) {
  return (
    <FormWrapper header={{ title: { name: "Address Details" } }}>
      <div
        className={`grid items-start grid-cols-3 gap-10 md:grid-cols-2 sm:grid-cols-1 `}
      >
        <MyTextField
          placeholder={"Eg. 109 Olney Street"}
          label={"Address-1"}
          name={"address1"}
        />
        <MyTextField
          placeholder={"Eg. 109 Olney Street 2"}
          label={"Address-2"}
          name={"address2"}
        />

        <MyTextField
          label={"Town/City"}
          placeholder={"Eg. Manchester"}
          name={"city"}
        />
        <MyTextField
          label={"Postal Code"}
          placeholder={"Enter postal code"}
          name={"postalCode"}
        />
        <MyTextField
          label={"County"}
          placeholder={"Eg. Manchester"}
          name={"county"}
        />
        <MyTextField
          label={"Country"}
          placeholder={"Enter postal code"}
          name={"country"}
        />
      </div>
    </FormWrapper>
  );
}

export default AddressForm;
