import { useContext } from "react";
import { useField } from "formik";

import { AppContext } from "context";
import { Api, getIconHelper } from "helpers";
import { ApiUrl } from "services";

import { MyUploadDocumentInterface } from "./interfaces";
import { IconTypeEnum } from "interfaces";
import { FileViewCard } from "../cards";

export default function MyUploadDocument({
  fileType,
  fileContainerStyle,
  onChangeCallBack,
  onDeleteHandler,
  ...props
}: MyUploadDocumentInterface) {
  const [field, meta, helpers] = useField(props);
  const { postFormApi } = Api();
  const ImageIcon = getIconHelper(IconTypeEnum.Images);

  const { handlers } = useContext(AppContext);
  let { setValue } = helpers;
  let isError = meta.touched && meta.error;
  const isMultiple = Array.isArray(field.value);
  const changeHandler = async (e) => {
    try {
      handlers?.setLoading(true);
      let file = e.target.files[0];
      const bodyFormData = new FormData();
      bodyFormData.append("file", file);
      bodyFormData.append("imageTitle", field?.name);

      let res: any = await postFormApi(
        ApiUrl.file.post_uploadFile,
        bodyFormData
      );
      if (!res.success) {
        throw res;
      }
      if (isMultiple) {
        let value = [...field.value, res.name];
        setValue(value);
        if (typeof onChangeCallBack === "function") {
          onChangeCallBack(value);
        }
      } else {
        setValue(res.name);
        if (typeof onChangeCallBack === "function") {
          onChangeCallBack(res.name);
        }
      }
    } catch (error) {
      handlers.setError(true, error.message);
    } finally {
      handlers?.setLoading(false);
    }
  };

  const removeHandler = (index) => {
    if (typeof onDeleteHandler === "function") {
      onDeleteHandler(index);
    }
    if (typeof field?.value === "string") {
      setValue("");
    } else {
      let value = [...field.value];
      value.splice(index, 1);
      setValue(value);
    }
  };

  return (
    <div className="input_container">
      {props?.label && <label className={"input_label"}>{props?.label}</label>}

      {/* select file*/}
      {(isMultiple || !field.value) && (
        <label>
          {fileType !== "image" ? (
            <div
              className={
                "flex flex-col items-center justify-center flex-1 h-[258px] gap-5 bg-[#f6f6f7] border-dashed border-[#C0C0C0] border-2 rounded-[28px]  "
              }
            >
              <div className={"p-5 bg-white rounded-full text-[24px]"}>
                <ImageIcon className={"text-primary"} />
              </div>

              <span className={"text-[18px] font-bold"}>
                Drop your file here or Browse
              </span>
              <div
                className={
                  "flex flex-col items-center text-center text-[#c0c0c0] text-[16px] font-displayLight"
                }
              >
                <span>
                  Accepted formats :
                  {props?.accept || "Word, PDF, Pictures (GIF, JPG, PNG)."}{" "}
                </span>
                <span>Maximum size: 15MB</span>
              </div>
            </div>
          ) : (
            <div
              className={
                "flex items-center gap-3 h-[48px]  bg-[#f6f6f7] rounded-full p-2 text-[12px]"
              }
            >
              <div
                className={
                  "bg-tBlue text-white rounded-full  p-1 px-4 uppercase"
                }
              >
                Choose
              </div>
              <div className={"text-[#c0c0c0]"}>No file selected.</div>
            </div>
          )}

          <input
            className="hidden"
            type="file"
            accept={props?.accept}
            name={field.name}
            onChange={changeHandler}
          />
        </label>
      )}
      {isError && <span className="input_textError">* {meta.error}</span>}
      {/*selected doc*/}
      <div
        className={
          fileContainerStyle ||
          "grid grid-cols-4 gap-5 md:grid-cols-2 sm:grid-cols-1 mt-2"
        }
      >
        {isMultiple
          ? field?.value?.length > 0 && (
              <>
                {field?.value?.map((e, key) => {
                  return (
                    <FileViewCard
                      key={key}
                      filePath={e}
                      deleteHandler={() => removeHandler(key)}
                    />
                  );
                })}
              </>
            )
          : field.value && (
              <FileViewCard
                filePath={meta.value}
                key={0}
                deleteHandler={() => removeHandler(0)}
              />
            )}
      </div>
    </div>
  );
}
