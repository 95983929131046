import * as yup from "yup";
import { AdminInterface } from "interfaces";

export const UserFormik = {
  initialValues: (values?: AdminInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      email: values?.email || "",
      name: values?.name,
      phone: values?.phone || "",
      photo: values?.photo || "",
      privilege: values?.privilege?._id || "",
      isSeed: values?.isSeed || false,
      password: "",
    };
  },
  validationSchema: (editID) =>
    yup.object().shape({
      name: yup.string().required("required"),
      email: yup.string().email("invalid").required("required"),
      phone: yup.string().required("required"),
      photo: yup.string(),
      privilege: yup.string().required("required"),
      password: editID
        ? yup.string()
        : yup
            .string()
            .matches(
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              "Password must contain at least 8 characters, one uppercase, one number and one special case character"
            )
            .required("required"),
    }),
};
