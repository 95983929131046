import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import {
  appReducer,
  appState,
  logoutAction,
  setActionLoadingAction,
  setAuthenticateAction,
  setAuthLoadingAction,
  setErrorAction,
  setLoadingAction,
  setMessageAction,
  setNotificationAction,
  setPageErrorAction,
  setProfileAction,
  setSuccessAction,
  setSystemSettingsAction,
} from "stores";
import { useConfirmHook } from "useHooks";
import { toast, ToastContainer } from "react-toastify";

import { Api, clearAuthToken, getAuthToken, setAuthToken } from "helpers";
import { AuthTokenENUM } from "interfaces";
import { ApiUrl } from "services";
import { Loader } from "../../components";

export const AppContext = createContext({
  state: appState,
  handlers: {
    login: (token: string) => {},
    logout: (payload: any) => {},
    getCurrentProfile: () => {},
    setActionLoading: (payload: any) => {},
    setError: (flag: boolean, message?: string) => {},
    setLoading: (payload: any) => {},
    setMessage: (message: string) => {},
    setNotification: (payload: any) => {},
    setAuthLoading: (payload: any) => {},

    loginTenantSpaceHandler: (tenantId: string) => {},
    dispatchLogoutActionHandler: () => {},
    setSuccess: (flag: boolean, message?: string) => {},
    alertCloseHandler: () => {},
    getSystemSettingsHandler: () => {},
    editSystemSettingsHandler: (payload) => {},
    setPageErrorHandler: (status: boolean, message?: string) => {},
  },
});

const AppProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(appReducer, appState);
  const navigate = useNavigate();
  const { getApi, putApi } = Api();
  const { confirm } = useConfirmHook();

  const logoutRedirectHandler = () => {
    navigate({
      pathname: "/auth/login",
    });
  };
  const loginTenantSpaceHandler = async (tenantId: string) => {
    try {
      setLoading(true);
      const res = await getApi(
        ApiUrl?.auth.get_loginToTenantSpace,
        { tenant: tenantId },
        AuthTokenENUM.refreshToken
      );
      setAuthToken(res?.data?.accessToken, AuthTokenENUM.accessToken);
      setAuthToken(res?.data?.refreshToken, AuthTokenENUM.refreshToken);
      setLoading(false);
      await getCurrentProfile();
      navigate("/");
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const getCurrentProfile = async () => {
    try {
      dispatch(setAuthLoadingAction(true));
      setLoading(true);
      const res = await getApi(ApiUrl.auth.get_currentProfile);
      dispatch(setProfileAction(res?.data));
    } catch (error) {
      setError(true, error?.message);
      clearAuthToken(AuthTokenENUM.refreshToken);
      clearAuthToken(AuthTokenENUM.accessToken);
      logoutRedirectHandler();
    } finally {
      setLoading(false);
      dispatch(setAuthLoadingAction(false));
    }
  };
  const getSystemSettingsHandler = async () => {
    try {
      setLoading(true);
      const res = await getApi(ApiUrl.settings.get_spaceSettings);
      dispatch(setSystemSettingsAction(res?.data));
    } catch (error) {
      setError(true, error?.message);
    } finally {
      setLoading(false);
    }
  };
  const editSystemSettingsHandler = async (payload) => {
    try {
      setLoading(true);
      await putApi(ApiUrl?.settings.put_updateSSettings, payload);
      setSuccess(true, "Updated Successfully!");
      await getSystemSettingsHandler();
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };

  const authCheckHandler = async () => {
    try {
      if (
        getAuthToken(AuthTokenENUM.refreshToken) &&
        getAuthToken(AuthTokenENUM.accessToken)
      ) {
        await getCurrentProfile();
        await getSystemSettingsHandler();
      } else if (getAuthToken(AuthTokenENUM.refreshToken)) {
        setAuthenticateHandler(true);
        navigate("/workspace");
      } else {
        logoutRedirectHandler();
      }
    } catch (err) {
      setError(true, err?.message);
    } finally {
      dispatch(setAuthLoadingAction(false));
    }
  };
  useEffect(() => {
    (async () => {
      await authCheckHandler();
    })();
  }, []);
  const dispatchLogoutActionHandler = () => {
    dispatch(logoutAction({}));
  };

  // dispatch handlers
  const login = async (token: string) => {
    setAuthToken(token, AuthTokenENUM.refreshToken);
    setAuthenticateHandler(true);
    await authCheckHandler();
  };

  const logout = async (payload: any) => {
    try {
      let isConfirm = await confirm(
        "Are you  sure you want to log out?",
        "Log Out",
        "Stay Logged In"
      );
      if (!isConfirm) {
        return;
      }
      setLoading(true);
      await getApi(ApiUrl.auth.get_logout, {}, AuthTokenENUM.refreshToken);
    } catch (error) {
    } finally {
      dispatch(logoutAction(payload));
      logoutRedirectHandler();
      clearAuthToken(AuthTokenENUM.accessToken);
      clearAuthToken(AuthTokenENUM.refreshToken);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state?.isActionSuccess) {
      toast.success(state?.message, {
        onClose: () => setSuccess(false, ""),
      });
    }
    if (state?.isError) {
      toast.error(state?.message, {
        onClose: () => setError(false, ""),
      });
    }
  }, [state?.isError, state.isActionSuccess]);
  const setAuthenticateHandler = (payload: boolean) => {
    dispatch(setAuthenticateAction(payload));
  };
  const setActionLoading = (payload: any) => {
    dispatch(setActionLoadingAction(payload));
  };
  const setError = (flag: boolean, message?: string) => {
    dispatch(setErrorAction(flag));
    setMessage(message);
  };
  const setLoading = (payload: boolean) => {
    dispatch(setLoadingAction(payload));
  };
  const setMessage = (message: string) => {
    dispatch(setMessageAction(message));
  };
  const setNotification = (payload: any) => {
    dispatch(setNotificationAction(payload));
  };
  const setSuccess = (flag: boolean, message?: string) => {
    dispatch(setSuccessAction(flag));
    setMessage(message);
  };
  const setAuthLoading = (flag: boolean) => {
    dispatch(setAuthLoadingAction(flag));
  };
  const alertCloseHandler = () => {
    setNotification(false);
    setSuccess(false, "");
    setError(false);
    setMessage("");
  };
  const setPageErrorHandler = (status: boolean, message?: string) => {
    dispatch(setPageErrorAction(status, message));
  };
  let contextValue = {
    state: state,
    handlers: {
      login,
      logout,
      setActionLoading,
      setError,
      setLoading,
      setMessage,
      setNotification,
      setSuccess,
      alertCloseHandler,
      setAuthLoading,
      getCurrentProfile,
      loginTenantSpaceHandler,
      getSystemSettingsHandler,
      editSystemSettingsHandler,
      setPageErrorHandler,
      dispatchLogoutActionHandler,
    },
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
      {state?.isLoading && <Loader />}
      <ToastContainer autoClose={1000} position={"bottom-right"} />
    </AppContext.Provider>
  );
};

export default AppProvider;
