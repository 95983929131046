import React, { useContext } from "react";

import { PageTemplate } from "templates";

import { AppContext } from "context";
import { NameValueText } from "assets";
import { getIconHelper } from "helpers";
import { ImageViewCard, MyButton } from "components";
import { IconTypeEnum } from "interfaces";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";

function ProfilePage() {
  const EditIcon = getIconHelper(IconTypeEnum.EDIT);
  const navigate = useNavigate();
  const {
    state: { profileDetails },
  } = useContext(AppContext);
  return (
    <PageTemplate
      title={"Profile Details"}
      titleRightChildren={
        <div>
          <MyButton
            onClick={() => navigate(PageLinks.profile.edit)}
            iconType={IconTypeEnum.EDIT}
            isOutline
            name={"Edit Profile"}
          />
        </div>
      }
    >
      <div className={"flex flex-col gap-5"}>
        {profileDetails?.photo && (
          <ImageViewCard fileName={profileDetails?.photo} />
        )}
        <div
          onClick={() => navigate(PageLinks.profile.changePassword)}
          className={"flex items-center gap-2 cursor-pointer"}
        >
          <EditIcon />
          <span>Change Password</span>
        </div>
        <NameValueText name={"Name"} value={profileDetails?.name} />
        <div className={"grid grid-cols-3 gap-5"}>
          <NameValueText
            name={"Phone Number"}
            value={profileDetails?.phone || "-"}
          />
          <NameValueText
            name={"Email Address"}
            value={profileDetails?.email || "-"}
          />
        </div>
      </div>
    </PageTemplate>
  );
}

export default ProfilePage;
