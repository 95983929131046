import { createContext, useContext, useState } from "react";

import { AppContext } from "../appContext";
import { systemDataInterface } from "./interface";

export const SystemDataContext = createContext<systemDataInterface>({});

const SystemDataProvider = ({ children }) => {
  const { state } = useContext(AppContext);
  const [apiState, setApiState] = useState({});
  const [data, setData] = useState({});

  let values = {
    ...data,
    ...apiState,
  };

  return (
    <SystemDataContext.Provider value={values}>
      {children}
    </SystemDataContext.Provider>
  );
};

export default SystemDataProvider;
