import React from "react";
import { NavLink } from "react-router-dom";
import { TabComponentInterface } from "./interfaces";

function TabComponent({ tabs }: TabComponentInterface) {
  return (
    <>
      <div className={"flex flex-col gap-5"}>
        {/*title*/}
        <div
          className={
            " flex items-center gap-16 border-b-[1px] border-gray-200 w-full overflow-x-scroll hideScroll cursor-pointer select-none"
          }
        >
          {tabs?.map((e, key) => {
            if (!e?.title) {
              return;
            }
            return (
              <NavLink
                end
                key={key}
                id={e?.pathName}
                to={`${e?.pathName}`}
                className={({ isActive }) => {
                  return `h-[40px]  min-w-[80px] text-center whitespace-nowrap text-gray-500  ${
                    isActive && "text-blue-800 border-b-2 border-b-blue-900 "
                  }`;
                }}
              >
                {e?.title}
              </NavLink>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default TabComponent;
