import { useContext } from "react";
import { createPortal } from "react-dom";

import { MyButton } from "components";

import { ConfirmContext } from "context";

export default function AlertModal() {
  const {
    prompt,
    isOpen = false,
    proceed,
    cancel,
    cancelTitle,
    confirmTitle,
  } = useContext(ConfirmContext);

  return (
    isOpen &&
    createPortal(
      <div className="fixed flex items-center justify-center h-screen w-screen left-0 top-0 z-[999] bg-gray-700 bg-opacity-75">
        <div className="flex flex-col  bg-white w-[300px]  rounded-lg text-center p-5 gap-4">
          <span className="font-bold text-[18px]">{prompt}</span>
          <div className="flex flex-col gap-2">
            <MyButton name={confirmTitle || "Ok"} onClick={proceed} />
            <MyButton
              isOutline
              name={cancelTitle || "Cancel"}
              onClick={cancel}
            />
          </div>
        </div>
      </div>,
      document.getElementById("popup-root")
    )
  );
}
