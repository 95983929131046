export enum UserRoleEnum {
  ADMIN = "admin",
  LANDLORD = "landlord",
  OWNER = "OWNER",
  TENANT = "tenant",
  SUB_TENANT = "sub_tenant",
  SUPPLIER = "supplier",
  LENDER = "LENDER",
  CUSTOM = "custom",
  NONE = "none",
}
export enum InvoiceSettlementSourceEnum {
  "RETURN" = "RETURN",
  "RECONCILIATION" = "RECONCILIATION",
}
export enum FinanceAccountGroupEnum {
  Sales = "sales",
  Purchase = "purchase",
  Income = "income",
  Expense = "expense",
}
export enum PackagePurchaseType {
  SUBSCRIPTION = "subscription",
  PROPERTY = "property",
}

export enum PackageTimeType {
  ANNUALLY = "annually",
  MONTHLY = "monthly",
}
export enum TaxTypeEnum {
  INCLUSIVE = "inc",
  EXCLUSIVE = "exc",
  EXEMPTED = "exe",
}

export enum TenancyStatusEnum {
  FUTURE = "future",
  EXPIRED = "expired",
  ACTIVE = "active",
  DRAFT = "draft",
}

export enum JournalAccountGroupEnum {
  Income = "income",
  Expenses = "expenses",
  Assets = "assets",
  Liability = "liabilities",
  Equity = "equity",
}

export enum JournalAccountSubgroupEnum {
  // Income subgroups
  Sales = "Sales",
  OtherIncome = "Other Income", // Other Income ,

  // Expenses subgroups
  CostOfSales = "Cost of Sales",
  AdministrativeExpenses = "Administrative Expenses",
  Taxation = "Taxation",

  // Equity subgroups
  CapitalReserve = "Capital & Reserves",

  //Liabilities
  CurrentLiabilityAfterMoreThanYear = "Current Liabilities-amounts falling due after more than one year",
  CurrentLiabilityWithinYear = "Current Liabilities Falling Due within One Year",

  // Assets subgroups
  InvestmentProperties = "Investment Properties",
  InvestmentCurrent = "Investments - Current",
  InvestmentFixed = "Investments - Fixed",
  CurrentAssets = "Current Assets",
  NonCurrentAssets = "Non-Current Assets",
  TangibleFixedAssets = "Tangible Fixed Assets",
  IntangibleFixedAssets = "Intangible Fixed Assets",
}

export enum StaticLedgerNameEnum {
  Sales = "Sales",
  SalesReturn = "Sales Return",
  RentalIncome = "Rental Income",
  RentalIncomeReturn = "Rental Income Return",
  LateFee = "Late Fee",
  Laundry = "Laundry",
  DiscountReceived = "Discount Received",
  Parking = "Parking",

  Purchase = "Purchase",
  PurchaseReturn = "Purchase Return",
  AgentFees = "Agent Fees",
  CertificatesAndInspections = "Certificates and inspections",
  CouncilTax = "Council tax",
  DepreciationAndAmortisation = "Depreciation and amortisation",
  DirectEmployersNI = "Direct Employers NI",
  DirectEmployersPensions = "Direct employers pensions",
  DirectMotorExpenses = "Direct Motor expenses",
  DirectTravelAndSubsistenceExpenses = "Direct Travel and subsistence expenses",
  DirectWages = "Direct wages",
  GasAndElectricity = "Gas and electricity",
  GroundRent = "Ground rent",
  HealthAndSafety = "Health and safety",
  HouseholdAndCleaning = "Household and cleaning",
  LegalAndProfessionalFees = "Legal and professional fees- Direct",
  MarketingAndAdvertisingExpenses = "Marketing and advertising expenses",
  OtherDirectCosts = "Other direct costs",
  OtherPropertyExpenses = "Other property expenses",
  PropertyInsurance = "Property insurance",
  RatesAndWater = "Rates and water-Direct",
  Rents = "Rents",
  RepairsAndMaintenance = "Repairs and maintenance",
  ServiceCharges = "Service charges",
  SubcontractorCosts = "Subcontractor costs",

  // Admin expenses
  DiscountPaid = "Discount paid",
  AccountancyFees = "Accountancy fees",
  AdvertisingExhibitionsAndMarketingCosts = "Advertising, exhibitions and marketing costs",
  Amortisation = "Amortisation",
  AuditFees = "Audit fees",
  BadDebts = "Bad debts",
  BankCharges = "Bank charges",
  Cleaning = "Cleaning",
  Commissions = "Commissions",
  ComputerExpenses = "Computer expenses",
  Depreciation = "Depreciation",
  DirectorsFees = "Directors' fees",
  DirectorsNI = "Directors' NI",
  DirectorsPension = "Directors' pension",
  DirectorsSalaries = "Directors' salaries",
  Donations = "Donations",
  EmployersNI = "Employers NI",
  EmployersPensions = "Employers pensions",
  Entertaining = "Entertaining",
  ForeignExchangeGainsLosses = "Foreign exchange gains/losses",
  GeneralOfficeExpenses = "General office expenses",
  HealthAndSafetyCosts = "Health and safety costs",
  Impairments = "Impairments",
  Insurance = "Insurance",
  LegalAndProfessionalFeesIndirect = "Legal and professional fees- Indirect",
  LightAndHeat = "Light and heat",
  ManagementFees = "Management fees",
  MotorExpenses = "Motor expenses",
  PrintingPostageAndStationery = "Printing, postage and stationery",
  ProfitLossOnDisposalOfIntangibleFixedAssets = "Profit/loss on disposal of intangible fixed assets",
  ProfitLossOnDisposalOfInvestments = "Profit/loss on disposal of investments",
  ProfitLossOnDisposalOfTangibleFixedAssets = "Profit/loss on disposal of tangible fixed assets",
  RatesAndWaterIndirect = "Rates and water-Indirect",
  RecruitmentCosts = "Recruitment costs",
  Rent = "Rent",
  RepairsRenewalsAndMaintenance = "Repairs, renewals and maintenance",
  StaffTrainingAndWelfare = "Staff training and welfare",
  Subscriptions = "Subscriptions",
  SundryExpenses = "Sundry expenses",
  TemporaryStaff = "Temporary staff",
  TravelAndSubsistence = "Travel and subsistence",
  WagesAndSalaries = "Wages and salaries",
  PestControl = "Pest Control",
  ProfessionalServices = "Professional Services",
  RealEstateTaxes = "Real Estate Taxes",
  RepairsAppliances = "Repairs Appliances",
  RepairsElectrical = "Repairs Electrical",
  RepairsPainting = "Repairs Painting",
  RepairsPlumbing = "Repairs Plumbing",
  TelephoneAndInternet = "Telephone & Internet",
  CourierAndPostage = "Courier & Postage",
  BankInterest = "Bank interest",
  BankLoanAndMortgageInterest = "Bank loan and mortgage interest",
  FinanceCosts = "Finance costs",
  OtherInterestPayable = "Other interest payable",
  OtherLoanInterest = "Other loan interest",

  CorporationTaxExpense = "Corporation tax Expense",
  DeferredTaxExpenseIncome = "Deferred Tax Expense/(Income)",

  InvestmentProperties = "Investment Properties",
  UnlistedInvestmentsCurrent = "Unlisted investments-Current",
  ShortTermDeposits = "Short term deposits",
  OtherInvestmentsHeldForSale = "Other investments, held for sale",
  ListedInvestmentsCurrent = "Listed investments-Current",
  ListedInvestmentsFixed = "Listed investments-Fixed",
  UnlistedInvestmentsFixed = "Unlisted investments-Fixed",

  Goodwill = "Goodwill",
  OtherIntangibles = "Other intangibles",

  PlantPropertyAndEquipment = "Plant, Property and equipment",
  PlantPropertyAndEquipmentAccumulatedDepreciation = "Plant, Property and equipment-Accumulated Depreciation",
  MotorVehicles = "Motor vehicles",
  MotorVehiclesAccumulatedDepreciation = "Motor vehicles-Accumulated Depreciation",
  FurnitureFixturesAndEquipment = "Furniture, fixtures and equipment",
  FurnitureFixturesAndEquipmentAccumulatedDepreciation = "Furniture, fixtures and equipment-Accumulated Depreciation",

  Loan = "Loan",

  Stock = "Stock",
  AccountReceivable = "Account Receivable",
  AmountsRecoverableOnContracts = "Amounts recoverable on contracts",
  OtherDebtors = "Other debtors",
  CorporationTaxRecoverable = "Corporation tax recoverable",
  AmountsOwedByGroupUndertakings = "Amounts owed by group undertakings",
  AmountsOwedByOtherRelatedParties = "Amounts owed by other related parties",
  PrepaymentsAndAccruedIncome = "Prepayments and accrued income",
  VATReceivable = "VAT Receivable",
  TaxReceivable = "Tax Receivable",
  BankCurrentAccountGBP = "Bank current account - GBP",
  BankCurrentAccountEUR = "Bank current account - EUR",
  CashInHand = "Cash in hand",
  BankCurrentAccountUSD = "Bank current account - USD",
  BankCurrentAccountAdditional = "Bank current account - additional",

  MortgageLoanMoreThanYear = "Mortgage Loan More than Year",
  OtherCreditorsMoreThanOneYear = "Other creditors More than one Year",
  Provisions = "Provisions",
  DeferredTax = "Deferred Tax",
  CreditCard = "Credit Card",
  TradeCreditors = "Trade Creditors",
  AccountPayable = "Account Payable",
  VATPayable = "VAT Payable",
  TaxPayable = "Tax Payable",
  CorporationTaxPayable = "Corporation tax Payable",
  OtherTaxesAndSocialSecurity = "Other taxes and social security",
  AccrualsAndDeferredIncome = "Accruals and deferred income",
  BankLoansLessThanOneYear = "Bank loans Less than One Year",
  OtherCreditorsLessThanOneYear = "Other creditors Less than one Year",
  DirectorsLoanAccount = "Directors loan account",
  AmountsOwedToGroupUndertakings = "Amounts owed to group undertakings",
  AmountsOwedToRelatedParties = "Amounts owed to related parties",

  OrdinaryShares = "Ordinary shares",
  PreferenceShares = "Preference shares",
  SharePremium = "Share Premium",
  RevaluationReserve = "Revaluation Reserve",
  GeneralReserve = "General Reserve",
  FinalDividendsPaid = "Final dividends paid",
  InterimDividendsPaid = "Interim dividends paid",
  ProfitLossAccountDuringThePeriod = "Profit & Loss Account During the Period/ Current Year Earnings",
}

export enum BankSourceEnum {
  Manual = "MANUAL",
  SEED = "SEED",
  TrueLayer = "TRUE_LAYER",
}

export enum LedgerFilterForEnum {
  SALES_INVOICE = "SALES_INVOICE",
  PURCHASE_INVOICE = "PURCHASE_INVOICE",
}

export enum TransactionTypeEnum {
  DR = "DR",
  CR = "CR",
}

export enum StatementStatusEnum {
  PENDING = "PENDING",
  AMBIGUOUS = "AMBIGUOUS",
  CANCELLED = "CANCELLED",
  SUCCESS = "SUCCESS",
  TRANSFERRED = "TRANSFERRED",
}

export enum StatementSourceEnum {
  IMPORT = "IMPORT",
  MANUAL = "MANUAL",
  TRANSFER = "TRANSFER",
}

export enum ReconciliationInvoiceEnum {
  INVOICE = "INVOICE",
  DIRECT = "DIRECT",
  ADVANCE = "ADVANCE",
  NONE = "NONE",
}

export enum TrueLayerTransactionCategoryEnum {
  PURCHASE = "PURCHASE",
  BILL_PAYMENT = "BILL_PAYMENT",
  CREDIT = "CREDIT",
  DIRECT_DEBIT = "DIRECT_DEBIT",
  TRANSFER = "TRANSFER",
  ATM = "ATM",
  CASH = "CASH",
}

export enum NameFormatEnum {
  FIRST_NAME_FIRST = "First Name First",
  LAST_NAME_FIRST = "Last Name First",
}

export enum DateTimeFormatEnum {
  "YYYY-MMM-DD" = "YYYY-MMM-DD",
}

export enum TimeFormatEnum {
  "Twenty_Four_HOUR" = "24 Hour",
  "TWELVE_HOUR" = "12 Hour",
}
