import React from "react";
import { Route } from "react-router-dom";
import { EditRolesPage, RoleDetailsPage, RolesPermissionPage } from "./pages";
import { AdminAccessListEnum, ParamsStringEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="roles/">
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.AUTH_READ_ROLE]}
            isForPage={true}
          >
            <RolesPermissionPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"create"}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.AUTH_ADD_ROLE]}
            isForPage={true}
          >
            <EditRolesPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.AUTH_EDIT_ROLE]}
            isForPage={true}
          >
            <EditRolesPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`details/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.AUTH_READ_ROLE]}
            isForPage={true}
          >
            <RoleDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
