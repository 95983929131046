import { useField } from "formik";
import "./styles.css";
import { MyTextAreaFieldInterface } from "./interfaces";

export default function MyTextField({
  label,
  isRequired,
  ...props
}: MyTextAreaFieldInterface) {
  const [field, meta] = useField(props);
  let isError = meta.touched && meta.error && typeof meta?.error === "string";
  return (
    <div className="input_container">
      {label && (
        <label className={`input_label `}>
          {label}
          {isRequired && <span className={"text-tRed "}>*</span>}
        </label>
      )}
      <textarea
        inputMode={"text"}
        {...field}
        {...props}
        style={{
          borderWidth: "1px",
          borderColor: isError ? "red" : "#C0C0C0",
          ...props?.style,
        }}
        className={`rounded-xl outline-none  text-[#00040E]  ${props?.className} `}
        rows={4}
        placeholder={props?.placeholder || "Type here..."}
      />
      {isError && meta?.error?.trim() && (
        <span className="input_textError">* {meta.error}</span>
      )}
    </div>
  );
}
