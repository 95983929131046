import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { DataContext } from "../context";
import { ValidateOTPPasswordFormik } from "../helpers";
import { MyButton, MyTextField } from "components";
import { useSearchParams } from "react-router-dom";
import { SearchQueryEnum } from "../interfaces";
import { AppContext } from "context";

function VerifyOTPPage() {
  const { validateForgetPasswordOTPHandler, requestForgetPasswordHandler } =
    useContext(DataContext);
  const { handlers } = useContext(AppContext);
  const [params] = useSearchParams();
  let otpLength = parseInt(params.get(SearchQueryEnum.OTP_LENGTH));
  let email = params.get(SearchQueryEnum.EMAIL);
  const resendOTPHandler = async () => {
    handlers?.setLoading(true);
    await requestForgetPasswordHandler(
      {
        emailOrPhone: email,
      },
      {}
    );
    handlers?.setLoading(false);
  };
  return (
    <Formik
      onSubmit={validateForgetPasswordOTPHandler}
      initialValues={ValidateOTPPasswordFormik.initialValues}
      validationSchema={ValidateOTPPasswordFormik.validationSchema(otpLength)}
    >
      {({ isSubmitting, errors }) => {
        return (
          <Form className={"flex flex-col gap-10 text-[14px]"}>
            <div className={"flex flex-col gap-5"}>
              <span className={"text-[14px] text-red-500"}>
                {typeof errors?.error === "string" && `*${errors?.error}`}
              </span>

              <MyTextField
                maxChar={otpLength}
                isNumberOnly
                name={"otp"}
                label={"Enter OTP"}
                isRequired
                placeholder={`Enter ${otpLength} digits OTP code`}
              />
            </div>
            <MyButton
              name={"Verify OTP"}
              type={"submit"}
              isLoading={isSubmitting}
            />
            <div>
              <span>Didn't received any code? </span>
              <span
                onClick={resendOTPHandler}
                className={"text-primary font-bold cursor-pointer"}
              >
                Resend Code
              </span>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default VerifyOTPPage;
