export enum FieldTypeEnum {
  // should be indexing by fieldType
  UNTITLED = "UNTITLED", // STATIC
  PROPERTY_TYPE = "PROPERTY_TYPE", // STATIC
  UNIT_SIZE = "UNIT_SIZE", // STATIC
  PROPERTY_AMENITIES = "PROPERTY_AMENITIES", // DYNAMIC
  UNIT_AMENITIES = "UNIT_AMENITIES", // DYNAMIC
  OWNER_TYPE = "OWNER_TYPE", // STATIC
  OWNERSHIP_TYPE = "OWNERSHIP_TYPE", // STATIC
  TENANCY_TYPE = "TENANCY_TYPE", // STATIC
  ANNUAL_FREQUENCY = "ANNUAL_FREQUENCY", // STATIC
  YEARLY_FREQUENCY = "YEARLY_FREQUENCY", // STATIC
  TENANCY_PAYMENT_TERMS = "PAYMENT_TERMS", // DYNAMIC
  OPENING_BALANCE_TYPE = "OPENING_BALANCE_TYPE", // DYNAMIC
  TENANCY_UTILITIES_CHARGE = "UTILITIES_CHARGE", // DYNAMIC
  CHARGE_TYPE = "CHARGE_TYPE", // STATIC
  TENANCY_OTHER_CHARGE = "TENANCY_OTHER_CHARGE", // STATIC
  BANK_LIST = "BANK_LIST", // STATIC
  ADVANCE_OPTION = "ADVANCE_OPTION", // STATIC
  FURNISHED_TYPE = "FURNISHED_TYPE", // DYNAMIC
  SELECT_FLOOR = "SELECT_FLOOR", // DYNAMIC
  DOCUMENT_TYPE = "DOCUMENT_TYPE", // DYNAMIC
  COUNTRY = "COUNTRY", // DYNAMIC
}

export interface DynamicListInterface {
  _id: string;
  label: string;
  value: string;
  canRemove: boolean;
  isValueEditable: boolean; // can edit value?
  isSeed: boolean;
  isActive: boolean;
  isDeleted: boolean;
  description?: string;
  fieldType: FieldTypeEnum; // should be indexed by this field so searching would be faster
}
