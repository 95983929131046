import React, { useContext, useEffect } from "react";

import { PageTemplate } from "templates";
import { PurchaseHistoryContext, ReportContext } from "context";
import { TableCardComponent } from "components";

import { PurchaseHistoryTableColumns, TableFilterCard } from "content";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";

function PurchaseHistoryPage() {
  const { allData, isLoading, totalDocs, getAllDataHandlers } = useContext(
    PurchaseHistoryContext
  );
  const { startDate, endDate } = usePageQueryHooks();
  const { currentPage } = usePaginationQueryHooks();
  const pageQuery = {
    startDate,
    endDate,
  };
  const { generatePurchaseHistoryReportHandler } = useContext(ReportContext);

  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [startDate, endDate, currentPage]);
  const tableCardProps = {
    column: PurchaseHistoryTableColumns(),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
  };

  return (
    <PageTemplate scrollAll title={"Purchase History"}>
      <TableCardComponent
        havePagination={{
          totalDocs: totalDocs,
        }}
        exportXlsx={{
          generateHandler() {
            generatePurchaseHistoryReportHandler(pageQuery);
          },
        }}
        {...tableCardProps}
        children={{
          leftChildren: <TableFilterCard haveDateRange />,
        }}
      />
    </PageTemplate>
  );
}

export default PurchaseHistoryPage;
