import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  TableColumnInterface,
  AdminInterface,
  AdminAccessListEnum,
} from "interfaces";
import { MyMoreOptionButton } from "components";

import { UserContext } from "context";
import { UserTableProfile } from "assets";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import { decodeRoleOptionsHandler } from "../../../../helpers";

function UsersTableColumns(): TableColumnInterface<AdminInterface>[] {
  const navigate = useNavigate();
  const { getAllDataHandlers, deleteHandler, archiveHandler } =
    useContext(UserContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([AdminAccessListEnum.AUTH_EDIT_USER]);
  const canDelete = checkCanAccessHandler([
    AdminAccessListEnum.CUSTOMER_DEL_CUSTOMER,
  ]);

  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "User",
      render(renderData) {
        return (
          <UserTableProfile
            details={{
              name: renderData?.name,
              profile: renderData?.photo,
              subTitle: renderData?.privilege?.name,
            }}
          />
        );
      },
    },
    {
      title: "Contact Number",
      render(renderData) {
        return <div>{renderData?.phone} </div>;
      },
    },
    {
      title: "Email",
      render(renderData) {
        return <div>{renderData?.email}</div>;
      },
    },

    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View ",
                  handler: () => {
                    navigate(PageLinks.user.details(renderData?._id));
                  },
                },
                !renderData?.isArchived &&
                  canEdit && {
                    name: "Edit ",
                    handler: () => {
                      navigate(PageLinks.user.edit(renderData?._id));
                    },
                  },
                canDelete &&
                  !renderData?.isSeed && {
                    name: "Delete ",
                    handler: () => {
                      deleteHandler(renderData?._id, {
                        async onSuccess(payload?: any) {
                          await getAllDataHandlers();
                        },
                      });
                    },
                  },
                canDelete &&
                  !renderData?.isSeed && {
                    name: renderData?.isArchived ? `Unarchive` : "Archive",
                    handler: () => {
                      archiveHandler(renderData?._id, renderData?.isArchived, {
                        async onSuccess(payload?: any) {
                          getAllDataHandlers();
                        },
                      });
                    },
                  },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default UsersTableColumns;
