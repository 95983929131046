import { NavLink, useNavigate } from "react-router-dom";

import { IconTypeEnum, PageTemplateInterface } from "interfaces";

import "./styles.css";
import { BackNavigationText } from "assets";
import { getIconHelper } from "../helpers";
import getIconHelpers from "../helpers/utils/getIconHelpers";
import { usePageQueryHooks, usePaginationQueryHooks } from "../useHooks";
import { useContext, useEffect } from "react";
import { AppContext } from "../context";
import { PageErrorPage } from "../pages";

export default function PageTemplate({
  children,
  title,
  breadCrumb,
  dataTypeToggle,
  backNavigation,
  titleRightChildren,
  isBgTransparent,
}: PageTemplateInterface) {
  const navigate = useNavigate();
  const { handlers, state } = useContext(AppContext);
  const { returnURL } = usePageQueryHooks();
  useEffect(() => {
    return () => {
      handlers?.setPageErrorHandler(false, "");
    };
  }, []);
  const pageBackHandler = () => {
    if (backNavigation?.overRideReturnURL) {
      navigate(-1);
      return;
    }
    if (returnURL) {
      navigate(returnURL);
    } else if (backNavigation?.path) {
      navigate(backNavigation?.path);
    } else {
      navigate(-1);
    }
  };
  if (state?.havePageError) {
    return (
      <div className={`templateContent `}>
        <PageErrorPage backHandler={pageBackHandler} />
      </div>
    );
  }
  return (
    <div
      className={`templateContent ${
        isBgTransparent && "bg-transparent"
      } hideScroll`}
    >
      {/*top details*/}
      <div className={"flex flex-col gap-6"}>
        {/*title, dataStatus*/}
        <div className="flex items-start justify-between  ">
          <div className="flex flex-col gap-[4px]">
            {/*title*/}
            <span className="font-bold text-[28px]">{title}</span>
            <BreadCrumbNavigationComponent breadCrumb={breadCrumb} />
          </div>
          {titleRightChildren && titleRightChildren}
          {dataTypeToggle?.isDataType && (
            <PageToggleComponent badgeValue={dataTypeToggle?.badgeValue} />
          )}
        </div>
        {/*    back*/}
        {backNavigation && (
          <div className={"mt-[22px]"}>
            <BackNavigationText
              title={backNavigation?.title}
              backHandler={pageBackHandler}
            />
          </div>
        )}
      </div>

      {/*children*/}
      <div className={`grid hideScroll mt-[48px]`}>
        {state?.havePageError ? (
          <div>{state?.pageErrorMessage || "Error page"}</div>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

const PageToggleComponent = ({ badgeValue }) => {
  const { setArchive, isArchive, setCurrentPage } = usePaginationQueryHooks();
  const TimeIcon = getIconHelpers(IconTypeEnum.CLOCK);
  const ArchiveIcon = getIconHelpers(IconTypeEnum.ARCHIVE);
  const dataStatus = [
    {
      title: "Active",
      Icon: TimeIcon,
      badge: badgeValue?.length >= 1 && badgeValue[0],
      status: !isArchive,
      handler() {
        setCurrentPage(1);
        setArchive(false);
      },
    },
    {
      title: "Archived",
      Icon: ArchiveIcon,
      badge: badgeValue?.length >= 1 && badgeValue[1],
      status: isArchive,
      handler() {
        setCurrentPage(1);
        setArchive(true);
      },
    },
  ];
  return (
    <div className={"flex gap-1 bg-tGray rounded-full p-1"}>
      {dataStatus?.map((e, key) => {
        let Icon = e?.Icon;
        return (
          <div
            key={key}
            onClick={() => {
              e?.handler();
            }}
            className={`flex items-center justify-center gap-2 cursor-pointer min-w-[150px] min-h-[30px]  rounded-full text-[16px]  ${
              e?.status ? "bg-white text-black" : "fill-gray-500 text-gray-500"
            }`}
          >
            <Icon className={"w-[20px] h-[20px]"} />
            <span>{e?.title}</span>
            <span
              className={`flex items-center justify-center  px-1 min-w-[20px] h-[20px] text-[12px] rounded-full  text-white ${
                e?.status ? "bg-tGreen" : "bg-gray-400"
              }`}
            >
              {e?.badge}
            </span>
          </div>
        );
      })}
    </div>
  );
};
const BreadCrumbNavigationComponent = ({ breadCrumb }) => {
  const RightIcon = getIconHelper(IconTypeEnum.RIGHT);
  return (
    <div className={"flex items-center gap-1"}>
      {breadCrumb?.map((e, key) => {
        if (e) {
          return (
            <NavLink
              key={key}
              to={e?.path}
              className={() => {
                return `flex gap-1 items-center cursor-pointer  text-[16px] text-[#00040E]`;
              }}
            >
              <span>{e?.name}</span>
              <RightIcon className={" text-[16px] text-[#00040E"} />
            </NavLink>
          );
        }
      })}
    </div>
  );
};
