import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import React from "react";
import { PurchasePackageInterface } from "interfaces";

interface PropsInterface {
  data: PurchasePackageInterface[];
  dateFormatter({ date }: { date: Date });
}
async function generatePurchaseHistoryReport({
  data,
  dateFormatter,
}: PropsInterface) {
  try {
    //    create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const title = "Purchase History";
    const worksheet = workbook.addWorksheet(title, {});
    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 20;
    let tableHeaders = [
      {
        name: "SN.",
      },
      {
        name: "Purchase Date",
      },
      {
        name: "Order ID",
      },
      {
        name: "Ref No.",
      },
      {
        name: "Package",
      },
      {
        name: "Purchase Type",
      },

      {
        name: "Subscribed Package",
      },
      {
        name: "Duration",
      },
      {
        name: "Duration Time Type",
      },
      {
        name: "Properties",
      },
      {
        name: "Paid with",
      },
      {
        name: "Price",
      },
      {
        name: "Purchased on",
      },
      {
        name: "Expiry Date",
      },
      {
        name: "Status",
      },
    ];
    let tableRows = data?.map((e, index) => {
      return [
        index + 1,
        dateFormatter({
          date: e?.createdAt,
        }),
        e?.invoiceID,
        e?.stripe?.intentID,
        e?.subscribedPackage?.name,
        e?.purchaseType,
        e?.subscribedPackage?._id,
        e?.duration,
        e?.timeType?.replace("ly", ""),
        e?.propertyQuantity,
        e?.paymentMode,
        e?.orderCostDetail?.payableAmount,
        dateFormatter({
          date: e?.packageStartsAt,
        }),
        dateFormatter({
          date: e?.packageExpiredAt || e?.packageExpiryDate,
        }),
        e?.isExpired ? "Expired" : "Active",
      ];
    });
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(8).width = 35;

    // add a table to a sheet
    worksheet.addTable({
      name: title,
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        showRowStripes: false,
      },
      columns: tableHeaders,
      rows: tableRows,
    });
    //    save the workbook to a file
    const bufferFile = await workbook.xlsx.writeBuffer();
    await FileSaver.saveAs(
      new Blob([bufferFile], { type: "application/octet-stream" }),
      `${title}-${Date.now()}.xlsx`
    );
  } catch (err) {
    throw err;
  }
}

export default generatePurchaseHistoryReport;
