import React from "react";
import { Route, useSearchParams } from "react-router-dom";
import {
  LoginPage,
  ForgotPasswordPage,
  ChangePasswordPage,
  VerifyOTPPage,
} from "./pages";
import { PageTemplateComponent } from "./components";
import { DataContextProvider } from "./context";
import "./styles.css";
import { SearchQueryEnum } from "./interfaces";

function Index() {
  const [params] = useSearchParams();
  let otpLength = params.get(SearchQueryEnum.OTP_LENGTH);

  return (
    <Route path="auth/" element={<DataContextProvider />}>
      <Route
        path={"login"}
        element={
          <PageTemplateComponent
            data={{
              title: "Log in",
              subTitle: "Connecting Landlords and Tenants, One Home at a Time",
              imageType: "primary",
            }}
          >
            <LoginPage />
          </PageTemplateComponent>
        }
      />
      <Route
        path={"forgotPassword"}
        element={
          <PageTemplateComponent
            data={{
              title: "Forgot Password",
              subTitle: "Please, Continue With Your Email Address",
              imageType: "primary",
            }}
          >
            <ForgotPasswordPage />
          </PageTemplateComponent>
        }
      />
      <Route
        path={"verifyOTP"}
        element={
          <PageTemplateComponent
            data={{
              title: "Verify OTP",
              subTitle: `Verify Your Account With ${otpLength}-digit Code Sent To Your Mail`,
              imageType: "primary",
            }}
          >
            <VerifyOTPPage />
          </PageTemplateComponent>
        }
      />
      <Route
        path={"changePassword"}
        element={
          <PageTemplateComponent
            data={{
              title: "Set a new password",
              subTitle: "Choose a strong password to protect your account",
              imageType: "primary",
            }}
          >
            <ChangePasswordPage />
          </PageTemplateComponent>
        }
      />
    </Route>
  );
}

export default Index;
