import React, { useEffect, useState } from "react";

import { AiFillCloseCircle } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";

import { Api } from "helpers";
import { ApiUrl } from "services";
import { ImageViewInterface } from "./interfaces";

function ImageViewCard({
  fileName,
  removeHandler,
  staticPath,
  containerStyle,
  altText,
  onErrorHandler,
}: ImageViewInterface) {
  const [binaryFile, setBinaryFile] = useState("");
  const [isLoading, setLoading] = useState(true);
  const { getBlobResApi } = Api();
  useEffect(() => {
    (async () => {
      try {
        if (!staticPath) {
          setLoading(true);
          if (!fileName) {
            setLoading(false);
            return;
          }
          const res = await getBlobResApi(ApiUrl.file.get_file + fileName);
          const objectUrl = URL.createObjectURL(res);
          setBinaryFile(objectUrl);
        } else {
          setBinaryFile(fileName);
        }
      } catch (err) {
        onErrorHandler(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [fileName, staticPath]);

  return (
    <div
      className={`flex flex-col  items-center relative ${
        containerStyle || "w-[120px] h-[120px]  rounded-md "
      } overflow-hidden`}
    >
      {isLoading ? (
        <Skeleton
          className={`${
            containerStyle ? containerStyle : "h-[120px] w-[120px]"
          }`}
        />
      ) : (
        <>
          {binaryFile ? (
            <img
              onError={() => {
                onErrorHandler("error");
              }}
              src={binaryFile}
              className={"w-full h-full object-cover"}
              alt={altText || "serverImage"}
            />
          ) : (
            <div className={"w-full h-full bg-gray-100"}></div>
          )}
          {typeof removeHandler === "function" && (
            <AiFillCloseCircle
              fontSize={25}
              onClick={removeHandler}
              className="absolute right-0 top-0 cursor-pointer text-primary "
            />
          )}
        </>
      )}
    </div>
  );
}

export default ImageViewCard;
