export interface GrantedInterface {
  AUTH: {
    ADD_ROLE: boolean;
    EDIT_ROLE: boolean;
    DEL_ROLE: boolean;
    READ_ROLE: boolean;
    ADD_USER: boolean;
    EDIT_USER: boolean;
    DEL_USER: boolean;
    READ_USER: boolean;
  };
  SUBSCRIPTION: {
    ADD_SUBSCRIPTION: boolean;
    EDIT_SUBSCRIPTION: boolean;
    DEL_SUBSCRIPTION: boolean;
    READ_SUBSCRIPTION: boolean;
  };
  CUSTOMER: {
    DEL_CUSTOMER: boolean;
    READ_CUSTOMER: boolean;
    READ_PURCHASE_HISTORY: boolean;
  };
  MISC: {
    ACCESS_DASHBOARD: boolean;
  };
  CONFIGURATION: {
    MANAGE_SETTINGS: boolean;
  };
}

export interface AdminPrivilegeInterface {
  _id?: string;
  name: string; // name
  role: string; // custom for admin made
  isSeed: boolean;
  canEdit: boolean;
  canDelete: boolean;
  granted?: GrantedInterface; // generated from generatePrivilegeInterfaceFromEnum function
  isDeleted?: boolean;
  isArchived?: boolean;
}

export enum AdminAccessListEnum {
  // flag
  OR = "OR", // make access list OR conjunction
  AND = "AND", // make access list OR conjunction

  // PRIVILEGE
  AUTH_ADD_ROLE = "AUTH.ADD_ROLE",
  AUTH_EDIT_ROLE = "AUTH.EDIT_ROLE",
  AUTH_DEL_ROLE = "AUTH.DEL_ROLE",
  AUTH_READ_ROLE = "AUTH.READ_ROLE",

  // USER
  AUTH_ADD_USER = "AUTH.ADD_USER",
  AUTH_EDIT_USER = "AUTH.EDIT_USER",
  AUTH_DEL_USER = "AUTH.DEL_USER",
  AUTH_READ_USER = "AUTH.READ_USER",

  // SUBSCRIPTION
  SUBSCRIPTION_ADD_SUBSCRIPTION = "SUBSCRIPTION.ADD_SUBSCRIPTION",
  SUBSCRIPTION_EDIT_SUBSCRIPTION = "SUBSCRIPTION.EDIT_SUBSCRIPTION",
  SUBSCRIPTION_DEL_SUBSCRIPTION = "SUBSCRIPTION.DEL_SUBSCRIPTION",
  SUBSCRIPTION_READ_SUBSCRIPTION = "SUBSCRIPTION.READ_SUBSCRIPTION",

  // CUSTOMER
  CUSTOMER_DEL_CUSTOMER = "CUSTOMER.DEL_CUSTOMER",
  CUSTOMER_READ_CUSTOMER = "CUSTOMER.READ_CUSTOMER",
  CUSTOMER_READ_PURCHASE_HISTORY = "CUSTOMER.READ_PURCHASE_HISTORY",

  // MISC
  MISC_ACCESS_DASHBOARD = "MISC.ACCESS_DASHBOARD",

  // ADMINISTRATIVE SETUP
  CONFIGURATION_MANAGE_SETTINGS = "CONFIGURATION.MANAGE_SETTINGS",
}

export enum ModuleListEnum {
  AUTH = "AUTH",
  CONFIGURATION = "CONFIGURATION",
  RENTAL = "RENTAL",
  TENANCY = "TENANCY",
  INVOICE = "INVOICE",
  DOCUMENT = "DOCUMENT",
  COMMUNICATION = "COMMUNICATION",
  INVENTORY = "INVENTORY",
  FINANCE = "FINANCE",
  CANDIDATE = "CANDIDATE",
  BOOKING = "BOOKING",
  REPAIR_AND_MAINTENANCE = "REPAIR_AND_MAINTENANCE",
  NOTES = "NOTES",
  TASK = "TASK",
  BANK = "BANK",
  TOOLS = "TOOLS",
}

// export const generatePrivilegeInterfaceFromEnum = () => {
//     const accessEnum = dbEnum(AccessListEnum);
//     const tempObject: any = {};
//     const schemaObject: any = {};
//     for (const eachEnum of accessEnum) {
//         const key = eachEnum.toUpperCase()
//         tempObject[key] = 'boolean';
//         schemaObject[key] = {
//             type: 'Boolean',
//             default: false,
//         };
//     }
//     // console.log(JSON.stringify(dot.object(tempObject)));
// }
