import { Suspense } from "react";
import "react-loading-skeleton/dist/skeleton.css";

import {
  AppContextProvider,
  ConfirmContextProvider,
  CustomerContextProvider,
  OptionsContextProvider,
  PackageContextProvider,
  PrivilegeContextProvider,
  SystemContextProvider,
  UserContextProvider,
  PDFContextProvider,
  DashboardStatsContextProvider,
  ReportContextProvider,
  PurchaseHistoryContextProvider,
} from "./context";

import { Layout, Loader } from "./components";
import { ErrorBoundaryTemplate } from "./templates";
import { AuthRoutes } from "./routes";

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundaryTemplate>
        <ConfirmContextProvider>
          <AppContextProvider>
            <ReportContextProvider>
              <SystemContextProvider>
                <OptionsContextProvider>
                  <PDFContextProvider>
                    <PackageContextProvider>
                      <PrivilegeContextProvider>
                        <UserContextProvider>
                          <CustomerContextProvider>
                            <DashboardStatsContextProvider>
                              <PurchaseHistoryContextProvider>
                                <Layout>
                                  <AuthRoutes />
                                </Layout>
                              </PurchaseHistoryContextProvider>
                            </DashboardStatsContextProvider>
                          </CustomerContextProvider>
                        </UserContextProvider>
                      </PrivilegeContextProvider>
                    </PackageContextProvider>
                  </PDFContextProvider>
                </OptionsContextProvider>
              </SystemContextProvider>
            </ReportContextProvider>
          </AppContextProvider>
        </ConfirmContextProvider>
      </ErrorBoundaryTemplate>
    </Suspense>
  );
}

export default App;
