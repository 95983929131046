import { AdminPrivilegeInterface, ModuleListEnum } from "interfaces";
import { MdVerifiedUser } from "react-icons/md";

export const decodeRoleOptionsHandler = (
  options: AdminPrivilegeInterface[]
) => {
  return options
    ?.filter((e) => !(e?.isSeed && e?.role === "super_admin"))
    .map((e) => {
      return {
        label: e?.name,
        value: e?._id,
      };
    });
};

export const getModuleNameIconHandler = (value: ModuleListEnum) => {
  let name, Icon;
  switch (value) {
    case ModuleListEnum.AUTH:
      name = "Auth";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.RENTAL:
      name = "Rental";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.TENANCY:
      name = "Tenancy";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.INVOICE:
      name = "Invoice";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.INVENTORY:
      name = "Inventory";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.FINANCE:
      name = "Finance";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.CANDIDATE:
      name = "Candidate";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.CONFIGURATION:
      name = "Configuration";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.BANK:
      name = "Bank";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.TASK:
      name = "Task";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.NOTES:
      name = "Notes";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.COMMUNICATION:
      name = "Communication";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.DOCUMENT:
      name = "Document";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.TOOLS:
      name = "Tools";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.BOOKING:
      name = "Booking";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.REPAIR_AND_MAINTENANCE:
      name = "Maintenance";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.TENANCY:
      name = "Tenancy";
      Icon = MdVerifiedUser;
      break;
    case ModuleListEnum.REPAIR_AND_MAINTENANCE:
      name = "Maintenance";
      Icon = MdVerifiedUser;
      break;
    default:
      name = "Undefined";
      Icon = MdVerifiedUser;
  }
  return { name, Icon };
};
