import { useState } from "react";
import "./styles.css";
import { SearchSelectInterface } from "./interfaces";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";

export default function MySelectField({
  option,
  paramsName,
  onSelectHandler,
  fetchOptionsHandler,
  isClearable,
  label,
  selectedValue,
  styles,
}: SearchSelectInterface) {
  const [isLoading, setLoading] = useState(false);
  const [query, setQuery] = useSearchParams();
  const onFocusHandler = async () => {
    try {
      if (option?.selectOptions?.length > 0) return;
      setLoading(true);
      if (typeof fetchOptionsHandler === "function") {
        await fetchOptionsHandler();
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const confirmHandler = (e) => {
    const value = e?.value || "";
    if (typeof onSelectHandler === "function") {
      onSelectHandler(value);
      return;
    }
    query.set(paramsName, value);
    setQuery(query);
  };
  let val = query.get(paramsName) || selectedValue;
  const value = option?.selectOptions?.find((e) => e?.value == val);
  return (
    <div className="input_container">
      {label && <label className={"input_label"}>{label}</label>}
      <Select
        isClearable={isClearable}
        isLoading={isLoading}
        className={"react-select-container"}
        classNamePrefix={"custom-react-select"}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: "#C0C0C0",
          },
        })}
        styles={{
          control: (provided: any, state) => ({
            ...provided,
            border: state.isFocused ? "2px solid #2680EB" : "1px solid #C0C0C0",
            borderRadius: "30px",
            boxShadow: "none",
            width: styles?.width,
            height: styles?.height || "48px",
            transition: "none",
          }),
          valueContainer: (provided: any, state) => ({
            ...provided,
            padding: "0 0px 0px 20px",
            fontSize: "16px",
            transition: "none",
          }),
          input: (provided: any, state) => ({
            ...provided,
            margin: "0px",
            outline: "none",
            boxShadow: "none",
            transition: "none",
          }),
          placeholder: (provided: any, state) => ({
            ...provided,
            whiteSpace: "nowrap",
          }),
          indicatorSeparator: (state: any) => ({
            ...state,
            display: "none",
            padding: "0px 0px",
          }),
          clearIndicator: (state: any) => ({
            ...state,
            padding: "0px 0px",
          }),
          dropdownIndicator: (state: any) => ({
            ...state,
            padding: "0px  10px 0px 0px",
          }),
          indicatorsContainer: (provided: any, state) => ({
            ...provided,
            minHeight: styles?.height || "48px",
            height: styles?.height || "48px",
          }),
          menuPortal: (base: any) => ({
            ...base,
            zIndex: 999,
            fontSize: "16px",
          }),
          option: (base: any, { isSelected }) => ({
            ...base,
            borderBottom: "1px solid #D0D0D0",
            borderRadius: "5px 5px 0px 0px",
            color: isSelected ? "white" : "#00040E",
            backgroundColor: isSelected ? "#2680EB" : "",
          }),
          menuList: (base: any) => ({
            ...base,
            padding: 5,
          }),
          menu: (base: any, {}) => ({
            ...base,
            borderRadius: 20,
            border: "1px solid #C0C0C0",
            overflow: "hidden",
            padding: 5,
            boxShadow: "none",
            backgroundColor: "white",
          }),
        }}
        value={value}
        onFocus={onFocusHandler}
        options={option?.selectOptions}
        placeholder={option?.defaultLabel || "Select options"}
        onChange={confirmHandler}
      />
    </div>
  );
}
