import React, { useContext, useEffect, useState } from "react";
import { AppContext, DashboardStatsContext } from "context";
import {capitalizeFirstLetter, getCurrency, getIconHelper} from "helpers";
import { IconTypeEnum, TIME_BASIS_ENUM } from "interfaces";
import { Form, Formik } from "formik";
import { MySelectField } from "components";

import { RevenueStatsInterface } from "../interfaces";

function RevenueStatsContent() {
  const {
    handlers: { setLoading },
  } = useContext(AppContext);
  const { getRevenueStatsHandler } = useContext(DashboardStatsContext);
  const CalendarIcon = getIconHelper(IconTypeEnum.CALENDAR);
  const GrowthIcon = getIconHelper(IconTypeEnum.GROWTH);
  const [data, setData] = useState<RevenueStatsInterface>(null);
  const [basis, setBasis] = useState(TIME_BASIS_ENUM.MONTH);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const data: any = await getRevenueStatsHandler(basis);
      setData(data);
      setLoading(false);
    })();
  }, [basis]);
  const timeBasisOptions = [
    {
      label: "Daily",
      value: TIME_BASIS_ENUM.DAY,
    },
    {
      label: "Weekly",
      value: TIME_BASIS_ENUM.WEEK,
    },
    {
      label: "Monthly",
      value: TIME_BASIS_ENUM.MONTH,
    },
    {
      label: "Yearly",
      value: TIME_BASIS_ENUM.YEAR,
    },
  ];
  return (
    <div className={"flex flex-col gap-5"}>
      <div className={"flex items-center justify-between"}>
        <b className={"text-[24px]"}>Revenue</b>
        <Formik
          initialValues={{
            basis: basis,
          }}
          onSubmit={(values) => {
            if (values?.basis) {
              setBasis(values?.basis);
            }
          }}
        >
          {({ handleSubmit }) => {
            return (
              <Form>
                <div className={"flex items-center gap-5"}>
                  <MySelectField
                    option={{
                      selectOptions: timeBasisOptions,
                      defaultLabel: "Select",
                      onChangeCallback() {
                        handleSubmit();
                      },
                    }}
                    name={"basis"}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className={"grid grid-cols-3  gap-5"}>
        <div
          className={
            "flex items-center justify-start gap-5 bg-[#6B9DFE] rounded-md pl-5 text-white min-h-[140px]"
          }
        >
          <CalendarIcon
            className={
              "w-[35px] h-[35px] fill-gray-200 text-gray-200 text-gray-200"
            }
          />
          <div className={"flex flex-col gap-2 text-[16px] "}>
            <span>Previous {capitalizeFirstLetter(basis)} Revenue</span>
            <span className={"text-[22px] font-semibold"}>
              {getCurrency()}{data?.previous || "-"}
            </span>
          </div>
        </div>
        <div
          className={
            "flex items-center justify-start gap-5 bg-[#FE8D6B] rounded-md pl-5 text-white min-h-[140px]"
          }
        >
          <CalendarIcon
            className={
              "w-[35px] h-[35px] fill-gray-200 text-gray-200 text-gray-200"
            }
          />
          <div className={"flex flex-col gap-2 text-[16px] "}>
            <span>Current {capitalizeFirstLetter(basis)} Revenue</span>
            <span className={"text-[22px] font-semibold"}>
              {getCurrency()}{data?.current || "-"}
            </span>
          </div>
        </div>

        <div
          className={
            "flex items-center justify-start gap-5 bg-[#BD6BFE] rounded-md pl-5 text-white min-h-[140px]"
          }
        >
          <GrowthIcon
            className={
              "w-[35px] h-[35px] fill-gray-200 text-gray-200 text-gray-200"
            }
          />
          <div className={"flex flex-col gap-2 text-[16px] "}>
            <span>Growth Rate</span>
            <span className={"text-[22px] font-semibold"}>
              {data?.growthRate > 100
                ? `100% +`
                : (data?.growthRate || 0) + "%"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RevenueStatsContent;
