import React from "react";
import { Route } from "react-router-dom";
import { DashboardPage } from "./pages";
import "./styles.css";
import { AdminAccessListEnum } from "../../interfaces";
import { AccessVerifier } from "../../templates";
function Index() {
  return (
    <Route path="/">
      <Route
        index
        path={""}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.MISC_ACCESS_DASHBOARD]}
            isForPage={true}
          >
            <DashboardPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
