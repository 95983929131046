import React, { useContext } from "react";

import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { PageLinks } from "routes";
import { AppContext } from "context";
import { PageTemplate } from "templates";
import { FormConcludeButtons } from "assets";
import { MyTextField, MyUploadPhoto } from "components";

import { Context } from "../context";
import { editProfileFormik } from "../helpers";

function EditProfileModal() {
  const navigate = useNavigate();
  const {
    state: { profileDetails },
  } = useContext(AppContext);

  const {
    contextHandlers: { updateProfileHandler },
  } = useContext(Context);

  const submitHandler = async (values, props) => {
    try {
      updateProfileHandler(values, props, {
        onSuccess(payload) {
          navigate(PageLinks.profile.details);
        },
      });
    } catch (err) {}
  };
  return (
    <PageTemplate
      backNavigation={{
        title: "Edit Profile",
      }}
      title={"Profile"}
    >
      <Formik
        initialValues={editProfileFormik.initialValues(profileDetails)}
        onSubmit={submitHandler}
        enableReinitialize
      >
        <Form className={"flex flex-col gap-5"}>
          <>
            <div className={`grid grid-cols-3  gap-5`}>
              <MyTextField
                label={"Name"}
                name={"name"}
                placeholder={"eg. John Doe"}
              />
              <MyTextField
                label={"Phone"}
                name={"phone"}
                placeholder={"eg. 977"}
              />
            </div>
            <div className={`grid grid-cols-3  gap-5`}>
              <MyUploadPhoto name={"photo"} label={"Picture"} />
            </div>
          </>
          <FormConcludeButtons
            cancelButton={{
              title: "Back",
              handler() {
                navigate(PageLinks.profile.details);
              },
            }}
            submitButton={{
              title: "Submit",
            }}
          />
        </Form>
      </Formik>
    </PageTemplate>
  );
}

export default EditProfileModal;
