import React, { useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import { DataContext } from "../context";
import { LoginFormik } from "../helpers";
import { MyButton, MyTextField, MyPasswordInput } from "components";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context";
function LoginPage() {
  const { loginHandler } = useContext(DataContext);
  const {
    handlers: { dispatchLogoutActionHandler },
  } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    dispatchLogoutActionHandler();
  }, []);

  return (
    <Formik onSubmit={loginHandler} {...LoginFormik}>
      {({ isSubmitting, errors }) => {
        return (
          <Form className={"flex flex-col gap-10 text-[14px]"}>
            <div className={"flex flex-col gap-5"}>
              <span className={"text-[14px] text-tRed"}>
                {typeof errors?.error === "string" && `*${errors?.error}`}
              </span>
              <MyTextField
                autoFocus
                name={"emailOrPhone"}
                label={"Email Address"}
                required
                placeholder={"Enter your email address"}
              />
              <MyPasswordInput
                name={"password"}
                label={"Password"}
                required
                placeholder={"Enter your password"}
              />
            </div>
            <MyButton name={"Login"} type={"submit"} isLoading={isSubmitting} />
            <div className={"text-center"}>
              <span
                onClick={() => navigate("/auth/forgotPassword")}
                className={
                  "underline  text-primary font-semibold  cursor-pointer"
                }
              >
                Forgot Password?
              </span>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default LoginPage;
