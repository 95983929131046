import { TIME_BASIS_ENUM } from "../interfaces";

const apiUrl = {
  app: {
    post_clientLogging: "/clientLogging/log-client-errors",
  },

  auth: {
    get_logout: "/admin/user/logout",
    get_currentProfile: "/admin/user/current",
    get_tenantSpaceList: "/user/space-list",
    get_loginToTenantSpace: "/user/space-access-token/",
    get_newAccessToken: "/user/access-token",
    post_login: "/admin/user/login",
    post_createUser: "/admin/user/register",
    post_registerAsLandLord: "/user/register",
    post_requestForgetPassword: "/admin/user/request-forget-password",
    post_validateForgetPasswordOtp: "/admin/user/validate-forget-password-otp",
    put_resetPassword: "/admin/user/reset-password",
  },
  profile: {
    put_updatePassword: "/admin/user/change-password",
    put_updateProfile: "/admin/user/edit-profile",
  },
  pdf: {
    get_subscriptionPDF: (itemID, orderID) =>
      `/pdf/admin-subscription/${itemID}/${orderID}`,
  },
  dashboard: {
    get_revenueStats: (type: TIME_BASIS_ENUM) =>
      `/admin/dashboard/revenue/${type}`,
    get_userStats: "/admin/dashboard/user",
    get_subscription: "/admin/dashboard/subscription",
  },
  utils: {
    uploadImage: "/imageUpload/upload",
    get_Image: "/imageUpload/image/",
  },
  address: {
    get_Locations: "/nested-place/",
    get_allCountyList: "/nested-place/county",
    get_allCountryList: "/nested-place/country",
    get_locationChild: "/nested-place",
    post_editLocation: "/nested-place/",
    delete_Location: "/nested-place/delete/",
  },
  settings: {
    get_spaceSettings: "/admin/settings",
    put_updateSSettings: "/admin/settings",
  },
  config: {
    get_vehicles: "/configuration/vehicles",
    get_configuration: "/configuration/",
    put_configuration: "/configuration/",
    put_system_off: "/configuration/system-off",
    put_system_on: "/configuration/system-on",
  },
  file: {
    post_uploadFile: "/file",
    get_file: "/file/detail/",
  },
  configuration: {
    dynamicList: {
      get_listFromFieldType: "/dynamic-list/",
      get_itemDetail: "/dynamic-list/detail/",
      post_addItem: "/dynamic-list/",
      delete_item: "/dynamic-list/delete/",
    },
  },
  property: {
    get_property: "/property/",
    get_archivedProperty: "/property/archived/",
    get_propertyDetails: "/property/detail/",
    post_addProperty: "/property/",
    put_editProperty: "/property/",
    put_archiveUnArchiveProperty: "/property/archive/",
    delete_Property: "/property/delete/",
  },
  unit: {
    get_unit: "/unit/",
    get_archivedUnit: "/unit/archived/",
    get_unitDetails: "/unit/detail/",
    post_addUnit: "/unit/",
    put_editUnit: "/unit/",
    put_archiveUnArchiveUnit: "/unit/archive/",
    delete_unit: "/unit/delete/",
  },
  tenants: {
    get_tenants: "/tenant/",
    get_tenantsByPropertyId: "/tenant/property/",
    get_tenantsByUnitId: "/tenant/unit/",
    get_archivedTenants: "/tenant/archived/",
    get_tenantsDetails: "/tenant/detail/",
    post_addTenants: "/tenant/",
    put_editTenants: "/tenant/",
    put_archiveUnArchiveTenants: "/tenant/archive/",
    delete_tenants: "/tenant/remove-from-space/",
  },
  listing: {
    get_listingFromUnitId: "/listing-unit/",
    get_ListingInSpace: "/listing-unit/",
    get_ListingDetails: "/listing-unit/detail/",
    post_addListingToUnit: "/listing-unit/",
    delete_listing: "/listing-unit/delete/",
  },
  ownership: {
    get_ownershipFromPropertyId: "/owner/ownership/list/",
    post_addOwnerAndAssignOwner: "/owner/add-and-assign/",
    post_assignOwnerInProperty: "/owner/assign/",
    put_ownership: "/owner/assign/edit/",
  },
  packages: {
    get_package: "/admin/package-subscription/",
    get_packageDetails: (pacakgeID: string) =>
      `/admin/package-subscription/detail/${pacakgeID}`,
    post_addPackage: "/admin/package-subscription/",
    put_editPackage: "/admin/package-subscription/",
    put_archiveUnArchivePackage: (itemID: string, status: boolean) =>
      `/admin/package-subscription/toggle-status/${itemID}/${
        status ? "off" : "on"
      }`,
    delete_package: (packageID: string) =>
      `/admin/package-subscription/delete/${packageID}`,
  },
  customers: {
    get_customers: "/admin/customer/",
    get_customerDetails: (id: string) => `/admin/customer/detail/${id}`,
    get_purchaseHistoryLandlord: (id: string) =>
      `/admin/customer/purchase-history?landlord=${id}`,
    put_assignPackageToCustomer: "/admin/customer/assign/",
    put_extendSubscriptionDuration: "/admin/customer/extend/",
    put_archiveCustomer: "/admin/customer/toggle-status/",
    delete_terminateActivePackage: "/admin/customer/revoke/",
  },
  purchaseHistory: {
    get_purchaseHistory: "/admin/customer/purchase-history",
  },
  users: {
    get_users: "/admin/user/",
    get_userDetails: "/admin/user/detail/",
    post_addUser: "/admin/user/register",
    put_editUser: "/admin/user/register",
    put_archiveUnArchiveUser: "/admin/user/toggle-status/",
    delete_user: "/admin/user/delete/",
  },
  privilege: {
    get_adminPrivilege: "/admin/privilege",
    get_privilegeDetails: "/admin/privilege/detail/",
    get_adminPrivilegeDetails: "/admin/privilege/detail/",
    post_createAdminPrivilege: "/admin/privilege",
    put_editAdminPrivilege: "/admin/privilege",
    put_archivePrivilege: "/admin/privilege/archive/",
    delete_adminPrivilege: "/admin/privilege/delete/",
  },
  owner: {
    get_ownersInSpace: "/owner/",
    get_ownerDetail: "/owner/detail/",
    put_inActiveOwner: "/owner/toggle-status/",
    delete_owner: "/owner/delete/",
  },
};

export default apiUrl;
