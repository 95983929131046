import React from "react";
import { FormWrapper } from "assets";
import {
  MyButton,
  MyTextField,
  MyUploadDocument,
  MyUploadPhoto,
} from "components";
import { AddressForm } from "content";
import { FieldArray, useFormikContext } from "formik";
import { IconTypeEnum, SystemSettingsInterface } from "interfaces";

function SystemSettingForm() {
  const { values } = useFormikContext<SystemSettingsInterface>();

  return (
    <div className={"flex flex-col gap-10 pb-20"}>
      <FormWrapper header={{ title: { name: "Company Information" } }}>
        <div className={"flex flex-col gap-10"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <MyTextField
              name={"companyName"}
              placeholder={"Eg. UK Property PVT. LTD."}
              label={"Company Name"}
            />
            <MyTextField
              name={"companyRegistration"}
              placeholder={"eg. 756-86-AHM-45"}
              label={"Company Registration Number"}
            />
              <MyTextField
                  name={"companyPan"}
                  placeholder={"eg. 756-86-AHM-45"}
                  label={"VAT Registration Number"}
              />
          </div>
          <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
            <MyUploadDocument
              fileContainerStyle={"grid grid-cols-1"}
              fileType={"image"}
              label={"Company's Logo"}
              name={"companyLogo"}
            />
          </div>
          <AddressForm />
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Contact" } }}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MyTextField
            name={"contactDetail"}
            placeholder={"eg. +44"}
            label={"Phone"}
          />
          <MyTextField
            name={"website"}
            placeholder={"eg. my.ukpa.com"}
            label={"Website"}
          />
          <MyTextField
            name={"companyEmail"}
            placeholder={"eg. my.name@ukpa.com"}
            label={"Company Email"}
          />
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Brevo configuration" } }}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MyTextField
            name={"sender.name"}
            label={"Sender name"}
            placeholder={"eg. UKPA Company"}
          />
          <MyTextField
            name={"sender.email"}
            label={"Sender email"}
            placeholder={"eg. my.company@ukpa.com"}
          />
          <div className={"col-span-2"}>
            <MyTextField
              label={"Brevo key"}
              name={"brevoKey"}
              placeholder={"eg. a7858 a7w85 a7w6"}
            />
          </div>
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Invoice Prefix" } }}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MyTextField
            name={"invoicePrefix.separator"}
            placeholder={"eg: -"}
            label={"Separator"}
          />
          <MyTextField
            name={"invoicePrefix.SUBSCRIPTION"}
            placeholder={"Enter subscription"}
            label={"Subscription"}
          />{" "}
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Shoot Subscription Remainder" } }}>
        <FieldArray
          name={"shootSubscriptionReminder"}
          render={({ push, remove }) => {
            return (
              <div className={"flex flex-col gap-5"}>
                {values?.shootSubscriptionReminder?.map((e, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"
                      }
                    >
                      <MyTextField
                        type={"number"}
                        placeholder={"eg: 2"}
                        name={`shootSubscriptionReminder.${index}`}
                      />
                      <div>
                        <MyButton
                          colorType={"danger"}
                          type={"button"}
                          name={"Remove"}
                          onClick={() => {
                            remove(index);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                <div>
                  <MyButton
                    name={"Add more"}
                    onClick={() => {
                      push("");
                    }}
                    iconType={IconTypeEnum.ADD}
                  />
                </div>
              </div>
            );
          }}
        />
      </FormWrapper>
      <FormWrapper header={{ title: { name: "App Configuration" } }}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MyTextField
            name={"aboutUsPageURL"}
            placeholder={"https://"}
            label={"About us page url"}
          />{" "}
          <MyTextField
            name={"privacyPolicyPageURL"}
            placeholder={"https://"}
            label={"Privacy policy page url"}
          />
          <MyTextField
            name={"termsAndConditionURL"}
            placeholder={"https://"}
            label={"Terms and condition url"}
          />
          <MyTextField
            name={"appStoreLink"}
            placeholder={"https://"}
            label={"App store link"}
          />{" "}
          <MyTextField
            name={"playStoreLink"}
            placeholder={"https://"}
            label={"Play store link"}
          />
        </div>
      </FormWrapper>
    </div>
  );
}

export default SystemSettingForm;
