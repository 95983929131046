import { createContext, useState } from "react";
import { AlertModal } from "components";

export const ConfirmContext = createContext({
  prompt: "",
  isOpen: false,
  proceed: null,
  cancel: null,
  setConfirm: null,
  cancelTitle: "",
  confirmTitle: "",
});

const ConfirmContextProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    prompt: "",
    isOpen: false,
    proceed: null,
    cancel: null,
    cancelTitle: "",
    confirmTitle: "",
  });

  return (
    <ConfirmContext.Provider value={{ ...confirm, setConfirm }}>
      <AlertModal />
      {children}
    </ConfirmContext.Provider>
  );
};

export default ConfirmContextProvider;
