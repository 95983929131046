import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { PageLinks } from "routes";
import { PageTemplate } from "templates";
import { UserContext } from "context";
import { AdminAccessListEnum, IconTypeEnum } from "interfaces";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";
import { MyButton, TableCardComponent } from "components";

import { UsersTableColumns } from "../components";

function UserPage() {
  const {
    allData,
    isLoading,
    getAllDataHandlers,
    totalDocs,
    totalArchiveDocs,
  } = useContext(UserContext);
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const navigate = useNavigate();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAdd = checkCanAccessHandler([AdminAccessListEnum.AUTH_ADD_USER]);

  useEffect(() => {
    getAllDataHandlers();
  }, [isArchive, currentPage]);

  const tableCardProps = {
    column: UsersTableColumns(),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
  };
  return (
    <PageTemplate
      scrollAll
      title={"Users"}
      breadCrumb={[
        {
          name: "User",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        children={{
          rightChildren: canAdd && (
            <div>
              <MyButton
                iconType={IconTypeEnum.ADD}
                name={"Add User"}
                onClick={() => navigate(PageLinks.user.create)}
              />
            </div>
          ),
        }}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
        {...tableCardProps}
      />
    </PageTemplate>
  );
}

export default UserPage;
