import React, { useContext } from "react";
import { CustomerContext } from "context";
import { NameValueText } from "assets";
import { useDateHooks } from "useHooks";
import { getCurrency } from "helpers";
import { SystemTenantDetailsInterface } from "../../../interfaces";

function PackageTab() {
  const { details, isDetailsLoading } = useContext(CustomerContext);
  let tempDetails: any = details;
  const userDetails: SystemTenantDetailsInterface = tempDetails;
  const { getDateHandler } = useDateHooks();
  return (
    <div className={"flex flex-col gap-10"}>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 "}>
        <NameValueText
          isLoading={isDetailsLoading}
          name={"Package"}
          value={userDetails?.package?.active?.subscribedPackage?.name}
        />
        <NameValueText
          isLoading={isDetailsLoading}
          name={"Purchase Date"}
          value={getDateHandler({
            date: userDetails?.package?.active?.packageStartsAt,
          })}
        />
        <NameValueText
          isLoading={isDetailsLoading}
          name={"Expiry Date"}
          value={getDateHandler({
            date: userDetails?.package?.active?.packageExpiredAt,
          })}
        />{" "}
        <NameValueText
          isLoading={isDetailsLoading}
          name={"Price"}
          value={`${getCurrency()}${
            userDetails?.package?.active?.totalPrice || "-"
          }`}
        />{" "}
        <NameValueText
          isLoading={isDetailsLoading}
          name={"Day left"}
          value={`${
            userDetails?.package?.active?.numberOfDaysRemainsText || "-"
          }`}
        />
      </div>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 "}>
        <div className={"col-span-2"}>
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Description"}
            value={
              userDetails?.package?.active?.subscribedPackage?.description ||
              "-"
            }
          />
        </div>
      </div>
    </div>
  );
}

export default PackageTab;
