import React from "react";
import { FormConcludeButtonInterface } from "./interfaces";
import { MyButton } from "components";
import { usePageQueryHooks } from "useHooks";
import { useNavigate } from "react-router-dom";

function FormConcludeButtons({
  submitButton,
  cancelButton,
  draftButton,
  size,
}: FormConcludeButtonInterface) {
  const { returnURL } = usePageQueryHooks();
  const navigate = useNavigate();
  return (
    <div className={"flex items-end justify-end border-t-[1px] pt-6"}>
      <div className={"flex gap-5"}>
        {submitButton && (
          <MyButton
            size={size}
            disabled={submitButton?.disable}
            onClick={
              typeof submitButton?.handler === "function"
                ? submitButton?.handler
                : undefined
            }
            colorType={"primary"}
            isLoading={submitButton?.isLoading}
            name={submitButton?.title || "Submit"}
            type={submitButton?.notSubmit ? "button" : "submit"}
          />
        )}

        {draftButton && (
          <MyButton
            size={size}
            disabled={draftButton?.disable}
            isOutline
            name={draftButton?.title || "Draft"}
            onClick={() => draftButton?.handler()}
          />
        )}
        {cancelButton && (
          <MyButton
            size={size}
            isOutline
            colorType={"white"}
            name={cancelButton?.title || "Cancel"}
            onClick={() => {
              if (typeof cancelButton?.handler == "function") {
                cancelButton.handler();
              } else {
                if (returnURL) {
                  navigate(returnURL);
                } else {
                  navigate(-1);
                }
              }
            }}
          />
        )}
      </div>
    </div>
  );
}

export default FormConcludeButtons;
