import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";

import { PageLinks } from "routes";
import { CustomerContext } from "context";
import { MyMoreOptionButton } from "components";
import {
  useAuthorizationHook,
  useDateHooks,
  usePaginationQueryHooks,
} from "useHooks";
import {
  AdminAccessListEnum,
  SystemTenantUserInterface,
  TableColumnInterface,
} from "interfaces";

function CustomerTableColumns(
  pageQuery?: any
): TableColumnInterface<SystemTenantUserInterface>[] {
  const navigate = useNavigate();
  const { archiveHandler, getAllDataHandlers } = useContext(CustomerContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { getDateHandler } = useDateHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canDelete = checkCanAccessHandler([
    AdminAccessListEnum.CUSTOMER_DEL_CUSTOMER,
  ]);
  return [
    {
      title: "SN",
      render(renderData, key) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Email Address",
      render(renderData) {
        return <div>{renderData?.userID?.email}</div>;
      },
    },
    {
      title: `Subscribed Package`,
      render(renderData) {
        return (
          <div>
            {renderData?.activeSubscription?.subscribedPackage?.name || "-"}
          </div>
        );
      },
    },
    {
      title: `Purchased Properties Count`,
      render(renderData) {
        return (
          <div>{renderData?.activeSubscription?.purchasedProperty || "-"}</div>
        );
      },
    },
    {
      title: `Last Login/Login Count`,
      render(renderData) {
        return (
          <div className={"flex flex-col gap-1"}>
            <span className={"whitespace-nowrap"}>
              {getDateHandler({ date: renderData?.userID?.loginAt })}
            </span>
            <b className={"whitespace-nowrap"}>
              {renderData?.userID?.numberOfLogins} times{" "}
            </b>
          </div>
        );
      },
    },
    {
      title: `Start Date`,
      render(renderData) {
        return (
          <div>
            {getDateHandler({
              date: renderData?.activeSubscription?.packageStartsAt,
            }) || "-"}
          </div>
        );
      },
    },
    {
      title: `Expire Date`,
      render(renderData) {
        return (
          <div>
            {getDateHandler({
              date: renderData?.activeSubscription?.packageExpiredAt,
            }) || "-"}
          </div>
        );
      },
    },
    {
      title: `Payment Mode`,
      render(renderData) {
        return <div>{renderData?.activeSubscription?.paymentMode || "-"}</div>;
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View ",
                  handler: () => {
                    navigate(PageLinks.customers.details(renderData?.tenantID));
                  },
                },
                canDelete && {
                  name: renderData?.isActive ? "Archive" : "Unarchive",
                  handler: () => {
                    archiveHandler(
                      renderData?.tenantID,
                      !renderData?.isActive,
                      {
                        async onSuccess(payload?: any) {
                          getAllDataHandlers(pageQuery);
                        },
                      }
                    );
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default CustomerTableColumns;
