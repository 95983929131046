import * as yup from "yup";
import { AddressInterface } from "interfaces";
export const addressInitialValues = (values?: AddressInterface) => {
  return {
    address1: values?.address1 || "",
    address2: values?.address2 || "",
    city: values?.city || "",
    postalCode: values?.postalCode || "",
    county:
      values?.county && typeof values?.county !== "string"
        ? values?.county?._id
        : values?.county || "",
    country:
      values?.country && typeof values?.country !== "string"
        ? values?.country?._id
        : values?.country || "",
  };
};
export const addressValidationSchema = yup.object().shape({
  address1: yup.string().required("required"),
  address2: yup.string().required("required"),
  city: yup.string().required("required"),
  postalCode: yup.number().required("required"),
  county: yup.string().required("required"),
  country: yup.string().required("required"),
});
