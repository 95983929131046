import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context";
import { AdminAccessListEnum, UserRoleEnum } from "interfaces";
import dot from "dot-object";

function UseAuthorizationHook() {
  const {
    state: { profileDetails },
  } = useContext(AppContext);
  const [granted, setGranted] = useState(profileDetails?.privilege?.granted);
  useEffect(() => {
    setGranted(profileDetails?.privilege?.granted);
  }, [profileDetails?.privilege?.granted]);
  const checkCanAccessHandler = (
    accessKeys: AdminAccessListEnum[],
    role?: UserRoleEnum[]
  ): boolean => {
    // if (role?.length && !role.includes(profileDetails?.role)) {
    //   return false;
    // }
    if (accessKeys?.length === 0) {
      return true;
    }

    return accessKeys.some((e) => {
      let value = dot.pick(e, granted);
      return value;
    });
  };
  return {
    checkCanAccessHandler,
  };
}

export default UseAuthorizationHook;
