import React from "react";
import { MyIconButton } from "../../components";
import { BackNavigationInterface } from "./interfaces";
import { useNavigate } from "react-router-dom";
import { IconTypeEnum } from "../../interfaces";

function BackNavigationText({
  backHandler,
  title,
  titleStyle,
  containerStyle,
  iconStyle,
}: BackNavigationInterface) {
  const navigate = useNavigate();
  return (
    <div className={`flex gap-4 items-center ${containerStyle}`}>
      <MyIconButton
        onClick={() =>
          typeof backHandler === "function" ? backHandler() : navigate(-1)
        }
        IconType={IconTypeEnum.LEFT}
        iconSize={22}
        containerStyle={`border-full border-2 w-[26px] h-[26px] ${iconStyle}`}
      />
      <span className={`font-bold text-[20px] ${titleStyle}`}>{title}</span>
    </div>
  );
}

export default BackNavigationText;
