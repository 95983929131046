import React from "react";
import { DateRangeOptionEnum } from "interfaces";
import {
  DateRangeOptions,
  lastMonthEnd,
  lastMonthStart,
  lastYearEnd,
  lastYearStart,
  thisMonthEnd,
  thisMonthStart,
  thisYearEnd,
  thisYearStart,
} from "helpers";
import { MySelectField, MyTextField } from "components";
import { Form, Formik } from "formik";

interface DateFilterInterface {
  startDate: Date;
  endDate: Date;
  type: DateRangeOptionEnum;
}

export const DateFilterModal = ({
  dateFilter,
  submitHandler,
}: {
  dateFilter: DateFilterInterface;
  submitHandler(payload);
}) => {
  return (
    <>
      <Formik
        initialValues={{
          type: dateFilter?.type || DateRangeOptionEnum.ALL_TIME,
          startDate: dateFilter?.startDate,
          endDate: dateFilter?.endDate,
          fiscalYear: "",
        }}
        onSubmit={(values) => {
          if (values?.type === DateRangeOptionEnum.CUSTOM) {
            if (!values?.startDate || !values?.endDate) {
              return;
            }
          }
          submitHandler(values);
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <Form onChange={() => handleSubmit()}>
              <div className={"flex flex-col gap-5 "}>
                <div className={"flex items-center gap-5 min-h-[100px]"}>
                  <MySelectField
                    isClearable
                    option={{
                      selectOptions: DateRangeOptions,
                      defaultLabel: "Select Date Range Type",
                      onChangeCallback(currentValue?: any) {
                        currentValue = currentValue?.value;
                        if (currentValue === DateRangeOptionEnum.THIS_MONTH) {
                          setFieldValue("startDate", thisMonthStart);
                          setFieldValue("endDate", thisMonthEnd);
                          handleSubmit();
                        } else if (
                          currentValue === DateRangeOptionEnum.THIS_YEAR
                        ) {
                          setFieldValue("startDate", thisYearStart);
                          setFieldValue("endDate", thisYearEnd);
                          handleSubmit();
                        } else if (
                          currentValue === DateRangeOptionEnum.LAST_MONTH
                        ) {
                          setFieldValue("startDate", lastMonthStart);
                          setFieldValue("endDate", lastMonthEnd);
                          handleSubmit();
                        } else if (
                          currentValue === DateRangeOptionEnum.LAST_YEAR
                        ) {
                          setFieldValue("startDate", lastYearStart);
                          setFieldValue("endDate", lastYearEnd);
                          handleSubmit();
                        } else {
                          setFieldValue("startDate", "");
                          setFieldValue("endDate", "");
                          handleSubmit();
                        }
                      },
                    }}
                    name={"type"}
                  />
                  {values?.type === DateRangeOptionEnum.CUSTOM && (
                    <>
                      <MyTextField type={"date"} name={"startDate"} />
                      <MyTextField type={"date"} name={"endDate"} />
                    </>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default DateFilterModal;
