import React from "react";
import { Route } from "react-router-dom";
import { AdminAccessListEnum, ParamsStringEnum } from "interfaces";
import { AccessVerifier } from "templates";

import { EditUserPage, UsersPage, UserDetailsPage } from "./pages";

function Index() {
  return (
    <Route path="users/">
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.AUTH_READ_USER]}
            isForPage={true}
          >
            <UsersPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"create"}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.AUTH_EDIT_USER]}
            isForPage={true}
          >
            <EditUserPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.AUTH_EDIT_USER]}
            isForPage={true}
          >
            <EditUserPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`details/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.AUTH_READ_USER]}
            isForPage={true}
          >
            <UserDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
