import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { useParams } from "react-router-dom";
import { ParamsStringEnum, TaxTypeEnum } from "interfaces";

import { PackageContext } from "context";
import { MyCheckButton } from "components";
import { FormWrapper, NameValueText } from "assets";
import { PageLinks } from "routes";
import { getCurrency } from "helpers";

function PackageDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const ID = params.id;
  const { details, isDetailsLoading, getDetailsHandler } =
    useContext(PackageContext);
  useEffect(() => {
    getDetailsHandler(ID);
  }, [ID]);
  return (
    <PageTemplate
      scrollAll
      title={"Package"}
      backNavigation={{
        title: "Package Details",
        path: PageLinks.package.list,
      }}
      breadCrumb={[
        {
          name: "Package",
        },
      ]}
    >
      <div className={"flex flex-col gap-10"}>
        <div
          className={
            "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
          }
        >
          <NameValueText
            isLoading={isDetailsLoading}
            value={details?.name}
            name={"Name"}
          />
          <NameValueText
            isLoading={isDetailsLoading}
            value={details?.subtitle || "-"}
            name={"Sub-title"}
          />
        </div>
        <div
          className={
            "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
          }
        >
          <MyCheckButton
            isChecked={details?.allowBooking}
            label={"Allow Booking"}
          />
          <MyCheckButton
            isChecked={details?.purchaseDisable}
            label={"Purchase Disable"}
          />
          <MyCheckButton
            isChecked={details?.isFreeForever}
            label={"Free Forever"}
          />
          {!details?.isFreeForever && (
            <MyCheckButton isChecked={details?.trial?.enable} label={"Trial"} />
          )}
        </div>
        {details?.trial?.enable && !details?.isFreeForever && (
          <FormWrapper
            header={{
              title: { name: "Trial" },
            }}
          >
            <div
              className={
                "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
              }
            >
              <NameValueText
                isLoading={isDetailsLoading}
                value={details?.trial?.days}
                name={"Trial"}
              />
              <NameValueText
                isLoading={isDetailsLoading}
                value={details?.trial?.text}
                name={"Text"}
              />
            </div>
          </FormWrapper>
        )}
        <FormWrapper
          header={{
            title: { name: "Features" },
          }}
        >
          <div
            className={
              "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
            }
          >
            <MyCheckButton
              isChecked={details?.allowBankIntegration}
              label={"Bank Integration"}
            />
            <MyCheckButton isChecked={details?.allowSMS} label={"SMS"} />
            <MyCheckButton isChecked={details?.enableMail} label={"Mail"} />
            <MyCheckButton
              isChecked={details?.enableMobileLogin}
              label={"Mobile Login"}
            />
          </div>
        </FormWrapper>{" "}
        <FormWrapper
          header={{
            title: { name: "Module" },
          }}
        >
          <div
            className={
              "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
            }
          >
            <MyCheckButton isChecked={details?.enableTask} label={"Task"} />
            <MyCheckButton
              isChecked={details?.allowBooking}
              label={"Booking"}
            />
            <MyCheckButton
              isChecked={details?.enableMaintenance}
              label={"Maintenance"}
            />
            <MyCheckButton
              isChecked={details?.enableInventory}
              label={"Inventory"}
            />
            <MyCheckButton
              isChecked={details?.allowMessageModule}
              label={"Message Module"}
            />
            <MyCheckButton
              isChecked={details?.allowSupplierUser}
              label={"Supplier User"}
            />
          </div>
        </FormWrapper>
        <FormWrapper
          header={{
            title: { name: "Storage" },
          }}
        >
          <div className={"flex flex-col gap-2"}>
            <span className={"text-gray-500"}>Note: 1GB = 1024MB</span>
            <div
              className={
                "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
              }
            >
              <NameValueText
                isLoading={isDetailsLoading}
                value={details?.storage?.base}
                name={"Base Storage (MB)"}
              />{" "}
              <NameValueText
                isLoading={isDetailsLoading}
                value={details?.storage?.perProperty}
                name={"Per Property Storage (MB)"}
              />
            </div>
          </div>
        </FormWrapper>
        {!details?.isFreeForever && (
          <>
            {details?.annualCost?.enable && (
              <FormWrapper
                header={{
                  title: { name: "Annual Subscription Charge" },
                }}
              >
                <div className={"flex flex-col gap-5"}>
                  <div className={"flex items-center gap-10"}>
                    <MyCheckButton
                      isChecked={
                        details?.annualCost?.taxType === TaxTypeEnum.EXCLUSIVE
                      }
                      label={"Tax Exclusive"}
                    />
                    <MyCheckButton
                      isChecked={
                        details?.annualCost?.taxType === TaxTypeEnum.INCLUSIVE
                      }
                      label={"Tax Inclusive"}
                    />
                    <MyCheckButton
                      isChecked={
                        details?.annualCost?.taxType === TaxTypeEnum.EXEMPTED
                      }
                      label={"Tax Exempted"}
                    />
                  </div>
                  <div
                    className={
                      "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
                    }
                  >
                    <NameValueText
                      isLoading={isDetailsLoading}
                      value={details?.annualCost?.cost}
                      name={`Charge (${getCurrency()})`}
                    />
                    {details?.annualCost?.taxType !== TaxTypeEnum.EXEMPTED && (
                      <NameValueText
                        isLoading={isDetailsLoading}
                        value={details?.annualCost?.taxRate}
                        name={"Tax Rate (%)"}
                      />
                    )}

                    <NameValueText
                      isLoading={isDetailsLoading}
                      value={details?.annualCost?.discountPercent}
                      name={"Discount Percent (%)"}
                    />
                    <NameValueText
                      isLoading={isDetailsLoading}
                      value={details?.annualCost?.flatPropertyCost}
                      name={`Flat Property Charge (${getCurrency()}) (Per Property)`}
                    />
                  </div>
                </div>
              </FormWrapper>
            )}
            {details?.monthlyCost?.enable && (
              <FormWrapper
                header={{
                  title: { name: "Monthly Subscription Charge" },
                }}
              >
                <div className={"flex flex-col gap-5"}>
                  <div className={"flex items-center gap-10"}>
                    <MyCheckButton
                      isChecked={
                        details?.monthlyCost?.taxType === TaxTypeEnum.EXCLUSIVE
                      }
                      label={"Tax Exclusive"}
                    />
                    <MyCheckButton
                      isChecked={
                        details?.monthlyCost?.taxType === TaxTypeEnum.INCLUSIVE
                      }
                      label={"Tax Inclusive"}
                    />
                    <MyCheckButton
                      isChecked={
                        details?.monthlyCost?.taxType === TaxTypeEnum.EXEMPTED
                      }
                      label={"Tax Exempted"}
                    />
                  </div>
                  <div
                    className={
                      "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
                    }
                  >
                    <NameValueText
                      isLoading={isDetailsLoading}
                      value={details?.monthlyCost?.cost}
                      name={`Charge (${getCurrency()})`}
                    />
                    {details?.monthlyCost?.taxType !== TaxTypeEnum.EXEMPTED && (
                      <NameValueText
                        isLoading={isDetailsLoading}
                        value={details?.monthlyCost?.taxRate}
                        name={"Tax Rate (%)"}
                      />
                    )}
                    <NameValueText
                      isLoading={isDetailsLoading}
                      value={details?.monthlyCost?.discountPercent}
                      name={"Discount (%)"}
                    />
                    <NameValueText
                      isLoading={isDetailsLoading}
                      value={details?.monthlyCost?.flatPropertyCost}
                      name={`Flat Property Charge (${getCurrency()}) (Per Property)`}
                    />
                  </div>
                </div>
              </FormWrapper>
            )}
          </>
        )}
        <FormWrapper
          header={{
            title: { name: "Property" },
          }}
        >
          <div className={"flex flex-col gap-5"}>
            {details?.isMaxPropertyUnlimited && (
              <div
                className={
                  "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
                }
              >
                <MyCheckButton
                  isChecked={details?.isMaxPropertyUnlimited}
                  label={"Unlimited Property"}
                />
              </div>
            )}

            <div
              className={
                "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
              }
            >
              {!details?.isMaxPropertyUnlimited && !details?.isFreeForever && (
                <NameValueText
                  isLoading={isDetailsLoading}
                  value={details?.maxProperty}
                  name={"Maximum Number of Properties"}
                />
              )}
              <NameValueText
                isLoading={isDetailsLoading}
                value={details?.propertyIncludedInBasePrice}
                name={"Additional Property through Base Price"}
              />
            </div>
          </div>
        </FormWrapper>
        <FormWrapper
          header={{
            title: { name: "Specifications" },
          }}
        >
          <div className={"flex flex-col gap-5"}>
            {details?.specifications?.map((e, key) => {
              return (
                <div
                  key={key}
                  className={
                    "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
                  }
                >
                  <NameValueText
                    isLoading={isDetailsLoading}
                    name={"Feature"}
                    value={e?.feature}
                  />
                  <MyCheckButton label={"Include"} isChecked={e?.isIncluded} />
                </div>
              );
            })}
          </div>
        </FormWrapper>
        <div
          className={
            "grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
          }
        >
          <div className={"col-span-2"}>
            <NameValueText
              isLoading={isDetailsLoading}
              value={details?.description}
              name={"Description"}
            />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default PackageDetailsPage;
