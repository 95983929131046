import React from "react";
import { Route } from "react-router-dom";

import { EditSystemSettingPage } from "./pages";
import { AdminAccessListEnum } from "../../interfaces";
import { AccessVerifier } from "../../templates";
function Index() {
  return (
    <Route path="settings/">
      <Route
        path={"space-settings"}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.CONFIGURATION_MANAGE_SETTINGS]}
            isForPage={true}
          >
            <EditSystemSettingPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
