import { createContext, useContext } from "react";

import { Outlet } from "react-router-dom";

import { Api, setAuthToken } from "helpers";
import { ApiUrl } from "services";
import { AppContext } from "context";
import { AuthTokenENUM, HandlerCallbackInterface } from "interfaces";

export const Context = createContext({
  contextHandlers: {
    updatePasswordHandler(values, props) {},
    updateProfileHandler(values, props, callback) {},
  },
});

function ProfileModuleContextProvider() {
  const { putApi } = Api();
  const { handlers } = useContext(AppContext);

  const contextHandlers = {
    async updatePasswordHandler(values, props) {
      try {
        props?.setSubmitting(true);
        const res = await putApi(ApiUrl.profile?.put_updatePassword, values);
        setAuthToken(res?.data?.accessToken, AuthTokenENUM.accessToken);
        setAuthToken(res?.data?.refreshToken, AuthTokenENUM.refreshToken);
        if (props?.resetForm) {
          props?.resetForm({
            values: {
              oldPassword: "",
              password: "",
            },
          });
        }
        handlers?.setSuccess(true, "Password changed!");
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        props?.setSubmitting(false);
      }
    },
    async updateProfileHandler(
      values,
      props,
      callback: HandlerCallbackInterface
    ) {
      try {
        handlers?.setLoading(true);
        if (typeof props?.setSubmitting === "function") {
          props?.setSubmitting(true);
        }
        const res = await putApi(ApiUrl.profile?.put_updateProfile, values);
        handlers?.setSuccess(true, "Profile updated");
        await handlers?.getCurrentProfile();
        if (typeof callback?.onSuccess == "function") {
          callback?.onSuccess(res);
        }
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        if (typeof props?.setSubmitting === "function") {
          props?.setSubmitting(false);
        }
        handlers?.setLoading(false);
      }
    },
  };

  const values = {
    contextHandlers,
  };
  return (
    <Context.Provider value={values}>
      <Outlet />
    </Context.Provider>
  );
}
export default ProfileModuleContextProvider;
