import React, { useContext } from "react";

import { CustomerContext } from "context";
import { getStaffFullName } from "helpers";
import { FormWrapper, NameValueText } from "assets";

import { SystemTenantDetailsInterface } from "interfaces";
import { useDateHooks } from "useHooks";

function GeneralDetailsTab() {
  const { details, isDetailsLoading } = useContext(CustomerContext);
  let tempDetails: any = details;
  const userDetails: SystemTenantDetailsInterface = tempDetails;
  const { getDateHandler } = useDateHooks();
  const GeneralDetails = {
    "Full Name": getStaffFullName(userDetails?.customerDetail?.name) || "-",
    "Email ID": userDetails?.customerDetail?.email || "-",
    "Mobile Number": userDetails?.customerDetail?.phone || "-",
    "Last Login":
      getDateHandler({ date: userDetails?.tenantDetail?.loginAt }) ||
      "-" ||
      "-",
    "No. of login": userDetails?.tenantDetail?.numberOfLogins || "-",
  };
  const CompanyDetails = {
    "Company Name": userDetails?.customerDetail?.companyName || "-",
    "Company Registration Number":
      userDetails?.customerDetail?.companyRegistration || "-",
    "PAN/VAT Number": userDetails?.customerDetail?.vatRegistration || "-",
  };
  const BillingAddress = {
    Country: userDetails?.customerDetail?.address?.country?.name || "-",
    County: userDetails?.customerDetail?.address?.county?.name || "-",
    "Town/city": userDetails?.customerDetail?.address?.city || "-",
    "Zip/Post Code": userDetails?.customerDetail?.address?.postalCode || "-",
    Address1: userDetails?.customerDetail?.address?.address1 || "-",
    Address2: userDetails?.customerDetail?.address?.address2 || "-",
  };
  return (
    <div className={"flex flex-col gap-10"}>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 "}>
        {Object.keys(GeneralDetails).map((e) => {
          return (
            <NameValueText
              name={e}
              value={GeneralDetails[e]}
              isLoading={isDetailsLoading}
            />
          );
        })}
      </div>
      <FormWrapper
        header={{
          title: { name: "Company Details" },
        }}
      >
        <div
          className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 "}
        >
          {Object.keys(CompanyDetails).map((e, key) => {
            return (
              <NameValueText
                key={key}
                name={e}
                value={CompanyDetails[e]}
                isLoading={isDetailsLoading}
              />
            );
          })}
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: { name: "Billing Address" },
        }}
      >
        <div
          className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 "}
        >
          {Object.keys(BillingAddress).map((e, key) => {
            return (
              <NameValueText
                key={key}
                name={e}
                value={BillingAddress[e]}
                isLoading={isDetailsLoading}
              />
            );
          })}
        </div>
      </FormWrapper>
    </div>
  );
}

export default GeneralDetailsTab;
