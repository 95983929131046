import React from "react";
import { Route } from "react-router-dom";
import { CustomerDetailsPage, CustomerPage } from "./pages";
import { GeneralDetailsTab, PackageTab, PurchaseHistoryTab } from "./tabs";
import { ParamsEnum } from "./interfaces";
import { AccessVerifier } from "templates";
import { AdminAccessListEnum } from "interfaces";

function Index() {
  return (
    <Route path="customers/">
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.CUSTOMER_READ_CUSTOMER]}
            isForPage={true}
          >
            <CustomerPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`details/:${ParamsEnum?.TENANT_ID}`}
        element={
          <AccessVerifier
            accessKeys={[AdminAccessListEnum.CUSTOMER_READ_CUSTOMER]}
            isForPage={true}
          >
            <CustomerDetailsPage />
          </AccessVerifier>
        }
      >
        <Route path={``} element={<GeneralDetailsTab />} />
        <Route
          path={`purchaseHistory`}
          element={
            <AccessVerifier
              accessKeys={[AdminAccessListEnum.CUSTOMER_READ_PURCHASE_HISTORY]}
              isForPage={true}
            >
              <PurchaseHistoryTab />
            </AccessVerifier>
          }
        />
        <Route path={`packages`} element={<PackageTab />}></Route>
      </Route>
    </Route>
  );
}

export default Index;
